import React, { useEffect,useState,useRef } from 'react'
import image from '../../assets/images/items/1.gif';
import image1 from '../../assets/images/items/1.jpg';
import image2 from '../../assets/images/items/2.jpg';
import image3 from '../../assets/images/items/3.jpg';
import image4 from '../../assets/images/items/2.gif';
import image5 from '../../assets/images/items/4.jpg';
import image6 from '../../assets/images/items/3.gif';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import tick from '../../assets/images/check.png';
import ticks from '../../assets/images/tick1.png';
import star from '../../assets/images/rating-star.png';
import check from '../../assets/images/tick.png';
import travel from '../../assets/images/travel-insurance.png';
import { Icon } from '@iconify/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faImage, faPassport,faHotel } from '@fortawesome/free-solid-svg-icons';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import airplaneTakeoffFill from '@iconify/icons-ph/airplane-takeoff-fill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {LuSearch, BiCheckCircle,CgArrowDown,FiTarget, BsTag,BiPlusCircle,BiDoorOpen, RiArrowDropDownLine, FiHeart, PiBrowsersLight,FiUsers,VscListFlat, PiMusicNotesBold, LuCamera,FaRegPaperPlane, BiWallet, BiMinusCircle, BiShield, BiTargetLock, FaSearchLocation, FaSearchDollar, LuUsers2, LuFolderCheck, MdOutlineChatBubbleOutline, TiPhoneOutline, IoMdClose, GrVisa, AiTwotoneInsurance, RiExchangeBoxFill, RiExchangeDollarLine, MdWorkOff, GoLocation, TiDocument, GiEntryDoor, LuSchool, BsExclamationOctagon} from "../../assets/icons/vander"
import { Users,Calendar,Clock,DollarSign, Folder } from 'feather-icons-react/build/IconComponents';
import NavbarNew from '../../components/navbar-new';
import File from 'feather-icons-react/build/IconComponents/File';
import { dataArray, insuranceDetails } from '../../data/data';
import HealthListing from '../index/health-listing';
import Comingsoon from '../special/comingsoon';
import next from '../../assets/images/next.png';
import prev from '../../assets/images/previous.png';
import { UniversityDetails } from '../../data/data';  
import TinySlider from 'tiny-slider-react';
import axios from 'axios';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import { empty, parseDescription } from '../../common/Helper';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import globe from '../../images/how to/university.png'
import req from '../../images/how to/books.png'
import apply from '../../images/how to/image__4_-removebg-preview.png'
import sts from '../../images/how to/image.jpg';
import rank from '../../images/global-rank.png'
import ReactPaginate from 'react-paginate';
const newImg = [  
  { image_url: globe },
  { image_url: req },
  { image_url: apply },
  { image_url: sts },
];

const settings = {
  items: 1,
  controls: false, // Disable default controls
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: false,
  autoplayButtonOutput: false,
  navPosition: 'bottom',
  nav: false,
  gutter: 0,
};



const getData = (source, ids) => {
  if (!ids) return [];
  return ids.split(',').map(id => source.find(item => item.id == id)).filter(item => item !== undefined);
};
export default function UniversityProfile() {
  const { courseId, tab, } = useParams();
  const [placeBid, setPlaceBid] = useState(false);
  const [university,setUniversity] = useState([])
  const [reviews,setReviews] = useState({})
  const [courseData,setCourseData] = useState([])
  const [courseDetails,setCourseDetails] = useState({})
  const sliderRef = useRef(null);
  const [userData,setUserData] = useState({})
// const [courseList,setCourseList]=useState(false)
  const [faqs, setFaq] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
 const navigate=useNavigate()


const getCourses=async()=>{
    try {
        console.log("u_id",courseId);
        const res = await axios.post(`${REST_API_END_POINT}university/get-all-course`, { u_id:courseId, getUniverity: true ,from_user:true});
        if (res.data.status === 1) {
            const filteredData = res.data.data.filter(d => d.course_status === 1 );
            setCourseData(filteredData)
        }
        else{
            setCourseData([])
          }
      }
       catch (err) {
        console.error(`Error fetching universities for university ID ${courseId}:`, err);
        
      }
    }




// const fetchCourseDetailsbyID=()=>{
//   axios.post(`${REST_API_END_POINT}university/get-course-details`,{courseId})
//   .then((res)=>{
//     if(res.data.status==1){
//       const combinedData = res.data.data ? 
//       [res.data.data, ...res.data.coursePlans] : res.data.coursePlans;
//       setCoursePlans(combinedData)
//       setUniversityDetail(res.data.data)
//       setNotification(res.data.data.notification_btn)
//       setDocRequirement(res.data.documentsRequired)
//       setCourseDetails(res.data.initialDetails)
//       setFaq(res.data.faq)
//       // setDocuments(getData(res.data.documents,res.data.initialDetails?.doc_ids))
//       setDocuments(res.data.documents)
//       setAboutCourse(getData(res.data.aboutCourse,res.data.initialDetails?.about_course_ids))
//     }
//   })
// }
const getUniversitybyId=()=>{
  axios.post(`${REST_API_END_POINT}university/get-by-id`,{id:courseId})
  .then((res)=>{
    if(res.data.status===1){
        setUniversity(res.data.data)
        setReviews(res.data.review)
    }
  })
  .catch((err)=>{
    console.log(err)
  })
}

  useEffect(() => {
    // fetchCourseDetailsbyID()
    getUniversitybyId() 
    getCourses()
}, [courseId,]);

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; 
    const handleSearch = (event) => {
      setSearchTerm(event.target.value);
      setCurrentPage(0); // Reset to first page on search
    };
  
    const handlePageClick = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    // Filter the course data based on the search term
    const filteredDatas = courseData.filter(item =>
      item?.course_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.course_intake.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.fee.toString().includes(searchTerm)
    );
  
    // Calculate the paginated data
    const pageCount = Math.ceil(filteredDatas.length / itemsPerPage);
    const paginatedData = filteredDatas.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  





  

  // const handleCourse=()=>{
  //   setCourseList(true)
  // }
console.log(courseData,'-----------------%%%%%%')
  return (
    <>
      <NavbarNew noHomeTab={true} tab={tab} />

                <section className="relative md:py-24 py-16" style={{ marginTop: '20px' }}>
                  <div className="container">

                  {/* {(notification === 1) && (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="flex items-center p-3 mt-3 rounded-lg" style={{ width: '100%', backgroundColor: 'grey', marginBottom: '10px', justifyContent: 'space-between', color: 'white' }}>


                                <div className="ms-2 items-center" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', textAlign: 'left' }}>
                                    <BsExclamationOctagon style={{ color: 'white', marginRight: '10px' }} className="text-3xl" />
                                    <div>
                                        <span className="block font-semibold">Master Notification</span>
                                        <span className="block" >{universityDetail?.notifications}</span>
                                    </div>
                                </div>
                                <div className="ms-2" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'right' }}>
                                    <span className="block font-semibold">Expire Date</span>
                                    <span className="block">{formattedExpireDate}</span>
                                </div>

                            </div>

                        </div>
                    )} */}
                  <div className="md:flex">

                    {!empty(university)&&(
                    <div className="lg:w-full md:w-full md:mb-8 md:order-2" style={{paddingInline:'1rem'}}>
                      <div style={{ position: 'relative', width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                        <img
                          style={{
                            width: '100%',
                            left: '0',
                            minWidth: '300px',
                            height: '330px',
                            borderRadius: '10px',
                            objectFit: 'cover',
                          }}
                          src={university.cover_image}
                          alt="VIsa Search Image"
                        />
                        <div className='visa-image' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{fontSize:'22px'}}>{university?.name}</span>

                           <div style={{display:'flex',width:'300px',justifyContent:'space-around'}}>
                           {university?.rank_button===1 &&( 
                             <div style={{display:'grid',justifyItems:'center'}}>
                              <span style={{fontSize:'19px'}}># {university.global_rank_number}</span>
                              <span style={{fontSize:'16px'}}>Global</span>
                              </div>
                           )}
                           {university?.national_rank_button===1 &&( 
                              <div style={{display:'grid',justifyItems:'center'}}>
                              <span style={{fontSize:'19px'}}># {university.national_rank_number}</span>
                              <span style={{fontSize:'16px'}}>National</span>
                              </div>
)}
                              <div style={{display:'grid',justifyItems:'center'}}>
                              <span style={{fontSize:'19px'}}>{courseData?.length}</span>
                              <span style={{fontSize:'16px'}}>Course</span>
                              </div>
                              <div style={{display:'grid',justifyItems:'center'}}>
                              <span style={{fontSize:'17px'}}>{reviews.reviewRating}
                              <img
                         src={star}
                         height={20}
                         width={20}
                         alt="Image Description"
                         className="badge-image"
                         style={{ marginLeft: "0px", }}
                       />
                              </span>
                              <span style={{fontSize:'16px'}}>Rating({reviews.userCount})</span>
                              </div>
                              
                           </div>
                          
                            <div className="bagde-flag-wrap2">
                           
                              <img
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    zIndex: '2', 
                                }}
                                src={university.logo} 
                                alt="College Logo"
                              />
                            </div>
                            {university?.rank_button===1 &&( 
                            <div className="bagde-flag-wrap-rank">
                            {/* <span>
                             # {university.global_rank_number}
                             </span> */}
                              </div>
                              )}
                           {/* {university?.rank_button===1 &&( 
                            <div className="bagde-flag-wrap4">
                            <div>
                            <img
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    zIndex: '2', 
                                }}
                                src={rank} 
                                alt="Ranking"
                              />
                              </div>
                              
                            </div>
                          )} */}
                        </div>
                      </div>                                                                                                                                    
                    </div>)}

                    {/* <div className="lg:w-2/5 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0 mb-8 media" 
                     style={{
                              width: '465px',
                              '@media (max-width: 560px)': {
                                width: '317px', 
                              },
                              cursor:'pointer'
                            }}>
                      <div>
                          <div className="sticky top-20"></div>
                            <div className="relative"
                             >
                              <div className="tiny-one-icon-item" style={{marginTop:'-8px'}}>
                                  <TinySlider settings={settings} ref={sliderRef}>
                                          {coursePlans.map((ele, index) => (
                                              <div className="tiny-slide" key={index}>
                                                  <div className="m-2 bg-white dark:bg-slate-900 rounded-lg dark:shadow-gray-800">
                                                  
                                                   
                                                  <div className="product-card">
                                                    { !empty(ele?.partner_u_id) && (<div className="color-price" style={{marginTop:'70px'}}>
                                                      <div className="color-option">
                                                        <span style={{ marginRight: '10px' }}><LuSchool style={{color:'black',fontSize:'1.5rem'}}/>
                                                        </span><span className="color">Partnership with</span>
                                                      </div>
                                                      <div className="price">
                                                      <div className="color-option">
                                                        <span className="color"><span style={{fontWeight:'bold'}}>
                                                          {universities?.find(d=>d.id===ele.partner_u_id)?.name}
                                                          </span></span>
                                                      </div>
                                                      </div>
                                                    </div>)}
                                                    {empty(ele?.partner_u_id) ? 
                                                     <div className="color-price" style={{marginTop:'70px'}}>
                                                      <div className="color-option">
                                                        <span style={{ marginRight: '10px' }}>
                                                          <Users style={{color:'black',fontSize:'1.5rem'}}/>
                                                          </span><span className="color">
                                                          {ele.name}</span>
                                                      </div>
                                                    </div>
                                                    :
                                                    <div className="color-price mt-4">
                                                      <div className="color-option">
                                                        <span style={{marginRight: '15px' ,marginBottom:"25px"}}>
                                                          <Users style={{color:'black'}}/></span>
                                                          <span className="color">
                                                            {ele.name} + <br/>
                                                            {universityDetail?.name}
                                                            </span>
                                                      </div>
                                                    </div> }
                                                    <div className="color-price mt-4">
                                                      <div className="color-option">
                                                      <span style={{marginRight:'10px',fontSize:'25px'}}><span className="icon-park-outline--time" style={{color:'black'}}/>
                                                      </span><span className="color">Course Intake</span>
                                                      </div>
                                                      <div className="price">
                                                      <div className="color-option">
                                                        <span className="color"><span style={{fontWeight:'bold'}}>
                                                          {universityDetail?.intake}
                                                          </span></span>
                                                      </div>
                                                      </div>
                                                    </div>
                                                    
                                                    <div className="color-price mt-4" style={{marginBottom:'20px'}}>
                                                      <div className="color-option">
                                                      <span style={{marginRight:'10px',fontSize:'25px'}}>
                                                        <BiWallet style={{color:'black'}}/>
                                                        </span><span className="color">Fee Yearly</span>
                                                      </div>
                                                      <div className="price">
                                                      <div className="color-option">
                                                      {empty(ele?.partner_u_id) ? 
                                                        <span className="color"><span style={{fontWeight:'bold'}}>${ele.fee}</span></span>
                                                        :
                                                        <span className="color"><span style={{fontWeight:'bold'}}>
                                                          ${ele.fee+universityDetail.fee}
                                                          </span></span> 
                                                        }
                                                      </div>
                                                      </div>
                                                    </div>  
                                                      {coursePlans.length>1 && (
                                                    <div className="color-price" style={{display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center',alignContent:'center'}}>
                                                        <button className="inline-block m-2" onClick={handlePrev}><img className="px-3 py-2 text-slate-400 bg-gray-50 dark:bg-slate-801 text-sm font-semibold rounded-lg shadow items-center" style={{cursor:'pointer'}} width={50} height={50} src={prev} /></button>
                                                        <button className="inline-block m-2" onClick={handleNext}><img className="px-3 py-2 text-slate-400 bg-gray-50 dark:bg-slate-801 text-sm font-semibold rounded-lg shadow items-center" style={{cursor:'pointer',marginRight:'15px'}}  width={50} height={50} src={next}/></button> 
                                                        <span className="color" >
                                                          <li className="inline-block m-2"><Link to="#" className="px-3 py-2 text-slate-400 bg-gray-50 dark:bg-slate-801 text-sm font-semibold rounded-lg shadow items-center">{index+1} / {coursePlans.length}</Link></li>
                                                        </span>
                                                    </div>
                                                    )}  
                                                    
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                                  </TinySlider>
                              </div>
                          </div>
                        </div>
                      </div>                                                                       */}
                  </div>
                </div>

                
                <div className="lg:flex" style={{ marginLeft: '0px', marginTop: '0px'}}>
                    <div className="p-6 w-full" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '20px'}}>
                        <div className="flex flex-wrap justify-between wrapp" style={{ marginBottom: '20px',gap:'20px' }}>
                            
                            <div style={{ marginBottom: '20px' ,marginRight:'30px'}}>
                                <div className="popover-container">
                                    <div className="flex">
                                        
                                       
                                    </div>
                                </div>
                            </div>
                                                
                          
                        </div>
                    </div>
                </div>
                
                <div style={{marginTop:'-45px'}}>

                    <div className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl " style={{ zIndex: 1, marginTop: '10px',display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                   
                    <Link
                      className='font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 '                                  
                      type="button"
                      to={'/consultants/'}                             
                      style={{ color: 'black',width:'550px',height:'70px',display:'flex',justifyContent:'center',textAlign:'center',padding:'22px',borderRadius:'14px'}}
                      >
                      Find Education Consultant
                    </Link>

                  </div>

              </div>

              <hr style={{ marginTop: '-36px', borderColor: 'black', borderWidth: '1px' }} />
            
              <div className="lg:flex" style={{ marginLeft: '0px', marginBottom: '30px' ,marginTop:'30px'}}>
                <div className="p-6 w-full" style={{ display: 'flex', flexDirection: 'column',alignItems:'center',justifyContent:'center' }}>
                  <div className="flex flex-wrap wrapp" style={{width:'100%',height:'100%',justifyContent:'space-evenly'}} >
                 
                    {university.location ?(<div className="color-price" style={{ marginTop:'18px', textAlign:'left'}}>
                      <div className="flex"  style={{marginRight:'10px'}}>
                        <div style={{ fontSize: '22px', color: '#000000', marginRight: '8px' ,marginBottom:"10px"}}>
                        <GoLocation style={{color:'black'}}/>
                        </div>
                        
                        <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                          <Link className="color" 
                          style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'left',display:"flex"}}>Location</Link>
                          <div className="popover-container">
                            <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>
                           { university.location?.split(',').slice(0, 2).join(', ')}
                              </Link>
                           
                          </div>
                          
                        </div>
                      </div>
                    </div>) : ''}
                    {/* {university?.course ? (
  <div className="color-price" style={{ marginTop: '73px', textAlign: 'left' }}>
    <div className="flex">
      <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
        <span className='bxs--school' style={{ color: 'black' }} />
      </div>
      <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
        <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px', textAlign: 'left' }}>Course</Link>
        <div
                          className="flex flex-col items-start scroll-container"
                          style={{
                            height: "70px",
                            border: "1px solid #e6e6e6",
                            // padding: "10px",
                            borderRadius: "7px",
                            cursor: "all-scroll",
                            overflowY: "scroll", // Ensure scrollbar appears only on the y-axis
                            msOverflowStyle: "none", // Internet Explorer 10+
                            scrollbarWidth: "thin", // Firefox
                          }}
                        >
                          <style>
                            {`
                              .scroll-container::-webkit-scrollbar {
                                width: 0.4em;
                              }

                              .scroll-container::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
                              }

                              .scroll-container::-webkit-scrollbar-thumb {
                                background-color: #000;
                                border-radius: 4px;
                              }
                            `}
                          </style>
                         
                          {university?.course.split('.').map((item, index) => (
          // <p  style={{ marginBottom: '8px' }}>
          <div key={index} className="flex items-center mb-2" style={{marginTop:'7px'}}>
                              <img
                                src={ticks}
                                height={19}
                                width={19}
                                alt="Image Description"
                                className="badge-image"
                                style={{ marginLeft: "7px" }}
                              />
            <Link
            key={index}
              className="color"
              style={{ fontSize: '16px', color: '#000000', textAlign: 'left',paddingLeft:'5px' }}
            >
              {item.trim()}
            </Link>
            </div>
          // </p>
        ))}
                        </div>



       
      </div>
    </div>
  </div>
) : ''} */}
                              {university?.course ? (
  <div className="color-price" style={{ marginTop: '22px', textAlign: 'left' }}>
    <div className="flex">
      <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
        <span className='bxs--school' style={{ color: 'black' }} />
      </div>
      <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
        <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px', textAlign: 'left' }}>
          Course
        </Link>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              // fontWeight: 'bold',
              fontSize: '16px', // Increased font size
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              maxWidth: '110px',
              cursor: 'pointer',
              color:'black'
            }}
            onMouseEnter={() => {
              document.getElementById('tooltip-course').style.display = 'block';
            }}
            onMouseLeave={() => {
              document.getElementById('tooltip-course').style.display = 'none';
            }}
          >
            {(() => {
              const courseList = university?.course?.split('.');
              if (courseList.length === 1) {
                return courseList[0].trim();
              } else {
                return `${courseList[0].trim()} & more`;
              }
            })()}
          </div>
          <div
            id="tooltip-course"
            style={{
              display: 'none',
              position: 'absolute',
              backgroundColor: '#fff', // Changed background to white
              border: '1px solid #e6e6e6', // Added border
              color: '#000', // Changed text color to black for better readability
              padding: '10px', // Increased padding for better spacing
              borderRadius: '5px',
              whiteSpace: 'nowrap',
              fontSize: '16px', // Increased font size
              zIndex: 1000,
              top: '-19%',
              left: '50%',
              transform: 'translateX(-50%)',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' // Added subtle shadow for depth
            }}
          >
            {university?.course.split('.').map((item, index) => (
              <div key={index}>{item.trim()}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
) : ''}                                  
             
                    {university?.intake ? (<div className="color-price" style={{ marginTop: '15px', textAlign:'left' }}>
                      <div className="flex" style={{marginRight:'10px'}}>
                        <div style={{ fontSize: '26px', color: '#000000',marginRight: '8px' ,marginBottom:"25px" }}>
                        <Calendar style={{color:'black'}}/>
                        </div>
                        <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                          <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'left'}}
                          >Next Intake</Link>
                          <div className="popover-container">
                            <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>
                              {university?.intake}</Link>
                           
                          </div>
                        </div>
                      </div>
                    </div>) : ''}
                 
                    {university?.score ? (<div className="color-price" style={{ marginTop: '15px', textAlign:'left'}}>
                      <div className="flex items-center" style={{marginRight:'10px'}}>
                      <div style={{ fontSize: '26px', color: '#000000',marginRight: '8px' ,marginBottom:"30px" }}>
                        <span className='material-symbols--credit-score-sharp' style={{color:'black', marginTop: '11px'}}/>
                        </div>
                        <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                          <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'left'}}>
                            Entry Score</Link>
                          <div className="popover-container">
                            <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>
                              {university?.score}
                              </Link>
                          
                          </div>
                        </div>
                      </div>
                    </div>) : ''}
                 
                    {university?.percent ?(<div className="color-price" style={{ marginTop: '40px', textAlign:'left'}}>
                      <div className="flex items-center" style={{marginRight:'10px'}}>
                      <div style={{ fontSize: '26px', color: '#000000' ,marginBottom:"35px" , marginTop: '-8px'}}>
                        <img src={travel} height={25} width={25}  alt="Image Description" className="badge-image"/>
                        </div>
                        <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                          <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'left'}}>
                            International</Link>
                          <div className="popover-container">
                            <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>Students</Link>
                           
                          </div>
                          <Link className="color" style={{ fontSize: '16px', color: '#000000',textAlign:'left'}}>{university?.percent}%</Link>
                        </div>
                      </div>
                    </div>) : ''}
                
                    {university?.url ?(<div className="color-price" style={{ marginTop: '18px', textAlign:'left'}}>
                      <div className="flex items-center" style={{marginRight:'10px'}}>
                      <div style={{ fontSize: '26px', color: '#000000',marginBottom:"35px" }}>
                        <span className='icons8--question-mark' style={{color:'black', marginTop: '11px'}}/>
                        </div>
                        <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                          <Link className="color underlineeee" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'center'}}
                          onClick={()=>window.open(university?.url, '_blank')} >
                          Want's to Know<br/>more</Link>
                          {/* <div className="popover-container1">
                            <Link onClick={()=>window.open(university?.url, '_blank')}  className="color" style={{ fontSize: '16px', color: '#000000',textDecoration:'underline'}}>
                            {university?.url}</Link>
                          </div> */}
                        </div>
                      </div>
                    </div>) : ''}
                  </div>
                  </div>
                </div>   
                
            { !empty(courseData)&& ( 
              <div className="container" style={{marginTop:'60px'}}>
                <Link style={{ fontSize: '24px', color: '#000', marginBottom: '4px',textAlign:'left',fontWeight:'bold'}}
                
                >
              Courses
                </Link>

                
          <div className="max-w-screen-xl mx-auto" style={{ marginTop: '50px',  backgroundColor:'#efefef',borderRadius:'10px',paddingTop:'10px',paddingBottom:'15px' }}>
          <div className="py-5">
            <div className="max-w-screen-xl  mx-auto">
              <div className="mb-4" style={{marginLeft:'12px',marginTop:'12px'}}>
                <input
                  type="text"
                  className="w-2/5 p-2 border border-gray-400 rounded text-black"
                  placeholder="Search courses..."
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              <div style={{ overflowX: 'auto', maxHeight: '400px' }}>
                <table className="w-full text-base" style={{ backgroundColor: '#f1f1f1', border: '1px solid #efefef', textAlign: 'left', marginBottom: '5px' }}>
                <thead style={{ position: 'sticky', top: '-2px', zIndex: 1,}}>
                    <tr className='text-center' style={{ border: '1px solid #dbdbdb', color: '#000', backgroundColor: '#dbdbdb', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '10px' }}>
                      <th className="px-3" style={{whiteSpace:'nowrap'}}>Sl.No</th>
                      <th className="px-3" style={{whiteSpace:'nowrap'}}>Course Name</th>
                      <th className="px-3" style={{whiteSpace:'nowrap'}}>Intake</th>
                      <th className="px-3" style={{whiteSpace:'nowrap'}}>Course Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((item, index) => (
                      <tr className="text-black border-b border-black text-center" key={index}>
                        <td className="px-3" style={{whiteSpace:'nowrap'}}>
                          <Link>{index + 1 + currentPage * itemsPerPage}</Link>
                        </td>
                        <td className="px-3 " style={{whiteSpace:'nowrap'}}>
                          <button 
                          className='underlineeee'
                                                    // to={`/university-details/${item.course_id}/${activeIndex}`}

                          onClick={()=>{
                            item.course_verified ? navigate(`/university-details/${item.course_id}/${activeIndex}`):toast.warning('Comming Soon')
                          }}
                          >{item.course_name}</button>
                        </td>
                        <td className="px-3" style={{whiteSpace:'nowrap'}}>
                        <Link 
                        // onClick={()=>{
                        //     item.course_verified ? navigate(`/university-details/${item.course_id}/${activeIndex}`):toast.warning('Comming Soon')
                        //   }}
                          >{item.course_intake}</Link>
                        </td>
                        <td className="px-3" style={{whiteSpace:'nowrap'}}>
                        <Link 
                        // onClick={()=>{
                        //     item.course_verified ? navigate(`/university-details/${item.course_id}/${activeIndex}`):toast.warning('Comming Soon')
                        //   }}
                          >{item?.fee_symbol} {item.fee} </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                  <hr style={{height: '3px', backgroundColor:' #b3b3b3',width:'100%',marginTop:'-9px'}}/>
              </div>
              
              <ReactPaginate
                previousLabel={<div className='tabler--player-track-prev' />}
                nextLabel={<div className='tabler--player-track-next' />}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </div>
        
                      
               
            </div>)}
            
              <div className="container mt-24">
        </div>
       
                                  
                <div className="container" style={{marginLeft:'0px',marginTop:'40px',marginBottom:'-60px'}}>
                  <div className="flex justify-between">
                  <div style={{display: 'flex', justifyContent: 'center',}}>
                 
                  </div>                           
                  </div>
                </div>               
                {/* <div className="lg:flex scrollbutton" style={{ marginLeft: '0px',marginTop:'200px',backgroundColor: '#000'}}>
                <div className="p-6 w-full" style={{ display: 'flex', flexDirection: 'column' ,marginTop:'-25px',}}>
                  <div className="flex flex-wrap justify-between">
                  
                    <div className="color-price" style={{marginTop: '30px',}}>
                      <div className="flex items-center">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Link className="color" style={{ fontSize: '19px', color: '#fff', fontWeight: 'bold',marginRight:'70px' }}>
                            ${universityDetail?.fee} Yearly</Link>
                        </div>
                      </div>
                    </div>
                                            
                                    
                  </div>
                  </div>
                </div>               */}
                <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 50, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} className={`m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${placeBid ? "" : "hidden" }`}>
                  <div style={{ position: 'relative', width: '100%', height: 'auto', maxWidth: '600px', padding: '4px' }} className="relative w-full h-auto max-w-2xl p-4">
                      <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' }} className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                          {/* Header Section */}
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px 12px', borderBottom: '1px solid #e6e6e6' }} className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-t-gray-800">
                              {/* <h5 style={{ fontSize: '1.5rem', fontWeight: '800', color: 'black' }} className="text-xl font-bold">❤️ Hear from {title} Students</h5> */}
                              <button style={{ color: '#718096', backgroundColor: 'transparent', ':hover': { backgroundColor: '#edf2f7', color: '#2d3748' }, borderRadius: '8px', fontSize: '1rem', padding: '0.375rem 0.75rem', marginLeft: 'auto' }} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                  <IoMdClose style={{fontSize:'1.8rem',color:'black'}} onClick={() => setPlaceBid(!placeBid)} />
                              </button>
                          </div>

                          {/* Scrollable Content Section */}
                          <div id='center' style={{ padding: '1.5rem', overflowY: 'auto', maxHeight: '400px', color: 'black' }} className="p-6 overflow-y-auto max-h-96">
                              {dataArray.map((item, index) => (
                                <>
                                  <div key={index} className='product-card1 mb-4'> {/* Added margin-bottom here */}
                                      <h5 style={{ fontSize: '1rem', fontWeight: '600', marginBottom: '20px' }} className="text-xl font-semibold">{item.heading}</h5>
                                      <p style={{ textAlign: 'start', color: 'black',fontWeight:'500' }}>
                                          {item.content}
                                      </p>
                                      <img src={item.image} style={{ borderRadius: '0.75rem', marginTop: '20px', width: '10rem', height: '10rem' ,objectFit:'cover'}} alt="Your Image" />

                                      <div style={{ paddingTop: '1rem', borderTop: '1px solid #e6e6e6',marginTop:'25px' }} className="pt-4 border-t dark:border-gray-700">
                                          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                          <p style={{ textAlign: 'start', color: 'black', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
                                              {item.name} 
                                              <span style={{ display: 'flex', alignItems: 'center', marginLeft: '10px',color:'rgb(111 118 133/var(--tw-text-opacity))' }}> — <span className="mingcute--location-fill" style={{ fontSize: '19px', marginRight: '5px',marginLeft:'7px' }}></span>{item.from}</span>
                                          </p>

                                          </div>
                                          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                              <span style={{ background: '#000000', borderRadius: '15px', display: 'flex', alignItems: 'center' }} className="bagde-flag1">
                                                  <span className="mingcute--airplane-line" style={{ fontSize: '20px', marginRight: '5px',}}></span>
                                                 {item.location}
                                              </span>
                                          </div>
                                      </div>          
                                  </div>
                                  </>
                              ))}
                          </div>                          
                      </div>
                    </div>
                  </div>
            </section>
            <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        rtl={false}
         draggable
        theme="light"
         />
            <Footer />
           
        </>
    )
}
