import React, { useState } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Select from 'react-select'
import Switcher from '../../components/switcher';
import { Icon } from '@iconify/react';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import { Link } from 'react-router-dom';
import { prpathwayCountry } from '../../data/data';
import {LuClock,LuCalendarDays,RiDragDropLine, LuSearch,AiOutlineAppstore, AiOutlineStar,MdKeyboardArrowLeft, MdKeyboardArrowRight,PiBrowsersLight, MdOutlineSportsVolleyball, AiOutlinePieChart, LuMusic4, TbCameraPlus, PiRocketLaunchBold,FaImages,ImImages, BiImage, BiImages, RiArrowDropDownLine, MdOutlineArrowForward, MdOutlineArrowBack} from "../../assets/icons/vander"

export default function PrPathwaySameCountryListing({ activeIndex,setList,data,selectCountry }) {

    return (
        <>
        <div className="color-price" style={{marginTop:'30px',marginLeft:'20px'}}>
          <div className="flex items-center">
              <Link   
                onClick={()=>setList(false)}
                to={`/pr-pathway`}                                               
                  style={{
                      color: '#000000',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      cursor: 'pointer',
                      marginLeft: '15px',
                      paddingBottom: '3px', 
                      whiteSpace: 'nowrap', 
                      display: 'flex', 
                      alignItems: 'center', 
                  }}
                  className='under'>
                    
                  <MdOutlineArrowBack color='#000' style={{ marginRight: '5px' }} /> 
                  Go back to listing
              </Link>
          </div>
        </div>
         <section className="relative mb-6" style={{marginTop:'30px'}}>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]" style={{padding:'20px'}}>
                        {
                            data?.map((ele, index) => (
                              <Link key={index} to={`/${"pr-pathway"}/${ele.id}/${activeIndex}`} 
                              // <Link key={index} to={`/${"pr-pathway/intro"}/${ele.id}/${activeIndex}`} 
                              style={{display:'flex',alignItems:'stretch',justifyContent:'stretch',alignContent:'stretch'}}>
                                <div key={index} 
                                className="group relative overflow-hidden shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-100 "
                                 style={{borderRadius:'20px',}}>
                                    <img style={{maxWidth:'100%',height:'300px',width:'600px',objectFit: 'cover'}}
                                      src={ele?.cover_image} alt={ele.name} />                                  
                                    <div className="relative p-6">

                                        <div>

                                        <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start' }}>
                        <div className="color-option">
                          <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                          {/* <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px',marginTop: '-8px', }}>
                                              <span class={"ri--visa-fill"}/>
                                              </div> */}
                          <div className="color-option1" >
                                                    <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Link className="color" 
                                                            style={{ fontWeight: 'bold',
                                                             fontSize: '21px',
                                                              color: '#333333',
                                                               maxWidth: 270,
                                                                overflow: 'hidden',
                                                                 textOverflow: 'ellipsis',
                                                                  whiteSpace: 'nowrap',
                                                                   marginLeft: '10px',
                                                                    marginBottom: '6px' }} 
                                                                    to={`/${"pr-pathway"}/${ele.id}/${activeIndex}`}
                                                                    >
                                                                      {ele.name} 
                                                                      </Link>
                                                        </div>
                                                    </div>
                                                </div>
                          </div>
                          </div>
                        </div>
  
                                            
                                            {/* <div className="color-price" 
                                            style={{ marginTop: '30px', marginLeft: '-10px' }}>
                                            <div className="flex items-center">
                                              <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px',marginTop: '-8px', }}>
                                              <span class={"ri--visa-fill"}/>
                                              </div>
                                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Link 
                                                to={`/${"pr-pathway"}/${ele.id}/${activeIndex}`}
                                                 className="color"  
                                                 style={{ fontSize: '18px', color: '#000', marginBottom: '12px',fontWeight:'bold',maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',marginLeft:'10px',marginBottom:'6px'}}>
                                                  {ele.name}
                                                  </Link>
                                              </div>
                                            </div>
                                          </div>  */}
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                                </Link>
                            ))
                        }
                    </div>
                    {/* <div className="container mt-8" style={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                        <div className="flex items-center">
                          <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',}}>
                         Load More
                          </Link>
                          <div style={{marginTop:'17px',fontSize:'40px',color:'#000000',cursor:'pointer' }} >
                          <RiArrowDropDownLine  style={{color:'#000000'}}/>
                          </div>
                        </div> 
                    </div>   */}
            </section>            
        </>
    )
}
