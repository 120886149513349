import React, { useState } from 'react';
import { BsGoogle } from "../../assets/icons/vander";
import NavbarNew from '../../components/navbar-new';
import Footer from '../../components/footer';
import { useParams,useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ADMIN_DASH_URL, REST_API, REST_API_END_POINT } from '../../constants/DefaultValue';
import ResetPassword from './reset-password';
import OTPInput from 'react-otp-input';


export default function VisaResetOtp() {
    const location = useLocation();
    const { otp, email } = location.state;

    // console.log("OTP:", otp);
    // console.log("Email:", email);
    const [otpdata, setOtp] = useState('');

    return (
        // <section className="md:h-screen py-36 flex items-center bg-no-repeat bg-center">
      <OTPInput
        value={otpdata}
        onChange={setOtp}
        numInputs={4}
        renderSeparator={<span>-</span>}
        renderInput={(props) => <input {...props} />}
      />
    //   </section>
    );
}