import React, { useCallback } from 'react'
import image from '../../assets/images/avatar/1.jpg';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import { Link, useNavigate } from 'react-router-dom';
import { Facebook, Instagram, Linkedin, Youtube, Twitter } from 'react-feather';
import { useState } from 'react';
import { storage } from '../../assets/firebase/constant';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useEffect } from 'react';
import { REST_API, REST_API_END_POINT } from '../../constants/DefaultValue';
import axios from 'axios';
export default function CreatorProfileEdit() {
    const userStr = localStorage.getItem('userVisa');
    const user = JSON.parse(userStr) || {};
    // console.log("user", user);
    const initialFormData = {
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
        email: user?.email || '',
        profile_pic: user?.profile_pic || '',
        phoneNumber: user?.phoneNumber || '',

    };
    const [formData, setFormData] = useState(initialFormData);
    const navigate = useNavigate()
    // const loadFile = (event) => {
    //     const image = document.getElementById(event.target.name);
    //     image.src = URL.createObjectURL(event.target.files[0]);
    // };

    const loadFile =
        useCallback(
            (acceptedFiles) => {
                const file = acceptedFiles[0]
                if (file) {
                    const Filename = new Date().getTime() + file.name;

                    const StorageRef = ref(storage, Filename);
                    const uploadTask = uploadBytesResumable(StorageRef, file);
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        },
                        (error) => {
                            console.error(error.message);
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                setFormData((prevFormData) => {
                                    const updatedFormData = { ...prevFormData, profile_pic: downloadURL };
                                    localStorage.setItem('userVisa', JSON.stringify({ ...user, profile_pic: downloadURL }));
                                    return updatedFormData;
                                });
                            });
                        }
                    );
                }
            },
            [user],
        )
    // console.log("formData", formData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData, [name]: value };
            const updatedUser = { ...user, ...updatedFormData };
            localStorage.setItem('userVisa', JSON.stringify(updatedUser));
            return updatedFormData;
        });
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     if (["first_name", "last_name", "email", "phoneNumber"].includes(name)) {
    //         setFormData((prevFormData) => {
    //             const updatedFormData = { ...prevFormData, [name]: value };
    //             const updatedUser = {
    //                 ...JSON.parse(localStorage.getItem('user')),
    //                 [name]: value
    //             };
    //             localStorage.setItem('user', JSON.stringify(updatedUser));
    //             return updatedFormData;
    //         });
    //     }
    // };


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {

            const updatedUser = { ...user, ...formData };
            localStorage.setItem('userVisa', JSON.stringify(updatedUser));
            const response = await axios.post(`${REST_API_END_POINT}update-user-details`, { updatedUser });
            console.log('Response:', response.data);
            console.log('Form data saved to local storage:', formData);
            setFormData(initialFormData);
            navigate('/visa')
        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <>
            {/* <Navbar /> */}
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                {/* <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Edit Profile / Settings</h3>

                    </div>
                </div> */}

                {/* <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Giglink</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Settings</li>
                    </ul>
                </div> */}
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 gap-[30px]">
                        <div className="lg:col-span-3 md:col-span-4">
                            <div className="group profile-pic w-[112px]">
                                <input id="pro-img" name="profile_pic" type="file" className="hidden" onChange={(e) => loadFile(e.target.files)} />
                                <div>
                                    <div className="relative h-28 w-28 rounded-full shadow-md dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800 overflow-hidden">
                                        <img src={formData.profile_pic} className="rounded-full" id="profile-image" alt="Profile" />
                                        <div className="absolute inset-0 group-hover:bg-slate-900/40 transition duration-500"></div>
                                        <label className="absolute inset-0 cursor-pointer" htmlFor="pro-img"></label>
                                    </div>
                                </div>
                            </div>
                            <p className="text-slate-4001 mt-3 w-28 text-center" style={{ fontSize: '12px' }}>
                                We recommend an image of at least 400X400. GIFs work too.
                            </p>
                        </div>

                        <div className="lg:col-span-9 md:col-span-8">
                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                <h5 className="text-lg font-semibold mb-4 text-slate-400">Personal Detail :</h5>
                                <form method='post' onSubmit={handleFormSubmit} style={{ width: '100%' }}>
                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5" style={{ width: '100%' }}>
                                        <div className="text-start">
                                            <label className="form-label font-medium text-slate-400">
                                                First Name :
                                                <span className="text-red-600">
                                                    *</span></label>
                                            <input type="text"
                                                className="form-input w-full text-[15px] 
                                           py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                           rounded-full outline-none border border-gray-200 
                                           focus:border-gray-200 dark:border-gray-200 
                                           dark:focus:border-gray-200 focus:ring-0 mt-2" placeholder="First Name:" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange}
                                            />
                                        </div>
                                        <div className="text-start">
                                            <label className="form-label font-medium text-slate-400">Last Name :
                                                <span className="text-red-600">*</span></label>
                                            <input type="text" className="form-input w-full text-[15px] 
                                           py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                           rounded-full outline-none border border-gray-200 
                                           focus:border-gray-200 dark:border-gray-200 
                                           dark:focus:border-gray-200 focus:ring-0 mt-2"
                                                placeholder="Last Name:" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} />
                                        </div>
                                        <div className="text-start">
                                            <label className="form-label font-medium text-slate-400" >
                                                Your Email :
                                                <span className="text-red-600">*</span>
                                            </label>
                                            <input type="email"
                                                className="form-input w-full text-[15px] 
                                           py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                           rounded-full outline-none border border-gray-200 
                                           focus:border-gray-200 dark:border-gray-200 
                                           dark:focus:border-gray-200 focus:ring-0 mt-2" placeholder="Email" name="email" value={formData.email} onChange={handleChange} />
                                        </div>
                                        <div className="text-start">
                                            <label className="form-label font-medium text-slate-400">
                                                Phone Number : </label>
                                            <input name="phoneNumber" id="phoneNumber" type="number"
                                                className="form-input w-full text-[15px] 
                                           py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                           rounded-full outline-none border border-gray-200 
                                           focus:border-gray-200 dark:border-gray-200 
                                           dark:focus:border-gray-200 focus:ring-0 mt-2" placeholder="Phone Number :" value={formData.phoneNumber} onChange={handleChange} />
                                        </div>


                                        {/* <div className="text-start">
                                            <label className="form-label font-medium text-slate-400">
                                                Change Password :
                                            </label>
                                            <input type="text"
                                                className="form-input w-full text-[15px] 
                                           py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                           rounded-full outline-none border border-gray-200 
                                           focus:border-gray-200 dark:border-gray-200 
                                           dark:focus:border-gray-200 focus:ring-0 mt-2" placeholder="Change Password :" id="change_password" name="change_password"
                                               
                                                onChange={handleChange}
                                            />
                                        </div> */}
                                    </div>
                                    {/* <div className="grid lg:grid-cols-2 grid-cols-1 gap-5" style={{width:'100%'}}>
                                    <div className="text-start" style={{width:'100%'}}>
                                        <div className="mt-5 ">
                                            <label className="form-label font-medium text-slate-400">Description : </label>
                                            <textarea style={{width:'100%'}} name="comments" id="comments"     
                                            className="form-input w-full text-[20px] py-4 px-5 h-40 w-80 bg-transparent rounded-2xl outline-none border border-gray-200 focus:border-gray-200 dark:border-gray-200 dark:focus:border-gray-200 focus:ring-0 mt-4" 
                                            placeholder="Message :"></textarea>
                                        </div>
                                    </div>
                                    </div> */}
                                    <div className='' style={{ width: "100%" }}>

                                        <input type="submit" id="submit" name="send"
                                            className="btn bg-violet-6001 hover:bg-red-600 border-violet-600 over:bg-gray-200 text-white rounded-full mt-5 justify-start"
                                            value="Save Changes" />
                                    </div>
                                </form>
                            </div>

                            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                <h5 className="text-lg font-semibold mb-6">Social Profiles :</h5>

                                <div className="md:flex">
                                    <div className="md:w-1/3">
                                        <span className="font-medium">Twitter</span>
                                    </div>

                                    <div className="md:w-2/3 mt-4 md:mt-0">
                                        <form>
                                            <div className="form-icon relative">
                                                <Twitter className="w-4 h-4 absolute top-3 start-4"></Twitter>
                                                <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Twitter Profile Name" id="twitter_name" name="name" />
                                            </div>
                                        </form>

                                        <p className="text-slate-400 mt-1">Add your Twitter username (e.g. jennyhot).</p>
                                    </div>
                                </div>

                                <div className="md:flex mt-8">
                                    <div className="md:w-1/3">
                                        <span className="font-medium">Facebook</span>
                                    </div>

                                    <div className="md:w-2/3 mt-4 md:mt-0">
                                        <form>
                                            <div className="form-icon relative">
                                                <Facebook className="w-4 h-4 absolute top-3 start-4"></Facebook>
                                                <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Facebook Profile Name" id="facebook_name" name="name" />
                                            </div>
                                        </form>

                                        <p className="text-slate-400 mt-1">Add your Facebook username (e.g. jennyhot).</p>
                                    </div>
                                </div>

                                <div className="md:flex mt-8">
                                    <div className="md:w-1/3">
                                        <span className="font-medium">Instagram</span>
                                    </div>

                                    <div className="md:w-2/3 mt-4 md:mt-0">
                                        <form>
                                            <div className="form-icon relative">
                                                <Instagram className="w-4 h-4 absolute top-3 start-4"></Instagram>
                                                <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Instagram Profile Name" id="insta_name" name="name" />
                                            </div>
                                        </form>

                                        <p className="text-slate-400 mt-1">Add your Instagram username (e.g. jennyhot).</p>
                                    </div>
                                </div>

                                <div className="md:flex mt-8">
                                    <div className="md:w-1/3">
                                        <span className="font-medium">Linkedin</span>
                                    </div>

                                    <div className="md:w-2/3 mt-4 md:mt-0">
                                        <form>
                                            <div className="form-icon relative">
                                                <Linkedin className="w-4 h-4 absolute top-3 start-4"></Linkedin>
                                                <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Linkedin Profile Name" id="linkedin_name" name="name" />
                                            </div>
                                        </form>

                                        <p className="text-slate-400 mt-1">Add your Linkedin username.</p>
                                    </div>
                                </div>

                                <div className="md:flex mt-8">
                                    <div className="md:w-1/3">
                                        <span className="font-medium">Youtube</span>
                                    </div>

                                    <div className="md:w-2/3 mt-4 md:mt-0">
                                        <form>
                                            <div className="form-icon relative">
                                                <Youtube className="w-4 h-4 absolute top-3 start-4"></Youtube>
                                                <input type="url" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Youtube url" id="you_url" name="url" />
                                            </div>
                                        </form>

                                        <p className="text-slate-400 mt-1">Add your Youtube url.</p>
                                    </div>
                                </div>

                                <div className="md:flex">
                                    <div className="md:w-full">
                                        <span className="font-medium"></span>
                                        <button className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full mt-5">Save Social Profile</button>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                <h5 className="text-lg font-semibold mb-6">Account Notifications :</h5>

                                <div className="flex justify-between pb-4">
                                    <h6 className="mb-0 font-medium">When someone mentions me</h6>
                                    <div className="">
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" id="noti1" />
                                        <label className="form-check-label" htmlFor="noti1"></label>
                                    </div>
                                </div>
                                <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                    <h6 className="mb-0 font-medium">When someone follows me</h6>
                                    <div className="">
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti2" />
                                        <label className="form-check-label" htmlFor="noti2"></label>
                                    </div>
                                </div>
                                <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                    <h6 className="mb-0 font-medium">When shares my activity</h6>
                                    <div className="">
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" id="noti3" />
                                        <label className="form-check-label" htmlFor="noti3"></label>
                                    </div>
                                </div>
                                <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                    <h6 className="mb-0 font-medium">When someone messages me</h6>
                                    <div className="">
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" id="noti4" />
                                        <label className="form-check-label" htmlFor="noti4"></label>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                <h5 className="text-lg font-semibold mb-6">Marketing Notifications :</h5>

                                <div className="flex justify-between pb-4">
                                    <h6 className="mb-0 font-medium">There is a sale or promotion</h6>
                                    <div className="">
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" id="noti5" />
                                        <label className="form-check-label" htmlFor="noti5"></label>
                                    </div>
                                </div>
                                <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                    <h6 className="mb-0 font-medium">Company news</h6>
                                    <div className="">
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" id="noti6" />
                                        <label className="form-check-label" htmlFor="noti6"></label>
                                    </div>
                                </div>
                                <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                    <h6 className="mb-0 font-medium">Weekly jobs</h6>
                                    <div className="">
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti7" />
                                        <label className="form-check-label" htmlFor="noti7"></label>
                                    </div>
                                </div>
                                <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                    <h6 className="mb-0 font-medium">Unsubscribe News</h6>
                                    <div className="">
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti8" />
                                        <label className="form-check-label" htmlFor="noti8"></label>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                    <div>
                                        <h5 className="text-lg font-semibold mb-5">Contact Info :</h5>

                                        <form>
                                            <div className="grid grid-cols-1 gap-5">
                                                <div>
                                                    <label className="form-label font-medium">Phone No. :</label>
                                                    <input name="number" id="number" type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Phone :" />
                                                </div>

                                                <div>
                                                    <label className="form-label font-medium">Website :</label>
                                                    <input name="url" id="url" type="url" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Url :" />
                                                </div>
                                            </div>

                                            <button className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full mt-5">Add</button>
                                        </form>
                                    </div>

                                    <div>
                                        <h5 className="text-lg font-semibold mb-5">Change password :</h5>
                                        <form>
                                            <div className="grid grid-cols-1 gap-5">
                                                <div>
                                                    <label className="form-label font-medium">Old password :</label>
                                                    <input type="password" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Old password" />
                                                </div>

                                                <div>
                                                    <label className="form-label font-medium">New password :</label>
                                                    <input type="password" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="New password" />
                                                </div>

                                                <div>
                                                    <label className="form-label font-medium">Re-type New password :</label>
                                                    <input type="password" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Re-type New password" />
                                                </div>
                                            </div>

                                            <button className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full mt-5">Save password</button>
                                        </form>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                <h5 className="text-lg font-semibold mb-5 text-red-600">Delete Account :</h5>

                                <p className="text-slate-400 mb-4">Do you want to delete the account? Please press below "Delete" button</p>

                                <Link to="/explore-two" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-full">Delete</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="relative md:py-24 py-16">
                <div className="container" style={{marginBottom:'-10px',marginTop:'-180px'}}>
                                    <form method="post" name="myForm" id="myForm"
                                    //  onSubmit={handleFormSubmit} 
                                   >
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <h3 className="mb-6 text-3xl leading-snug font-medium">Personal Information :</h3>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-4 mb-5" >
                                                <div className="text-start">
                                                    <label htmlFor="name" className="font-semibold">Full Name: </label>
                                                    <input id="name" name="name" type="text" required className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Enter your full name :"  />
                                                </div>
                                            </div>

                                            <div className="lg:col-span-4 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="email" className="font-semibold">Email Address: </label>
                                                    <input id="email" name="email" type="email" required className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Enter your email address :" />
                                                </div>
                                            </div>
                                            <div className="lg:col-span-4 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="phoneNumber" className="font-semibold">Phone Number: <span className="text-red-600">*</span></label>
                                                    <input id="phoneNumber" name="phoneNumber" required type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Enter your phone number :" />
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <button type="submit" id="submit" name="send" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full justify-center flex items-center">Register Now</button>
                                    </form>
                           
                        
                </div>
            </section> */}
            <Footer />
        </>
    )
}
