import React from 'react'
import logo_white from '../assets/images/logo-icon1.png';
import app from '../assets/images/app.png';
import playstore from '../assets/images/playstore.png';
import { Link } from 'react-router-dom';
import { Mail, Phone } from 'react-feather';
import {AiOutlineShoppingCart, MdKeyboardArrowRight, FaRegEnvelope, BsPencil, FiDribbble, BiLogoBehance, FaLinkedin, LiaFacebookF, AiOutlineInstagram, AiOutlineTwitter } from "../assets/icons/vander"

export default function Footer() {
    return (
        <footer className="footer bg-light-footer relative text-gray-200 dark:text-gray-200 mt-24 border-t border-black">
            <div className="container">
                <div className="grid grid-cols-1">
                    <div className="relative py-16">
                        <div className="relative w-full">
                            {/* <div className="relative -top-50 bg-white dark:bg-slate-900 lg:px-8 px-6 py-10 rounded-xl shadow dark:shadow-gray-800 overflow-hidden">
                                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                                    <div className="md:text-start text-center z-1">
                                        <h3 className="text-[26px] font-semibold text-slate-900 dark:text-white">Subscribe to Newsletter!</h3>
                                        <p className="text-slate-400 max-w-xl mx-auto">Subscribe to get latest updates and information.</p>
                                    </div>

                                    <div className="subcribe-form z-1">
                                        <form className="relative max-w-lg md:ms-auto">
                                            <input type="email" id="subcribe" name="email" className="pt-4 pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-slate-900 dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800" placeholder="Enter your email :" />
                                            <button type="submit" className="btn absolute top-[2px] end-[3px] h-[46px] bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full">Subscribe</button>
                                        </form>
                                    </div>
                                </div>

                                <div className="absolute -top-5 -start-5">
                                    <FaRegEnvelope className="lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5 -rotate-45"/>
                                </div>

                                <div className="absolute -bottom-5 -end-5">
                                   <BsPencil className="lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5"/>
                                </div>
                            </div> */}

                            <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] -mt-44">
                                <div className="lg:col-span-4 md:col-span-12">
                                    <Link to="/" className="text-[22px] focus:outline-none">
                                        <img src={logo_white} width={100} height={100} alt="" />
                                    </Link>
                                    <p className="mt-6 text-black">Build For The World 🌎</p> 
                                    <ul className="list-none md:text-start mt-5">
                                        <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><LiaFacebookF  style={{color:'black'}} className='text-base'/></Link></li>
                                        <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><AiOutlineInstagram  style={{color:'black'}} className='text-base'/></Link></li>
                                        <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><AiOutlineTwitter  style={{color:'black'}} className='text-base'/></Link></li>
                                    </ul>

                                </div>

                                <div className="lg:col-span-2 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-black font-bold">Support</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="#" className="text-[16px] text-black hover:text-black duration-500 ease-in-out inline-flex items-center"> For Travel Agents</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-black hover:text-black duration-500 ease-in-out inline-flex items-center"> About Us</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-black hover:text-black duration-500 ease-in-out inline-flex items-center"> Reviews</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-black hover:text-black duration-500 ease-in-out inline-flex items-center"> Partners</Link></li>
                                    </ul>
                                </div>
                                <div className="lg:col-span-2 md:col-span-4">
                                <h5 className="tracking-[1px] text-lg text-black font-bold">Company</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="#" className="text-[16px] text-black hover:text-black duration-500 ease-in-out inline-flex items-center"  style={{color:'black'}}> Help Center</Link></li>
                                        <li className="mt-[10px]"><Link to={`/contact-form`} className="text-[16px] text-black hover:text-black duration-500 ease-in-out inline-flex items-center"  style={{color:'black'}}> Contact</Link></li>
                                    </ul>
                                </div>

                                <div className="lg:col-span-3 md:col-span-4">
                                    

                                    <h5 className="tracking-[1px] text-lg text-black font-bold">Legal</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="#" className="text-[16px] text-black hover:text-black duration-500 ease-in-out inline-flex items-center"> Privacy</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-black hover:text-black duration-500 ease-in-out inline-flex items-center"> Terms</Link></li>
                                    </ul>
                                </div>

                                {/* <div className="lg:col-span-3 md:col-span-4" style={{marginLeft:'0px'}}>
                                <div className="lg:col-span-3 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-black font-bold">Legal</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="#" className="text-[16px] text-black hover:text-black duration-500 ease-in-out inline-flex items-center"> Privacy</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-black hover:text-black duration-500 ease-in-out inline-flex items-center"> Terms</Link></li>
                                    </ul>
                                </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-[30px] px-0 border-t border-black dark:border-gray-7001">
                <div className="container text-center">
                    <div className="grid md:grid-cols-2 items-center gap-6">
                        <div className="md:text-start text-center">
                            <p className="mb-0 text-black"  style={{color:'black'}}>© {(new Date().getFullYear())}{" "}  Visa Search</p>
                            <p className="mb-0 text-black"  style={{color:'black'}}>Version : 1.8.0</p>
                        </div>

                        {/* <ul className="list-none md:text-end text-center">
                            <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><LiaFacebookF  style={{color:'black'}} className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><AiOutlineInstagram  style={{color:'black'}} className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><AiOutlineTwitter  style={{color:'black'}} className='text-base'/></Link></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </footer>
    )
}
