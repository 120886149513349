import React, { useState,useEffect } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Select from 'react-select'
import Switcher from '../../components/switcher';
import { Icon } from '@iconify/react';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import { Link } from 'react-router-dom';
import { health } from '../../data/data';
import metamask from '../../assets/images/wallet/metamask.png';
import alpha from '../../assets/images/wallet/alpha.png';
import binance from '../../assets/images/wallet/binance.png';
import bitski from '../../assets/images/wallet/bitski.png';
import coinbase from '../../assets/images/wallet/coinbase.png';
import enjin from '../../assets/images/wallet/enjin.png';
import formatic from '../../assets/images/wallet/formatic.png';
import pionex from '../../assets/images/wallet/pionex.png';
import torus from '../../assets/images/wallet/torus.png';
import trustwallet from '../../assets/images/wallet/trustwallet.png';
import connect from '../../assets/images/wallet/wallet-connect.png';
import {MdOutlineArrowForward} from "../../assets/icons/vander"
import {LuClock,LuCalendarDays,RiDragDropLine, LuSearch,AiOutlineAppstore, AiOutlineStar,MdKeyboardArrowLeft, MdKeyboardArrowRight,PiBrowsersLight, MdOutlineSportsVolleyball, AiOutlinePieChart, LuMusic4, TbCameraPlus, PiRocketLaunchBold,FaImages,ImImages, BiImage, BiImages} from "../../assets/icons/vander"
import QA from '../../components/qa';


const options = [
    { value: 'Art', label: 'Art' },
    { value: 'Domain', label: 'Domain Names' },
    { value: 'Music', label: 'Music' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Trading', label: 'Trading Cards' },
    { value: 'Virtual', label: 'Virtual World' },
];

const buyOptions = [
    { value: 'now', label: 'Buy Now' },
    { value: 'auctions', label: 'Auctions' },
    { value: 'offers', label: 'Offers' }
]
const rateOptions = [
    { value: 'top', label: 'Top Rated' },
    { value: 'low', label: 'Lower Rated' }
]
export default function DemoTesting() {

    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);

    const [selectedCategory, setSelectedCategory] = useState(null);

    const [activeIndex, setIndex] = useState(0);


  
    const matchCategory = (category) => {
        setSelectedCategory(category);
    };

    const filteredData = selectedCategory
        ? health.filter((item) => item.category === selectedCategory)
        : health;

        const health1 = 'https://www.healthopinion.net/images/blogs/indian-medical-visa.jpg'
        const health2 = 'https://pgsmedtourism.com/wp-content/uploads/elementor/thumbs/medical-visa-1-pzixb83j2bknkdolhqpg7205p5xl50ia8jui693aqa.jpg'
        const health3 = 'https://workstudyvisa.com/wp-content/uploads/2022/07/Ukraine-Medical-Treatment-visa.jpg'
        const health4 = 'https://www.vaidam.com/sites/default/files/visa.jpg'

        const walletData = [
            {
                title: 'Accessible travel',
                image: health1,
                p:'Explore what type of assistance we offer people with determination or passengers who require a medical escort or service animal on their journey.',
            },
            {
                title: 'Special assistance and requests',
                image: health2,
                p:'Learn more about our policy on taking special medication on your journey, traveling with service animals and rules and regulations for passengers traveling with young children and infants.',
            },
            {
                title: 'Dietary requirements',
                p:'Read our allergy advice and have a look at the different dietary meals we serve for specific diets and how you can book one on your flight.',
                image: health3
            },
            {
                title: 'Travelling with infants',
                p:'Plan your baby’s journey with extra comforts, from baby bassinets and extra baggage allowance, to dietary meals and toys.',
                image: health4
            },
           
        ]    
        // const spanStyle = {
        //     background: `linear-gradient(90deg,#000000,#000000,#ffffff,#ffffff,#000000,#000000)`, 
        //     WebkitBackgroundClip: 'text',
        //     color: 'transparent',
        //     display: 'inline-block',
        //   };

    return (
        <>
         {/* <Navbar /> */}

<section className="relative lg:pt-24 pt-[74px] overflow-hidden"
      //  style={{ background: 'linear-gradient(to right, #ff8c00, #eecf36, #eecf36, #eecf36, #eecf36, #ff8c00)' }}
       style={{ backgroundColor: '#000000' }}
      //  style={{ backgroundColor: '#5057ea' }}
       >
        <div className="container-fluid relative overflow-hidden">
          <div className="lg:pt-26 lg:pb-26 pt-26 pb-366 shadow dark:shadow-gray-800">
            <div className="container">
              <div className="grid grid-cols-1 justify-center text-center">
                <div className="relative">                
                    <>
                      <p className="text-lg max-w-xl mx-auto mb-5" 
                      style={{
                        //  color: '#4eee37',
                         color: '#ffffff',
                       fontWeight: 'bold' 
                      }}>Delays shouldn't stand between you and your destination</p>
                      <div className="relative ">
                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Ensuring your journey is seamless </h1>
                      </div>
                    </>                                                                                                                                              
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:pb-24 pb-16 -mt-16">
        <div className="container z-1">
          <div className="search-container">
          <div className="wrapper">
            <div className="search_box">            

              <div className="search">
                <input type="text" placeholder='Travel Advice' />
                <div className="autocomp-box"></div>
                <div className="icon">
                  <LuSearch style={{color:'black'}} />
                </div>
              </div>
            </div>
          </div>

          </div>
        </div>
        </section>
        

            <section className="relative md:py-24 py-16 ">
                <div className="container">
                    <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-[30px]" style={{marginTop:'-120px',}}>
                        {
                            walletData.map((ele, index) => (
                                <div key={index} className="group relative rounded-xl bg-white dark:bg-slate-900 shadow p-6 text-center mt-10 dark:shadow-gray-700 hover:shadow-md transition-all duration-500">
                                    <div className="relative -mt-16">
                                        <img src={ele.image} className="bg-white dark:bg-slate-900 h-20 w-20 rounded-full shadow-md dark:shadow-gray-8001 mx-auto p-3" alt="" />
                                        <h5 className="text-lg font-semibold mt-5" style={{color:'#000'}}>{ele.title}</h5>
                                        <p className="text-slate-4001 mt-3">{ele.p} 
                                        {/* <Link to="#" data-modal-toggle="loginform" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center">here <MdOutlineArrowForward className="ms-1 text-base"/></Link> */}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                
            </section>

            <section className="relative md:py-24 py-16" style={{marginBottom:'80px',marginTop:'-160px',}}>
                <QA/>
            </section>

            <section className="relative mb-6" style={{marginTop:'-90px',}}>
                <div className="grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 gap-[30px]" style={{padding:'20px'}}>
                        {
                            filteredData.map((ele, index) => (
                                <div key={index} className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-500">
                                    <img style={{objectFit:'cover',height:'259px',width:'722px'}} src={ele.image} alt="" />

                                    <div className="relative p-6">
                                        

                                    <div className="text-center" style={{textAlign:'center'}}>
                                      <Link className="title text-lg font-medium duration-500 ease-in-out" style={{color:'#000'}}>{ele.title}</Link>
                                      <div className="flex justify-center mt-4">
                                        <Link className="btn btn-link text-[16px] font-bold inline-flex items-center" style={{color:'#858585',}}>{ele.subtext}</Link>
                                      </div>
                                    </div>

                                    </div>
                                </div>
                            ))
                        }
                    </div>
            </section>
        </>
    )
}
