import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { REST_API_END_POINT } from "../../constants/DefaultValue";
import ContactImgs from "../../assets/images/top-view-chat-bubbles-with-telephone-receiver-copy-space.jpg";
import { Icon } from '@iconify/react';
import chevronDown from '@iconify/icons-ph/caret-down-bold';
import ReactPaginate from "react-paginate";
import StarRatingComponent from "react-star-rating-component";
import Navbar from "../../components/navbar";
import mdiDelete from '@iconify/icons-ph/trash';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css' ;
import { BsExclamationOctagon } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import NavbarNew from "../../components/navbar-new";
const STATUS = [
  { id: 1, label: 'New', color: '#758694' },
  { id: 2, label: 'In Progress', color: '#009FBD' },
  { id: 3, label: 'Completed', color: '#54D62C' },
  { id: 4, label: 'Payment Done', color: '#FF8F00' },
  { id: 5, label: 'Any Other Relevant Status', color: '#405D72' },
];

export default function EnquiryList() {
  const user = JSON.parse(localStorage.getItem('userVisa'));
  const [search, setSearch] = useState("");
  const [searchUniversity, setSearchUniversity] = useState("");
  const [searchVisa, setSearchVisa] = useState("");
  const [searchPR, setSearchPR] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [languageEnq, setLanguageEnquiry] = useState([]);
  const [visaEnquiry, setVisaEnquiry] = useState([]);
  const [prEnquiry, setPrEnquiry] = useState([]);
  const [universityEnquiry, setUniversityEnquiry] = useState([]);
  const [ratings, setRatings] = useState({});
  const [ratingData, setRatingData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [buyNow, setBuyNow] = useState(false);
  const [urldata, setUrlData] = useState('');
  const [urldataId, setUrlDataId] = useState('');
  const itemsPerPage = 10;
console.log("ratingData",ratingData);

  const handleStarClick = (nextValue, itemId, url,from_id,university_id) => {
    setRatings({
      ...ratings,
      [itemId]: nextValue
    });
    setLanguageEnquiry(prevState => prevState.map(item => {
      if (item.language_id === itemId) {
        return { ...item, rating: nextValue };
      }
      return item;
    }));
    axios.post(`${REST_API_END_POINT}${url}/add-review`, { 
      id:itemId,
      value:nextValue,
      user_id:user?.user_id,
      from_id:from_id,
      university_id:university_id
    })
      .then(response => {
        if(response.data.status==1){
            toast.success("Rating updated successfully");
        }
      })
      .catch(error => {
        toast.error("Error updating rating");
      });
  };
  // let enquiryId=[]
  let deletenq=false
  const submitDelete = (url,id) => {
    setBuyNow(!buyNow)
    setUrlData(url)
    setUrlDataId(id)
      // confirmAlert({
      //   title: 'Delete Confirmation',
      //   message: 'Are you sure to do this.',
      //   buttons: [
      //     {
      //       label: 'Yes',
      //       onClick: () => alert('Click Yes')
      //     },
      //     {
      //       label: 'No',
      //       onClick: () => alert('Click No')
      //     }
      //   ]
      // })
    };
    


    const deleteEnquiry=()=>{
      // enquiryId=id
      axios.post(`${REST_API_END_POINT}${urldata}/delete-enquiry`, { 
        ids:urldataId
      })
        .then(response => {
          if(response.data.status==1){
            setRefresh(!refresh)
            setBuyNow(!buyNow)
            setUrlData('')
            setUrlDataId('')
              toast.success(response.data.message)
          }
          else{
            toast.error(response.data.message)
            setBuyNow(!buyNow)
          }
        })
        .catch(error => {
          toast.error("Error delete enquiry");
          setBuyNow(!buyNow)
        });
    }





  useEffect(() => {
    // Fetch data based on the current page and search query
    const fetchTicketData = async () => {
      try {
        const response = await axios.post(`${REST_API_END_POINT}user-enquires/get-by-user-id`, {
          user_id: user.user_id
        });
        if (response.data.status === 1) {
          const languageData = response.data.language_enquiry.map(item => {
            const rating = response.data.rating.find(r => r.source_id === item.language_id && r.from_id === 6);
            return { ...item, rating: rating ? rating.rating : 0 };
          });
          setLanguageEnquiry(languageData);
          setVisaEnquiry(response.data.visaEnquiry);
          setPrEnquiry(response.data.prEnquiry);
          setUniversityEnquiry(response.data.universityEnquiry);
          setRatingData(response.data.rating);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchTicketData();
  }, [refresh]);
console.log("languageEnq",languageEnq);
console.log("prEnquiry",prEnquiry);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };





  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleSearchUniversity = (event) => {
    setSearchUniversity(event.target.value);
  };
  const handleSearchPR = (event) => {
    setSearchPR(event.target.value);
  };
  const handleSearchVisa = (event) => {
    setSearchVisa(event.target.value);
  };

  const filteredData = languageEnq.filter(item => {
    return (
      item.language_consult.toLowerCase().includes(search.toLowerCase()) ||
      STATUS.find(status => status.id === item.status)?.label.toLowerCase().includes(search.toLowerCase())
    );
  });
const UNI_filteredData=universityEnquiry.filter(item => {
    return (
      item.course_name.toLowerCase().includes(searchUniversity.toLowerCase()) ||item.university_name.toLowerCase().includes(searchUniversity.toLowerCase())||
      STATUS.find(status => status.id === item.status)?.label.toLowerCase().includes(searchUniversity.toLowerCase())
    );
  });
const VISA_filteredData=visaEnquiry.filter(item => {
    return (
      item.country_name.toLowerCase().includes(searchVisa.toLowerCase()) ||item.visa_plan_name.toLowerCase().includes(searchVisa.toLowerCase())||
      STATUS.find(status => status.id === item.status)?.label.toLowerCase().includes(searchVisa.toLowerCase())
    );
  });
const PR_filteredData=prEnquiry?.filter(item => {
    return (
      item.country_name.toLowerCase().includes(searchPR.toLowerCase()) ||item.plan_name.toLowerCase().includes(searchPR.toLowerCase())||
      STATUS.find(status => status.id === item.status)?.label.toLowerCase().includes(searchPR.toLowerCase())
    );
  });
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const universityPgeCount = Math.ceil(UNI_filteredData.length / itemsPerPage);
  const visaPageCount = Math.ceil(VISA_filteredData.length / itemsPerPage);
  const PRPageCount = Math.ceil(PR_filteredData.length / itemsPerPage);

  const paginatedData = filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const UniversityData = UNI_filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const VisaData = VISA_filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const PRData = PR_filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  return (
    <>
      {/* <section
        className="relative table w-full py-36 bg-no-repeat"
        style={{ backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url(${ContactImgs})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container">
          <div style={{ backdropFilter: "blur(20px)", WebkitBackdropFilter: "blur(20px)", borderRadius: '5px' }} className="grid grid-cols-1 pb-8 text-center mt-10">
            <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">
              <span style={{ color: "#fff" }}>Contact Us</span>
            </h4>
            <p className="max-w-xl mx-auto text-3xl lg:text-4xl" style={{ color: '#e6e6e6' }}>
              Need Help with Your Visa? Contact Us!
            </p>
          </div>
        </div>
      </section> */}
      <NavbarNew noHomeTab={true} />
      <section style={{marginTop:'70px'}} className="relative md:py-16 py-10">
      {languageEnq.length>0&&(
        <div className="container" style={{ marginTop: '10px' }}>
          <Link style={{ fontSize: '24px', color: '#000', textAlign: 'left', fontWeight: 'bold' }}>
            Language consultant
          </Link>

          <div className="max-w-screen-xl mx-auto" style={{ marginTop: '30px', backgroundColor: '#efefef', borderRadius: '10px', paddingTop: '10px', paddingBottom: '15px' }}>
            <div className="py-5">
              <div className="max-w-screen-xl mx-auto">
                <div className="mb-4" style={{ marginLeft: '12px', marginTop: '12px' }}>
                  {/* <input
                    type="text"
                    className="w-2/5 p-2 border border-gray-400 rounded text-black"
                    placeholder="Search courses..."
                    value={search}
                    onChange={handleSearch}
                  /> */}
                </div>
                <div style={{ overflowX: 'auto', maxHeight: '400px' }}>
                  <table className="w-full text-base" style={{ backgroundColor: '#f1f1f1', border: '1px solid #efefef', textAlign: 'left', marginBottom: '5px' }}>
                    <thead style={{ position: 'sticky', top: '-2px', zIndex: 1 }}>
                      <tr className='text-center' style={{ border: '1px solid #dbdbdb', color: '#000', backgroundColor: '#dbdbdb', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '10px' }}>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Sl.No</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Name</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Status</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((item, index) => {
                        console.log("itemitemitemitemitemitem",item);
                        
                        const status = STATUS.find(s => s.id === item.status) || {};
                        return (
                          <tr className="text-black border-b border-black text-center" key={index}>
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <Link>{index + 1 + currentPage * itemsPerPage}</Link>
                            </td>
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <button className='underline'>
                                {item.language_consult}
                              </button>
                            </td>
                            <td className="px-3" style={{ whiteSpace: 'nowrap', backgroundColor: status.color, color: '#fff' }}>
                              <Link>
                                {status.label}
                              </Link>
                            </td>
                            { (item.status === 3 || item.status === 4) ? (
                     <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                  <StarRatingComponent
                   name={`rating-language-${item.language_id}`}
                    starCount={5}
                    value={item.rating|| 0}
                    onStarClick={(nextValue) => handleStarClick(nextValue, item.language_id,'language',6)}
                  />
                </td>
              )
              :(
                <td className="px-3" style={{ whiteSpace: 'nowrap' ,}}>

    <Link style={{display:'flex',alignItems:'center',justifyContent:'center'}} type="button" onClick={()=>submitDelete('language',item.id,)} >
      <span>Delete</span>
      <Icon icon={mdiDelete} style={{ fontSize: '24px', color: 'red', cursor: 'pointer', marginLeft: '8px' }} />
      
    </Link>
</td>

            
              )
              }
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <hr style={{ height: '3px', backgroundColor: '#b3b3b3', width: '100%', marginTop: '-9px' }} />
                </div>

                <ReactPaginate
                  previousLabel={<div className='tabler--player-track-prev' />}
                  nextLabel={<div className='tabler--player-track-next' />}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </div>
    )}
        {universityEnquiry.length>0&&(
        <div className="container" style={{ marginTop: '10px' }}>
          <Link style={{ fontSize: '24px', color: '#000', textAlign: 'left', fontWeight: 'bold' }}>
            University consultant
          </Link>

          <div className="max-w-screen-xl mx-auto" style={{ marginTop: '30px', backgroundColor: '#efefef', borderRadius: '10px', paddingTop: '10px', paddingBottom: '15px' }}>
            <div className="py-5">
              <div className="max-w-screen-xl mx-auto">
                <div className="mb-4" style={{ marginLeft: '12px', marginTop: '12px' }}>
                  {/* <input
                    type="text"
                    className="w-2/5 p-2 border border-gray-400 rounded text-black"
                    placeholder="Search University..."
                    value={searchUniversity}
                    onChange={handleSearchUniversity}
                  /> */}
                </div>
                <div style={{ overflowX: 'auto', maxHeight: '400px' }}>
                  <table className="w-full text-base" style={{ backgroundColor: '#f1f1f1', border: '1px solid #efefef', textAlign: 'left', marginBottom: '5px' }}>
                    <thead style={{ position: 'sticky', top: '-2px', zIndex: 1 }}>
                      <tr className='text-center' style={{ border: '1px solid #dbdbdb', color: '#000', backgroundColor: '#dbdbdb', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '10px' }}>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Sl.No</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>University</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Course Name</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Status</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {UniversityData.map((item, index) => {
                        console.log("itemitemitemitemitem",item);
                        
                        const status = STATUS.find(s => s.id === item.status) || {};
                        return (
                          <tr className="text-black border-b border-black text-center" key={index}>
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <Link>{index + 1 + currentPage * itemsPerPage}</Link>
                            </td>
                           
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <button className='underline'>
                                {item.university_name}
                              </button>
                            </td>
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <button className='underline'>
                                {item.course_name}
                              </button>
                            </td>
                            <td className="px-3" style={{ whiteSpace: 'nowrap', backgroundColor: status.color, color: '#fff' }}>
                              <Link>
                                {status.label}
                              </Link>
                            </td>
                            { (item.status === 3 || item.status === 4) ? (
                     <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                  <StarRatingComponent
                     name={`rating-university-${item.course_id}`}
                    starCount={5}
                    value={ratings[item.course_id] || 0}
                    onStarClick={(nextValue) => handleStarClick(nextValue, item.course_id,'university',4,item.university_id)}
                  />
                </td>
              ):(
                <td className="px-3" style={{ whiteSpace: 'nowrap' ,}}>

    <Link style={{display:'flex',alignItems:'center',justifyContent:'center'}} type="button" onClick={()=>submitDelete('university',item.id,)} >
      <span>Delete</span>
      <Icon icon={mdiDelete} style={{ fontSize: '24px', color: 'red', cursor: 'pointer', marginLeft: '8px' }} />
      
    </Link>
</td>

            
              )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <hr style={{ height: '3px', backgroundColor: '#b3b3b3', width: '100%', marginTop: '-9px' }} />
                </div>

                <ReactPaginate
                  previousLabel={<div className='tabler--player-track-prev' />}
                  nextLabel={<div className='tabler--player-track-next' />}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={universityPgeCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </div>
        )}
         {visaEnquiry.length>0&&(
        <div className="container" style={{ marginTop: '10px' }}>
          <Link style={{ fontSize: '24px', color: '#000', textAlign: 'left', fontWeight: 'bold' }}>
            Visa 
          </Link>

          <div className="max-w-screen-xl mx-auto" style={{ marginTop: '30px', backgroundColor: '#efefef', borderRadius: '10px', paddingTop: '10px', paddingBottom: '15px' }}>
            <div className="py-5">
              <div className="max-w-screen-xl mx-auto">
                <div className="mb-4" style={{ marginLeft: '12px', marginTop: '12px' }}>
                  {/* <input
                    type="text"
                    className="w-2/5 p-2 border border-gray-400 rounded text-black"
                    placeholder="Search Visa..."
                    value={searchVisa}
                    onChange={handleSearchVisa}
                  /> */}
                </div>
               
                <div style={{ overflowX: 'auto', maxHeight: '400px' }}>
                  <table className="w-full text-base" style={{ backgroundColor: '#f1f1f1', border: '1px solid #efefef', textAlign: 'left', marginBottom: '5px' }}>
                    <thead style={{ position: 'sticky', top: '-2px', zIndex: 1 }}>
                      <tr className='text-center' style={{ border: '1px solid #dbdbdb', color: '#000', backgroundColor: '#dbdbdb', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '10px' }}>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Sl.No</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Country Name</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Type Of Visa</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Visa Plan</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Status</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {VisaData.map((item, index) => {
                        console.log("itemitemitem",item);
                        
                        const status = STATUS.find(s => s.id === item.status) || {};
                        return (
                          <tr className="text-black border-b border-black text-center" key={index}>
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <Link>{index + 1 + currentPage * itemsPerPage}</Link>                          
                            </td>
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <button className='underline'>
                                {item.country_name}
                              </button>
                            </td>    
                                            
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <button className='underline'>
                                {item.type_of_visa==1? 'Travel':item.type_of_visa==2 ? 'Work':'Student'}
                              </button>
                            </td>
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <button className='underline'>
                                {item.visa_plan_name}
                              </button>
                            </td>
                            <td className="px-3" style={{ whiteSpace: 'nowrap', backgroundColor: status.color, color: '#fff' }}>
                              <Link>
                                {status.label}
                              </Link>
                            </td>
                            { (item.status === 3 || item.status === 4) ? (
                     <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                  <StarRatingComponent
                    name={`rating-visa-${item.visa_plans}`} 
                    starCount={5}
                    value={ratings[item.visa_plans] || 0}
                    onStarClick={(nextValue) => handleStarClick(nextValue, item.visa_plans,'visa',1)}
                  />
                </td>
              ):(
                <td className="px-3" style={{ whiteSpace: 'nowrap' ,}}>

    <Link style={{display:'flex',alignItems:'center',justifyContent:'center'}} type="button" onClick={()=>submitDelete('visa',item.id,)} >
      <span>Delete</span>
      <Icon icon={mdiDelete} style={{ fontSize: '24px', color: 'red', cursor: 'pointer', marginLeft: '8px' }} />
      
    </Link>
</td>

            
              )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <hr style={{ height: '3px', backgroundColor: '#b3b3b3', width: '100%', marginTop: '-9px' }} />
                </div>

                <ReactPaginate
                  previousLabel={<div className='tabler--player-track-prev' />}
                  nextLabel={<div className='tabler--player-track-next' />}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={visaPageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </div>
        )}
         {prEnquiry.length>0&&(
        <div className="container" style={{ marginTop: '10px' }}>
          <Link style={{ fontSize: '24px', color: '#000', textAlign: 'left', fontWeight: 'bold' }}>
            PR 
          </Link>

          <div className="max-w-screen-xl mx-auto" style={{ marginTop: '30px', backgroundColor: '#efefef', borderRadius: '10px', paddingTop: '10px', paddingBottom: '15px' }}>
            <div className="py-5">
              <div className="max-w-screen-xl mx-auto">
                <div className="mb-4" style={{ marginLeft: '12px', marginTop: '12px' }}>
                  {/* <input
                    type="text"
                    className="w-2/5 p-2 border border-gray-400 rounded text-black"
                    placeholder="Search PR..."
                    value={searchPR}
                    onChange={handleSearchPR}
                  /> */}
                </div>
                <div style={{ overflowX: 'auto', maxHeight: '400px' }}>
                  <table className="w-full text-base" style={{ backgroundColor: '#f1f1f1', border: '1px solid #efefef', textAlign: 'left', marginBottom: '5px' }}>
                    <thead style={{ position: 'sticky', top: '-2px', zIndex: 1 }}>
                      <tr className='text-center' style={{ border: '1px solid #dbdbdb', color: '#000', backgroundColor: '#dbdbdb', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '10px' }}>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Sl.No</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Country Name</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Type Of Visa</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Visa Plan</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}>Status</th>
                        <th className="px-3" style={{ whiteSpace: 'nowrap' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {PRData.map((item, index) => {
                        console.log("rreeeeeeeeeeeeeeeeeeee",item);
                        
                        const status = STATUS.find(s => s.id === item.status) || {};
                        return (
                          <tr className="text-black border-b border-black text-center" key={index}>
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <Link>{index + 1 + currentPage * itemsPerPage}</Link>                          
                            </td>                         
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <button className='underline'>
                                {item.country_name}
                              </button>
                             
                            </td>                        
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <button className='underline'>
                                {item.pr_name}
                              </button>
                            </td>
                            <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                              <button className='underline'>
                                {item.plan_name} ({item.sub_class})
                              </button>
                            </td>
                            <td className="px-3" style={{ whiteSpace: 'nowrap', backgroundColor: status.color, color: '#fff' }}>
                              <Link>
                                {status.label}
                              </Link> 
                            </td>
                            { (item.status === 3 || item.status === 4) ? (
                     <td className="px-3" style={{ whiteSpace: 'nowrap' }}>
                  <StarRatingComponent
                    name={`rating-pr-${item.pr_plan}`} 
                    starCount={5}
                    value={ratings[item.pr_plan] || 0}
                    onStarClick={(nextValue) => handleStarClick(nextValue, item.pr_plan,'pr',2)}
                  />
                </td>
              ):(
                <td className="px-3" style={{ whiteSpace: 'nowrap' ,}}>

    <Link style={{display:'flex',alignItems:'center',justifyContent:'center'}} type="button" onClick={()=>submitDelete('pr',item.id,)} >
      <span>Delete</span>
      <Icon icon={mdiDelete} style={{ fontSize: '24px', color: 'red', cursor: 'pointer', marginLeft: '8px' }} />
      
    </Link>
</td>

            
              )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <hr style={{ height: '3px', backgroundColor: '#b3b3b3', width: '100%', marginTop: '-9px' }} />
                </div>

                <ReactPaginate
                  previousLabel={<div className='tabler--player-track-prev' />}
                  nextLabel={<div className='tabler--player-track-next' />}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={prEnquiry}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </div>
        )}

<div  className={`fixed z-50 overflow-hidden inset-0 m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${buyNow ? "" :"hidden"}`}>
                <div className="relative w-full h-auto max-w-md p-4">
                    <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-gray-700">
                            <h5 className="text-xl font-semibold text-black">Delete Confirmation</h5>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" >
                                <IoMdClose  onClick={() => setBuyNow(!buyNow)}/>
                            </button>
                        </div>
                        <div className="p-6">
                            <div className="flex items-center p-4 mb-2 rounded-lg" style={{backgroundColor:'rgb(255, 226, 225)',color:'rgb(255, 72, 66)'}}>
                                <BsExclamationOctagon className="text-3xl" style={{color:'red'}}/>

                                <div className="ms-2">
                                    <span className="block font-semibold">Are you sure you want to delete this ?</span>
                                    {/* <span className="block">Purchase this item at your own risk</span> */}
                                </div>
                            </div>

                            <div className="mt-4" style={{display:'flex',}}>
                                <Link to="#" className="btn rounded-full bg-violet-100  text-white w-full"onClick={()=>deleteEnquiry()} style={{marginRight:"10px"}}> <i className="mdi mdi-lightning-bolt"></i> Confirm</Link>
                                <Link to="#" className="btn rounded-full text-black w-full" style={{backgroundColor:'white',borderColor:'black'}}  onClick={() => setBuyNow(!buyNow)}> Cancel</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </section>
      <ToastContainer />
    </>
  );
}
