import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REST_API_END_POINT } from "../../constants/DefaultValue";
import PRVisaImage from "../../assets/images/permanent-resident-card-us-map.jpg";
import { empty } from "../../common/Helper";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../assets/firebase/constant";
import moment from "moment";


const VISA_TYPE = [
  { id: 1, name: "Travel" },
  { id: 2, name: "Work" },
  { id: 3, name: "Student" },
];

export default function PRvisaForm() {
  const location = useLocation()
  const consultId = location.state?.consultId
  const [countryPrefer, setCountryPrefer] = useState({});
  const [prCountry, setPrCountry] = useState([])
  const [prData,setPRData] = useState([])
  const [prDataPlans,setPrDataPlans] = useState([])
  const [countries, setCountries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [visaDetail, setVisaDetails] = useState([]);
  const [pdfPreviews, setPdfPreviews] = useState({});
  // Function to toggle the modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleGoBack = () => {
    window.history.back();
  };

  const [formData, setFormData] = useState({
    first_name: userData?.first_name,
    last_name: userData?.last_name,
    phone_number: userData.phoneNumber,
    dob: "",
    nationality: "",
    address: "",
    email: userData.email,
    reason_for_migration: "",
    destination_country: "",
    pr_type:"",
    selected_pr_plan: "",
    passport: "",
    resume:'',
    educational_certificates:'',
    references:"",
    occupation: "",
    current_employer:'',
    years_of_experience:"",
    level_of_education:'',
    field_of_study:'',
    name_of_institution:'',
    year_of_graduation:'',
    relatives:'',
    comments:'',
  });

  const handleFileChange = (event) => {
    const { id, files } = event.target;
    if (files.length > 0) {
      const file = files[0];
      if (file.type === 'application/pdf') {
        loadFile(event);
        const fileUrl = URL.createObjectURL(file);
        setPdfPreviews((prev) => ({
          ...prev,
          [id]: fileUrl,
        }));
      }
    }
  };
  const fetchPRCountries = () => {
    axios.post(`${REST_API_END_POINT}pr_pathway/get-all-pr`,{from_user:true})
      .then((res) => {
        if (res.data.status === 1) {
          setPrCountry(res.data.data?.filter(d => d.status == 1))
        }
        else{
          setPrCountry([])
        }
      })
      .catch((err)=>{
        console.log(err)
    })
  }


const fetchPR_by_coutryId = async () => {
    axios.post(`${REST_API_END_POINT}pr-country/get-list`, { id :formData.destination_country?.id})
      .then((res) => {
        if (res.data.status === 1) {
          setPRData(res.data.data?.filter(d => d.status == 1))
        }
      })
      .catch((err)=>{
        console.log(err)
    })
  }

useEffect(()=>{
    fetchPR_by_coutryId()
},[formData.destination_country])

const fetchDataByprID=()=>{
    if(!empty(formData?.pr_type)){
    axios.get(`${REST_API_END_POINT}pr-pathway/get-pr-data/${formData?.pr_type?.id}`)
    .then((res)=>{
      if(res.data.status===1){
        setPrDataPlans(res.data.prDetails)
      }
    })
    .catch((err)=>{
        console.log(err)
    })
  }
  }

  useEffect(()=>{
    fetchDataByprID()
  },[formData?.pr_type])

  useEffect(() => {
    fetchPRCountries() 
    const storedCountryPrefer = JSON.parse(
      localStorage.getItem("country_preference")
    );
    const storedUserData = JSON.parse(localStorage.getItem("userVisa"));
    setCountryPrefer(storedCountryPrefer);
    if (storedUserData) {
      setUserData(storedUserData);
      setFormData({
        first_name: storedUserData.first_name,
        last_name: storedUserData.last_name,
        phone_number: storedUserData.phoneNumber,
        dob: "",
        nationality: "",
        address: "",
        postal_code: "",
        country: "",
        email: storedUserData.email,
        reason_for_migration: "",
        destination_country: "",
        pr_type:null,
        selected_pr_plan: null,
        passport: "",
        resume:'',
        educational_certificates:'',
        references:"",
        occupation: "",
    current_employer:'',
    years_of_experience:"",
    level_of_education:'',
    field_of_study:'',
    name_of_institution:'',
    year_of_graduation:'',
    relatives:'',
    comments:'',
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked, id } = e.target;
    if (name === "pr_type") {
      const selectedOption = prData.find((choice) => choice.id === Number(value));
      setFormData((prevState) => ({
        ...prevState,
        [name]: selectedOption,
        selected_pr_plan:""
      }));
    } else if (name === "selected_pr_plan") {
      const selectedOption = prDataPlans?.find(
        (choice) => choice.id === Number(value)
      );
      setFormData((prevState) => ({
        ...prevState,
        [name]: selectedOption,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleChangeCountry = (e) => {
    const { name, value } = e.target;

    if (name === "destination_country") {
      const selectedOption = prCountry?.find((choice) => choice.id == value);
      setFormData((prevState) => ({
        ...prevState,
        [name]: selectedOption,
        pr_type: "",
        selected_pr_plan:""
      }));
    }
  };


  const loadFile = useCallback((event) => {
    const file = event.target.files[0];
    const { name } = event.target;
    if (file) {
      const Filename = `${new Date().getTime()}_${file.name}`;
      const StorageRef = ref(storage, Filename);
      const uploadTask = uploadBytesResumable(StorageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error(`Upload failed: ${error.message}`);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFormData((prevFormData) => ({
              ...prevFormData,
              [name]: downloadURL,
            }));
          });
        }
      );
    }
  }, [setFormData]);
  


///form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    axios.post(`${REST_API_END_POINT}add/pr-visa-application-form`,{
        values:formData,user_id:userData?.user_id,consultId
    })
    .then((res)=>{
      if(res.data.status==1){
        toast.success(res.data.message);
         handleGoBack()
        setFormData({})
      }else{
        toast.error(res.data.message);
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  };
  return (
    <>
      <section
        className="relative table w-full py-36 bg-no-repeat"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${PRVisaImage})`,
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container"></div>
      </section>

      <section className="relative md:py-24 py-16">
        <div
          className="container"
          style={{ marginBottom: "-10px", marginTop: "-180px" }}
        >
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-12 md:col-span-12 mt-8 md:mt-0 order-2 md:order-1">
              <div className="lg:me-5">
                <div
                  className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6"
                  style={{
                    padding: "20px",
                    borderRadius: "18px",
                    objectFit: "cover",
                  }}
                >
                  <form
                    method="post"
                    name="myForm"
                    id="myForm"
                     onSubmit={handleFormSubmit}
                    style={{
                      padding: "20px",
                      width: "100%",
                      backdropFilter: "blur(1000px)",
                      WebkitBackdropFilter: "blur(1000px)",
                      backgroundColor: "#ffffff1a",
                      boxShadow: "0 0 80px #0000004d",
                      borderRadius: "10px",
                    }}
                  >
                    <p className="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div className="color-price">
                      <div className="color-option">
                        <h3 className="color mb-6 text-3xl leading-snug font-medium">
                          Fill the Form to Apply For PR Visa
                        </h3>
                      </div>
                    </div>
                    <div className="color-price" style={{ textAlign: "left" }}>
                      <div className="color-option">
                        <h6 className="color mb-6  leading-snug font-medium">
                          Personal Information
                        </h6>
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start " style={{ color: "black" }}>
                          <label htmlFor="first_name" className="font-semibold">
                            First Name: <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="first_name"
                            name="first_name"
                            type="text"
                            required
                            className="form-input w-full text-[15px] 
                                                    py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                                    rounded-full outline-none border border-gray-200 
                                                    focus:border-gray-200 dark:border-gray-200 
                                                    dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter your first name :"
                            value={formData.first_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label htmlFor="last_name" className="font-semibold">
                            Last Name:{" "}
                          </label>
                          <input
                            id="last_name"
                            name="last_name"
                            type="text"
                            className="form-input w-full text-[15px] py-2 px-3
                             h-10 bg-transparent dark:text-slate-600 
                             rounded-full outline-none border border-gray-200 
                             focus:border-gray-200 dark:border-gray-200 
                             dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter your last name :"
                            value={formData.last_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label
                            htmlFor="phone_number"
                            className="font-semibold"
                          >
                            Phone Number:{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="phone_number"
                            name="phone_number"
                            type="number"
                            pattern="\d{12}"
                            maxLength="12"
                            required
                            onInput={(e) => {
                              e.target.value = e.target.value.slice(0, 12);
                            }}
                            className="form-input w-full text-[15px] py-2 px-3
                             h-10 bg-transparent dark:text-slate-600 
                             rounded-full outline-none border
                              border-gray-200 focus:border-gray-200 
                              dark:border-gray-200 
                              dark:focus:border-gray-200
                               focus:ring-0 mt-2"
                            placeholder="Enter your Phone Number :"
                            value={formData.phone_number}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label htmlFor="email" className="font-semibold">
                            Email Address:{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="email"
                            name="email"
                            type="email"
                            required
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                            rounded-full outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter your email address :"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label htmlFor="dob" className="font-semibold">
                            Date Of Birth:{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                   id="dob"
                   name="dob"
                   type="date"
                  required
                  max={moment().subtract(18, "years").format("YYYY-MM-DD")}    
                  className="mt-2 p-2 border border-gray-300 rounded-md"
                  onChange={handleChange}
                     />
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label
                            htmlFor="nationality"
                            className="font-semibold"
                          >
                            Nationality: <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="nationality"
                            name="nationality"
                            type="text"
                            required
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                            rounded-full outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter your Nationality :"
                            value={formData.nationality}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div style={{ color: "black" }} className="text-start">
                          <label htmlFor="address" className="font-semibold">
                            Address: <span className="text-red-600">*</span>
                          </label>
                          <textarea
                            id="address"
                            name="address"
                            required
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-28 bg-transparent dark:text-slate-600 
                            rounded-2xl outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter  Address :"
                            value={formData.address}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="lg:col-span-4 mb-5">
                        <div style={{ color: "black" }} className="text-start">
                          <label
                            htmlFor="postal_code"
                            className="font-semibold"
                          >
                            Postal Code: <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="postal_code"
                            name="postal_code"
                            type="number"
                            required
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                            rounded-full outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter your Postal Code :"
                            value={formData.postal_code}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div style={{ color: "black" }} className="text-start">
                          <label htmlFor="country" className="font-semibold">
                            Country: <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="country"
                            name="country"
                            type="text"
                            required
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                            rounded-full outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter your Country :"
                            value={formData.country}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* this is for the migration details */}
                    <div className="color-price" style={{ textAlign: "left" }}>
                      <div className="color-option">
                        <h6 className="color mb-6  leading-snug font-medium">
                          Migration Details
                        </h6>
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start " style={{ color: "black" }}>
                          <label
                            htmlFor="reason_for_migration"
                            className="font-semibold"
                          >
                            Reason for Migration:{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <textarea
                            id="reason_for_migration"
                            name="reason_for_migration"
                            required
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-28 bg-transparent dark:text-slate-600 
                            rounded-2xl outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter the reason  for migration :"
                            value={formData.reason_for_migration}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label
                            htmlFor="destination_country"
                            className="font-semibold"
                          >
                            Destination Country :{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <select
                            name="destination_country"
                            required
                            className="form-input w-full text-[15px] py-2 px-3
                           h-10 bg-transparent dark:text-slate-600 rounded-full 
                           outline-none border border-gray-200 
                           focus:border-gray-200 dark:border-gray-200
                            dark:focus:border-gray-200 
                            focus:ring-0 mt-2"
                            value={formData.destination_country?.id || ""}
                            onChange={handleChangeCountry}
                          >
                            <option value="" disabled>
                              Select a country
                            </option>
                            {prCountry?.map((d, index) => (
                              <option
                                key={index}
                                className="form-input w-full text-[15px] 
                             py-2 px-3 h-10 bg-transparent dark:bg-slate-900 
                             dark:text-slate-600 rounded-full outline-none 
                             border border-gray-200 focus:border-gray-200 
                             dark:border-gray-800 
                             dark:focus:border-gray-200 
                             focus:ring-0 mt-2"
                             value={d.id}
                              >
                                {d.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label htmlFor="pr_type" className="font-semibold">
                            Get PR Type  :{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <select
                            name="pr_type"
                            required
                            className="form-input w-full text-[15px] py-2 px-3
                           h-10 bg-transparent dark:text-slate-600 rounded-full 
                           outline-none border border-gray-200 
                           focus:border-gray-200 dark:border-gray-200
                            dark:focus:border-gray-200 
                            focus:ring-0 mt-2"
                            value={formData.pr_type?.id||''}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Select a PR Visa Type {" "}
                            </option>
                            {prData?.map((d, index) => (
                              <option
                                key={index}
                                value={d.id}
                                className="form-input w-full text-[15px] 
                             py-2 px-3 h-10 bg-transparent dark:bg-slate-900 
                             dark:text-slate-600 rounded-full outline-none 
                             border border-gray-200 focus:border-gray-200 
                             dark:border-gray-800 
                             dark:focus:border-gray-200 
                             focus:ring-0 mt-2"
                              >
                                {d.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div className="selected_pr_plan" style={{ color: "black" }}>
                          <label htmlFor="pr_type" className="font-semibold">
                            Choose a Preffered PR Plan  :{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <select
                            name="selected_pr_plan"
                            required
                            className="form-input w-full text-[15px] py-2 px-3
                           h-10 bg-transparent dark:text-slate-600 rounded-full 
                           outline-none border border-gray-200 
                           focus:border-gray-200 dark:border-gray-200
                            dark:focus:border-gray-200 
                            focus:ring-0 mt-2"
                            value={formData.selected_pr_plan?.name||""}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Select a PR Plan {" "}
                            </option>
                            {prDataPlans?.map((d, index) => (
                              <option
                                key={index}
                                className="form-input w-full text-[15px] 
                             py-2 px-3 h-10 bg-transparent dark:bg-slate-900 
                             dark:text-slate-600 rounded-full outline-none 
                             border border-gray-200 focus:border-gray-200 
                             dark:border-gray-800 
                             dark:focus:border-gray-200 
                             focus:ring-0 mt-2"
                             value={d.id}
                              >
                                {d.name} ({d.sub_class})
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* this is for the documents details */}
                    <div className="color-price" style={{ textAlign: "left" }}>
                    <div className="color-option">
                    <h6 className="color mb-6 leading-snug font-medium">Document Uploads</h6>
                    </div>
                     </div>
                       <div className="grid lg:grid-cols-3 gap-5 mb-5">
                       {['passport', 'resume', 'educational_certificates', 'references'].map((field, index) => (
          <div className="mb-5" key={index}>
          <div style={{ color: "black" }} className="text-start">
            <label htmlFor={field} className="font-semibold">
              {field.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}: 
              <span className="text-red-600">*</span>
            </label>
            <input
              id={field}
              name={field}
              type="file"
              accept="application/pdf"
              className="hidden"
              onChange={handleFileChange}
            />
            <div>
              <div className="relative h-50 w-50 shadow-md dark:shadow-gray-800 overflow-hidden flex items-center justify-center">
                {pdfPreviews[field] ? (
                  <iframe
                    src={pdfPreviews[field]}
                    type="application/pdf"
                    style={{ height: '100%', width: '100%', overflow: 'auto', zIndex: 999 }}
                    title={`PDF Preview for ${field}`}
                  />
                ) : (
                  <p className="text-slate-400 mt-3 w-28 text-center" style={{ fontSize: "12px" }}>
                    Please upload a PDF file.
                  </p>
                )}
                <div className="absolute inset-0 group-hover:bg-slate-900/40 transition duration-500"></div>
                <label className="absolute inset-0 cursor-pointer" htmlFor={field}></label>
              </div>
            </div>
          </div>
        </div>
                         ))}

      <div className="mb-5">
        <div style={{ color: "black" }} className="text-start">
          <label htmlFor="date_of_migration" className="font-semibold">
            Expected Date of Migration: <span className="text-red-600">*</span>
          </label>
          <input
            id="date_of_migration"
            name="date_of_migration"
            type="date"
            required
            min={new Date().toISOString().split("T")[0]}
            className="mt-2 p-2 border border-gray-300 rounded-md"
            onChange={handleChange}
          />
        </div>
      </div>
                        </div>

                     {/* this is for the Employment details */}
                    <div className="color-price" style={{ textAlign: "left" }}>
                      <div className="color-option">
                        <h6 className="color mb-6  leading-snug font-medium">
                         Employment Details
                        </h6>
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start " style={{ color: "black" }}>
                          <label htmlFor="occupation" className="font-semibold">
                          Current Occupation: 
                          </label>
                          <input
                            id="occupation"
                            name="occupation"
                            type="text"
                            required
                            className="form-input w-full text-[15px] 
                                                    py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                                    rounded-full outline-none border border-gray-200 
                                                    focus:border-gray-200 dark:border-gray-200 
                                                    dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter your Occupation :"
                            value={formData.occupation}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label htmlFor="current_employer" className="font-semibold">
                          Current Employer
                          </label>
                          <input
                            id="current_employer"
                            name="current_employer"
                            type="text"
                            className="form-input w-full text-[15px] py-2 px-3
                             h-10 bg-transparent dark:text-slate-600 
                             rounded-full outline-none border border-gray-200 
                             focus:border-gray-200 dark:border-gray-200 
                             dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Enter your Current Employer :"
                            value={formData.current_employer}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                 
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label
                            htmlFor="years_of_experience"
                            className="font-semibold"
                          >
                            Years of Work Experience:
                          </label>
                          <input
                            id="years_of_experience"
                            name="years_of_experience"
                            type="number"
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                            rounded-full outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Years of Work Experience:"
                            value={formData.years_of_experience}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                     {/* this is for the Educational details */}
                     <div className="color-price" style={{ textAlign: "left" }}>
                      <div className="color-option">
                        <h6 className="color mb-6  leading-snug font-medium">
                        Education Details
                        </h6>
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start " style={{ color: "black" }}>
                          <label htmlFor="level_of_education" className="font-semibold">
                          Highest Level of Education: 
                          </label>
                          <input
                            id="level_of_education"
                            name="level_of_education"
                            type="text"
                            required
                            className="form-input w-full text-[15px] 
                                                    py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                                    rounded-full outline-none border border-gray-200 
                                                    focus:border-gray-200 dark:border-gray-200 
                                                    dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder=" Highest Level of Education :"
                            value={formData.level_of_education}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label htmlFor="field_of_study" className="font-semibold">
                          Field of Study
                          </label>
                          <input
                            id="field_of_study"
                            name="field_of_study"
                            type="text"
                            className="form-input w-full text-[15px] py-2 px-3
                             h-10 bg-transparent dark:text-slate-600 
                             rounded-full outline-none border border-gray-200 
                             focus:border-gray-200 dark:border-gray-200 
                             dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Field of Study"
                            value={formData.field_of_study}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                 
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label
                            htmlFor="name_of_institution"
                            className="font-semibold"
                          >
                            Name of Institution:
                          </label>
                          <input
                            id="name_of_institution"
                            name="name_of_institution"
                            type="text"
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                            rounded-full outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Name of Institution"
                            value={formData.name_of_institution}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label
                            htmlFor="year_of_graduation"
                            className="font-semibold"
                          >
                           Year of Graduation:
                          </label>
                          <input
                            id="year_of_graduation"
                            name="year_of_graduation"
                            type="number"
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                            rounded-full outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Year of Graduation"
                            value={formData.year_of_graduation}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* this is for the Additional Information details */}
                    <div className="color-price" style={{ textAlign: "left" }}>
                      <div className="color-option">
                        <h6 className="color mb-6  leading-snug font-medium">
                        Additional Information
                        </h6>
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start " style={{ color: "black" }}>
                          <label htmlFor="relatives" className="font-semibold">
                          Any Relatives/Friends in Desired Country: 
                          </label>
                          <input
                            id="relatives"
                            name="relatives"
                            type="text"                        
                            className="form-input w-full text-[15px] 
                                                    py-2 px-3 h-10 bg-transparent dark:text-slate-600 
                                                    rounded-full outline-none border border-gray-200 
                                                    focus:border-gray-200 dark:border-gray-200 
                                                    dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder=" Any Relatives/Friends :"
                            value={formData.relatives}
                            onChange={handleChange}
                          />
                        </div>
                      </div>                              
                      <div className="lg:col-span-4 mb-5">
                        <div className="text-start" style={{ color: "black" }}>
                          <label
                            htmlFor="comments"
                            className="font-semibold"
                          >
                           Additional Comments or Questions:
                          </label>
                          <textarea
                            id="comments"
                            name="comments"
                            required
                            className="form-input w-full text-[15px] 
                            py-2 px-3 h-28 bg-transparent dark:text-slate-600 
                            rounded-2xl outline-none border border-gray-200 
                            focus:border-gray-200 dark:border-gray-200 
                            dark:focus:border-gray-200 focus:ring-0 mt-2"
                            placeholder="Ask any Questions :"
                            value={formData.comments}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <button
                      className="font-semibold transition-all duration-500 ease-in-out citizenship-button"
                      type="submit"
                      id="submit"
                      name="send"
                      //  onClick={handleContinueClick}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <Modal
          isOpen={isModalOpen}
          onRequestClose={toggleModal}
          contentLabel="Popup"
          className="modal"
          overlayClassName="overlay"
        >
          <div
            className="popup-content"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "120px",
            }}
          >
            <p>Your Form Has Been Registered Succesfully</p>
            <br />
            <button
              className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 
              hover:border-violet-700 text-white rounded-full"
              onClick={toggleModal}
            >
              OK
            </button>
          </div>
        </Modal>
      </section>
    </>
  );
}
