import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Select from 'react-select'
import Switcher from '../../components/switcher';
import { Icon } from '@iconify/react';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import { Link } from 'react-router-dom';
import { data } from '../../data/data';
import {LuClock,LuCalendarDays,RiDragDropLine, LuSearch,AiOutlineAppstore, AiOutlineStar,MdKeyboardArrowLeft, MdKeyboardArrowRight,PiBrowsersLight, MdOutlineSportsVolleyball, AiOutlinePieChart, LuMusic4, TbCameraPlus, PiRocketLaunchBold,FaImages,ImImages, BiImage, BiImages, RiArrowDropDownLine, MdOutlineArrowForward} from "../../assets/icons/vander"
import axios from 'axios';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import Error from '../special/error';
import { DNA } from 'react-loader-spinner';



export default function CheckMyVisa({ activeIndex ,countryPrefer,countrySelected,refresh}) {
    const [countries,setCountries] = useState([])
    const [loading, setLoading] = useState(true);

const fetCountriesForCheckVisa=()=>{
  axios.post(`${REST_API_END_POINT}country-listing`,{from_user:true})
  .then((res)=>{
    if(res.data.status===1){
        let filteredCountry = res.data?.data?.filter(d=>d.id !==countryPrefer.id && d.status === 1)
        setCountries(filteredCountry)
    }else{
        setCountries([])
    }
  })
}
  useEffect(()=>{
  setCountries([countrySelected])
  },[countrySelected])
  
useEffect(()=>{
    const timer = setTimeout(() => {
        setLoading(false);
      }, 5000);
    fetCountriesForCheckVisa()
    return () => clearTimeout(timer);
},[countryPrefer,refresh])



    return (
        <>
              {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{}}
         wrapperClass="dna-wrapper"
           />
        </div>
      ) : (
            <section className="relative mb-6" style={{marginTop:'-70px'}}>
            {countries?.length > 0 ?
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]" style={{padding:'20px',}}>
                        {
                            countries?.map((ele, index) => (
                                <Link to={(ele.verified === 1 && ele.visa_detail_status===1 ) && `/check-my-visa-details/${ele.id}/${activeIndex}`} 
                                style={{display:'flex',alignItems:'stretch',justifyContent:'stretch',alignContent:'stretch'}}>
                                <div key={index} className="group relative overflow-hidden shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-100 " style={{borderRadius:'20px',}}>
                                    <img 
                                    style={{ width: "600px",
                                    height: "300px",
                                    objectFit: "cover",}}
                                      src={ele.image} alt={ele.name} />

                                    <div className="relative p-6">
                                       
                                        <div className="">
                                        <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start' }}>
                                        <div className="color-option" >
                                                    <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Link 
                                                            to={(ele.verified === 1 && ele.visa_detail_status===1 ) && `/check-my-visa-details/${ele.id}/${activeIndex}`} 
                                                             className="color" data-ripple-light="true"
                                                             data-tooltip-target="tooltip" style={{ fontWeight: 'bold', fontSize: '21px', color: '#333333', maxWidth: 270, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '10px', marginBottom: '6px' }} >{ele.name}</Link>
                                                                  <div
                                                                data-tooltip="tooltip"
                                                             class="tooltip"
                                                               >
                                                         {ele.name} 
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>


                                            <div className="color-price" style={{marginTop:'20px',marginLeft:'-20px'}}>
                                                    <div className="flex items-center">
                                                        <Link
                                                            to={(ele.verified === 1 && ele.visa_detail_status===1 ) && `/check-my-visa-details/${ele.id}/${activeIndex}`}
                                                            style={{
                                                                color: '#000000',
                                                                fontWeight: 'bold',
                                                                fontSize: '16px',
                                                                cursor: 'pointer',
                                                                marginLeft: '15px',
                                                                paddingBottom: '3px', 
                                                                whiteSpace: 'nowrap', 
                                                                display: 'flex', // Added display flex
                                                                alignItems: 'center', // Added align items center
                                                            }}
                                                            className='under'>
                                                                Check Visa Status
                                                            <MdOutlineArrowForward color='#000' style={{ marginLeft: '5px' }} /> 
                                                        </Link>
                                                    </div>
                                                </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            ))
                        }
                    </div>
                    : <Error />}
            </section>   
         )}         
        </>
    )
}
