import React, { useEffect, useState } from 'react'
import image from '../../assets/images/items/1.gif';
import image1 from '../../assets/images/items/1.jpg';
import image2 from '../../assets/images/items/2.jpg';
import image3 from '../../assets/images/items/3.jpg';
import image4 from '../../assets/images/items/2.gif';
import image5 from '../../assets/images/items/4.jpg';
import image6 from '../../assets/images/items/3.gif';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Select from 'react-select';
import Switcher from '../../components/switcher';
import tick from '../../assets/images/check.png';
import star from '../../assets/images/rating-star.png';
import check from '../../assets/images/tick.png';
import travel from '../../assets/images/travel-insurance.png';
import { Icon } from '@iconify/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faImage, faPassport, faHotel } from '@fortawesome/free-solid-svg-icons';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import airplaneTakeoffFill from '@iconify/icons-ph/airplane-takeoff-fill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LuSearch, BiCheckCircle, CgArrowDown, FiTarget, BsTag, BiPlusCircle, BiDoorOpen, RiArrowDropDownLine, FiHeart, PiBrowsersLight, FiUsers, VscListFlat, PiMusicNotesBold, LuCamera, FaRegPaperPlane, BiWallet, BiMinusCircle, BiShield, BiTargetLock, FaSearchLocation, FaSearchDollar, LuUsers2, LuFolderCheck, MdOutlineChatBubbleOutline, TiPhoneOutline, IoMdClose, GrVisa, AiTwotoneInsurance, RiExchangeBoxFill, RiExchangeDollarLine, MdWorkOff, GoLocation, CiMedicalClipboard, GiAges, GiCash, GiFamilyHouse, GiHealthIncrease, MdWork, MdOutlineArrowBack, CgClose, BsExclamationOctagon } from "../../assets/icons/vander"
import { Users, Calendar, Clock, DollarSign, Folder, Search } from 'feather-icons-react/build/IconComponents';
import NavbarNew from '../../components/navbar-new';
import File from 'feather-icons-react/build/IconComponents/File';
import { dataArray } from '../../data/data';
import HealthListing from '../index/health-listing';
import Comingsoon from '../special/comingsoon';
import { PRPathwayDetails } from '../../data/data';
import NavbarPR from '../../components/navbar-pr';
import axios from 'axios';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import { empty, parseDescription } from '../../common/Helper';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import req from '../../images/how to/image.jpg'
import apply from '../../images/how to/image (1).jpg'
import globe from '../../images/how to/image (3).jpg'
import sts from '../../images/how to/image (2).jpg'
import ReactPaginate from 'react-paginate';
const newImg = [
  { image_url: globe },
  { image_url: req },
  { image_url: apply },
  { image_url: sts },
];


const customStyles = {
  control: (styles, { isFocused, isHovered }) => ({
    ...styles,
    backgroundColor: 'transparent',
    marginTop: "-7px",
    color: 'white !important',
    placeholder: {
      color: 'white',
    },
    borderColor: isHovered ? 'white' : 'transparent',
    boxShadow: isHovered ? '0 0 0 2px white' : 'none',
    borderWidth: 0,
    outline: 'none',
    height: '30px',
  }),

  menu: provided => ({
    ...provided,
    marginLeft: '-35px',
    zIndex: 999,
  }),

  option: (styles, { isFocused }) => ({
    ...styles,

    backgroundColor: 'white',
    color: 'black',
    zIndex: isFocused ? 999 : 999,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'white',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: 'white',
    '.css-tj5bde-Svg': {
      color: 'white',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: 'white',
  }),
};


const VisaType = [
  { label: 'Skills', type: 1 },
  { label: 'Family', type: 2 },
  { label: ' Business/Investor', type: 3 },
  { label: 'Other etc', type: 4 },
]

// const getData = (source, ids) => {
//   if (!ids) return [];
//   return ids.map(id => source?.find(item => item?.id == id)).filter(item => item !== undefined);
// };

export default function PRPathwayDSkilledWork() {
  const { PRPathwayId, tab } = useParams();
  const navigate = useNavigate()
  const [data,setData] = useState({})
  const [datapr,setDatapr] = useState({})
  const [country,setCountry] = useState({})
  const [userData,setUserData] = useState({})
  const [prFormData,setprFormData] = useState({})
  const [dataVar,setDataVar] = useState([])
  const [dataVarSpon,setDataVarSpon] = useState([])
  const [visaVar,setVisaVar] =  useState([])
  const [prDocs,setPrDocs] = useState([])
  const [prDetails,setPrDetails] = useState([])
  const [documents,setDocuments] = useState([])
  const [occupation,setOccupations] = useState([])
  const [selectOcc,setSelectOcc] = useState({})
  const [docRequirement, setDocRequirment] = useState({})
  const [filteredOccupation,setFilteredOccupations] = useState([])
  const [selectedLink, setSelectedLink] = useState(prDetails[0]);
  const [inputValue, setInputValue] = useState('')
  const [isForApplicants, setIsForApplicants] = useState(false) 
  const [isSponcer, setIsSponcer] = useState(prFormData?.sponser === 1) 
  const [notification, setNotification] = useState({})
  const [currentDate, setCurrentDate] = useState("");
  const [docName, setDocName] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
  const [docReqData, setDocReqData] = useState([])
  const [faqs, setFaq] = useState([])
  const [visaForms,setVisaForms] = useState([])
  const [pdfDocuments,setPdfDocuments] = useState({})
  const [review,setReview] = useState(0)
  const [reviewCount,setReviewCount] = useState(0)
//   const VISA_TYPE = VisaType.find(item => item.type === data?.visa_type);
  const VISA_TYPE = VisaType.find(item => item.type === data?.visa_type)?.label || 'Unknown';

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; 
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0); // Reset to first page on search
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(()=>{
    fetchAllData()
  },[])
  const fetchAllData=()=>{
    try {
      axios.post(`${REST_API_END_POINT}pr_pathway/get-all-data`,{id:PRPathwayId})
      .then((res)=>{
        if(res.data.status===1){
          setDatapr(res.data.data)        
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
  const filteredDatas =[]
  // Filter the course data based on the search term
//   const filteredDatas = data?.filter(item =>
//     item?.course_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     item?.course_intake.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     item?.fee.toString().includes(searchTerm)
//   );

  // Calculate the paginated data
  const pageCount = Math.ceil(filteredDatas.length / itemsPerPage);
  const paginatedData = filteredDatas.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);



  const getOccupationForms = (data) => {
    axios.post(`${REST_API_END_POINT}pr-detail/occup-id`, { id: data.id, prID: PRPathwayId })
      .then((res) => {
        if (res.data.status === 1) {
          if (isForApplicants) {
            let result = res.data.prDetails?.filter(d => d.sponser === 1)
            setPrDetails(result);
            setSelectedLink(result[0])
            fetchPrDetailsbyId(result[0].id)
          } else {
            setPrDetails(res.data.prDetails);
            fetchPrDetailsbyId(res.data.prDetails[0].id)
            setSelectedLink(res.data.prDetails[0])
          }

          setFilteredOccupations([]);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSearchOption = (data) => {
    setInputValue(data.name)
    setSelectOcc(data)
    getOccupationForms(data)

  };

  const handleCloseSearch = () => {
    setInputValue('')
    setSelectOcc({})
    setFilteredOccupations([])
    // fetchDataByprID();
  }

 



  useEffect(() => {
    if (isForApplicants) {

      let filterData = prDetails?.filter(d => d.sponser === 1)
      if (filterData.length > 0 && filterData) {
        setPrDetails(filterData)
        fetchPrDetailsbyId(filterData[0]?.id)
        setSelectedLink(filterData[0])
      } else {
        setPrDetails([])
        setSelectedLink({})
        // setIsForApplicants(!isForApplicants);
        if (!empty(selectOcc)) {
          console.log(selectOcc)
          getOccupationForms(selectOcc)
        }
        //  else {
        //   fetchDataByprID()
        // }
      }
    } else {
      // fetchDataByprID()
      if (!empty(selectOcc)) {
        console.log(selectOcc)
        getOccupationForms(selectOcc)
      } else {
        fetchDataByprID()
      }
    }
  }, [isForApplicants])

  useEffect(() => {
    setIsSponcer(prFormData?.sponser === 1);
  }, [prFormData?.sponser]);

  // const handleToggle = () => {
  //   // const updatedIsSponcer = !isSponcer;
  //   setIsSponcer(!isSponcer);
  //   // localStorage.setItem('isSponcer', JSON.stringify(updatedIsSponcer));
  // };

  const handleToggle = () => {
    setIsSponcer(prev => {
      const newState = !prev;
      // You can optionally save the new state to localStorage here
      // localStorage.setItem('isSponcer', JSON.stringify(newState));
      return newState;
    });
  };


  // useEffect(() => {
  //   const savedIsSponcer = localStorage.getItem('isSponcer');
  //   if (savedIsSponcer !== null) {
  //     setIsSponcer(JSON.parse(savedIsSponcer));
  //   }
  // }, []);


    


  // if(prFormData?.sponser==0){
  //   setIsSponcer(true)
  // }
  // console.log("Personalize this page::",isForApplicants)
  // if(isForApplicants){
  //   setIsSponcer(true)
  // }else{
  //   setIsSponcer(false)
  // }

  const fetchPrDetailsbyId = (id) => {
    axios.get(`${REST_API_END_POINT}pr-pathway/get-pr-form-details/${id}`)
      .then((res) => {
        if (res.data.status === 1) {
          setReview(res.data.review)
          setReviewCount(res.data.reviewers_count)
          setprFormData(res.data.prForm_Data)
          setDataVar(res.data.data_variable)
          setDataVarSpon(res.data.data_variable_sponsor)
          setPrDocs(res.data.pr_docs)
          setVisaVar(res.data.visa_variable)
          setDocuments(res.data.doc)
          setOccupations(res.data.occupation)
          setDocRequirment(res.data.documentsRequired)
          setPdfDocuments(res.data.pdfDocument)
          setVisaForms(res.data.visa_forms)
          setFaq(res.data.faq)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const fetchDataByprID = () => {
    axios.get(`${REST_API_END_POINT}pr-pathway/get-pr-data/${PRPathwayId}`)
      .then((res) => {
        if (res.data.status === 1) {
          setCountry(res.data.countryData)
          setData(res.data.data)
          setNotification(res.data.data.notification_btn)
          fetchPrDetailsbyId(res.data.prDetails[0]?.id)
          setSelectedLink(res.data.prDetails[0])
          setPrDetails(res.data.prDetails)
        }
      })
  }

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  }

  // const formatDescription = (description) => {
  //   return description.replace(/<p>/g, '<p><span style="font-weight: bold; font-size: larger;">•</span> ');
  // };
  
  // const formatDescription = (description) => {
  //   description = description?.replace(/<p><br><\/p>/g, '<br>');
  //   description = description?.replace(/<p>/g, '<p>• ');
  //   description = description?.replace(/<ul><li>/g, '');
  //   description = description?.replace(/<li>/g, '<p>• ');
  //   description = description?.replace(/<\/li>/g, '</p>');
  //   description = description?.replace(/<\/ul>/g, '');
  
  //   if (description && !description.startsWith('<p>• ')) {
  //       description = '<p>• ' + description;
  //   }
  
  //   return description;
  // };
  const formatDescription = (description) => {
    if (!description) return '';
  
    // Handle ordered lists
    let listIndex = 1;
    if (description.includes('<ol>')) {
      description = description.replace(/<ol>/g, '');
      description = description.replace(/<\/ol>/g, '');
      // description = description.replace(/<li>/g, () => {
      //   const item = `${listIndex++}. `;
      //   return `<p>${item}$1</p>`;
      // });
      description = description.replace(/<li>/g, () => {
        const item = `${listIndex++}. `;
        return `<p>&nbsp;&nbsp;&nbsp;${item}`;
      });
    }
  
    // Handle unordered lists
    if (description.includes('<ul>')) {
      description = description.replace(/<ul>/g, '');
      description = description.replace(/<\/ul>/g, '');
      // description = description.replace(/<li>/g, '<p>&nbsp;&nbsp;&nbsp;• $1</p>');
      description = description.replace(/<li>/g, '<p>&nbsp;&nbsp;&nbsp;• ');
    }
    description = description.replace(/<\/li>/g, '');
    // Clean up any extra <p> tags that might be left
    description = description.replace(/<p><\/p>/g, '');
  
    return description;
  }

//   const initialFAQ = getData(faqs,prFormData?.faq_ids)

  const expireDate = moment.unix(data?.expiredate);
  const formattedExpireDate = expireDate.format('MMMM D, YYYY');


useEffect(()=>{
  fetchDataByprID()
},[PRPathwayId])

  let headingData = country.name + ' PR' + ' ' + data.name

const handleClickApply=()=>{
  if(empty(userData)){
    navigate('/signup-visa/visa')
  }else{
    axios.post(`${REST_API_END_POINT}user-apply-for-visa`,{
      user_id:userData.user_id,formId:prFormData.id,is_pr:true
    })
    .then((res)=>{
      if(res.data.status==1){
        toast.success(res.data.message)
      }else{
        toast.error(res.data.message)
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  }
}
const handleDownload=(data)=>{
  try {
   
    const link = document.createElement('a');
    link.href = data.url;
    link.setAttribute('download', data.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  } catch (error) {
    console.error('Error downloading file:', error);
  }
}
  return (
    <>
     <NavbarNew noHomeTab={true} tab={'pr-pathway'} />

      <section className="relative md:py-24 py-16" style={{ marginTop: '20px' }}>
        <div className="container">

          {(notification === 1) && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              
              <div className="flex items-center p-3 mt-3 rounded-lg" style={{ width: '80%', backgroundColor: 'grey', marginBottom: '10px', justifyContent: 'space-between', color: 'white' }}>


                <div className="ms-2 items-center" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', textAlign: 'left' }}>
                  <BsExclamationOctagon style={{ color: 'white', marginRight: '10px' }} className="text-3xl" />
                  <div>
                    <span className="block font-semibold">Notification</span>
                    <span className="block" >{data?.notifications}</span>
                  </div>
                </div>
                <div className="ms-2" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'right' }}>
                  <span className="block font-semibold">Expire Date</span>
                  <span className="block">{formattedExpireDate}</span>
                </div>

              </div>

            </div>
          )}

          <div className="md:flex">
            {!empty(data) && (
              <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" style={{ paddingInline: '1rem' }}>
                <div className="color-price" style={{ marginTop: '-20px', marginBottom: '30px' }}>
                  {/* <div className="flex items-center">
                            <Link  onClick={()=>navigate(`/pr-pathway/${country.name}`,{state:{back_to:true}})}     
                            to={`/pr-pathway/${country.name}`}                                           
                                style={{
                                    color: '#000000',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    marginLeft: '15px',
                                    paddingBottom: '3px', 
                                    whiteSpace: 'nowrap', 
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                className='under'>

                                <MdOutlineArrowBack color='#000' style={{ marginRight: '5px' }} /> 
                                Go back to PR's
                            </Link>
                        </div> */}
                </div>
                <div style={{ position: 'relative', width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                {  prFormData?.sponser==1&&
                  <div style={{ position: 'absolute', bottom: 75, right: 0, width: '285px', height: '70px', backgroundColor: 'black', backdropFilter: 'blur(100px)', borderTopLeftRadius: '10px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="side-heading" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'space-between', color: 'white', fontWeight: 'bold', }}>
                      {/* <h2 style={{ marginBottom: "5px", fontSize: '25px', textAlign: 'left' }}>Personalize this page..</h2> */}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "5px", fontSize: '16px' }}>Applicants</span>
                        <label className="switch" style={{ marginBottom: "0", marginLeft: "5px", marginRight: "8px",fontSize:'15px' }}>
                          <input type="checkbox" id="togBtn" value={isSponcer} onChange={handleToggle} />
                          <div className="slider round"></div>
                        </label>
                        <span style={{ fontSize: '16px' }}>Sponsors</span>
                      </div>
                    </div>
                  </div>
}
                  <img
                    style={{
                      width: '100%',
                      left: '0',
                      minWidth: '300px',
                      height: '310px',
                      borderRadius: '10px',
                      objectFit: 'cover',
                    }}
                    src={country.image}
                    alt={country.name}
                  />
                  <div className='visa-image' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{headingData}</span> <span
                        className="visa-image1"
                        style={{ paddingRight: "15px" }}
                      >
                        {review} <img
                         src={star}
                         height={27}
                         width={27}
                         alt="Image Description"
                         className="badge-image"
                         style={{ marginLeft: "0px", }}
                       />
                       <br />
                        <span
                          className="visa-image1"
                          style={{ display: "flex", justifyContent: "flex-end" ,fontSize:'14px'}}
                        >
                          Rating({reviewCount})
                        </span>
                      </span>

                    <div className="bagde-flag-wrap4">
                      <img
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          zIndex: '2',
                        }}
                        src={country.flag}
                        alt={country.name}
                      />
                    </div>
                  </div>
                </div>

              </div>
            )}
          </div>
        </div>
        

        {!empty(prDetails) && (
          <>
            <div className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl " style={{ zIndex: 1, marginTop: '20px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            
              <Link
                className='font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 '
                type="button"
                to={'/consultants'}
                style={{ color: 'black', width: '550px', height: '70px', display: 'flex', justifyContent: 'center', textAlign: 'center', padding: '22px', borderRadius: '40px' }}
              >
                <div className="container z-1" style={{ color: 'white',  }}
                to={'/consultants'}
                >
                    Find Migration Consultant
                </div>
               
              </Link>
            </div>

            <hr style={{ marginTop: '-36px', borderColor: 'black', borderWidth: '1px' }} />

            <div className="container" style={{marginTop:'60px'}}>


                <Link style={{ fontSize: '24px', color: '#000', marginBottom: '4px',textAlign:'left',fontWeight:'bold'}}
                
                >
             {VISA_TYPE}
                </Link>

                <div className="max-w-screen-xl mx-auto" style={{ marginTop: '50px',  backgroundColor:'#efefef',borderRadius:'10px',paddingTop:'10px',paddingBottom:'15px' }}>
          <div className="py-5">
            <div className="max-w-screen-xl  mx-auto">
              <div className="mb-4" style={{marginLeft:'12px',marginTop:'12px'}}>
                <input
                  type="text"
                  className="w-2/5 p-2 border border-gray-400 rounded text-black"
                  placeholder="Search courses..."
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              <div style={{ overflowX: 'auto', maxHeight: '400px' }}>
                <table className="w-full text-base" style={{ backgroundColor: '#f1f1f1', border: '1px solid #efefef', textAlign: 'left', marginBottom: '5px' }}>
                <thead style={{ position: 'sticky', top: '-2px', zIndex: 1,}}>
                    <tr className='text-center' style={{ border: '1px solid #dbdbdb', color: '#000', backgroundColor: '#dbdbdb', fontWeight: 'bold', paddingTop: '10px', paddingBottom: '10px' }}>
                      <th className="px-3" style={{whiteSpace:'nowrap'}}>Sl.No</th>
                      <th className="px-3" style={{whiteSpace:'nowrap'}}>Course Name</th>
                      <th className="px-3" style={{whiteSpace:'nowrap'}}>Intake</th>
                      <th className="px-3" style={{whiteSpace:'nowrap'}}>Course Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((item, index) => (
                      <tr className="text-black border-b border-black text-center" key={index}>
                        <td className="px-3" style={{whiteSpace:'nowrap'}}>
                          <Link>{index + 1 + currentPage * itemsPerPage}</Link>
                        </td>
                        <td className="px-3 " style={{whiteSpace:'nowrap'}}>
                          <button 
                          className='underlineeee'
                                                    // to={`/university-details/${item.course_id}/${activeIndex}`}

                          onClick={()=>{
                            item.course_verified ? navigate(`/university-details/${item.course_id}/${activeIndex}`):toast.warning('Comming Soon')
                          }}
                          >{item.course_name}</button>
                        </td>
                        <td className="px-3" style={{whiteSpace:'nowrap'}}>
                        <Link 
                        // onClick={()=>{
                        //     item.course_verified ? navigate(`/university-details/${item.course_id}/${activeIndex}`):toast.warning('Comming Soon')
                        //   }}
                          >{item.course_intake}</Link>
                        </td>
                        <td className="px-3" style={{whiteSpace:'nowrap'}}>
                        <Link 
                        // onClick={()=>{
                        //     item.course_verified ? navigate(`/university-details/${item.course_id}/${activeIndex}`):toast.warning('Comming Soon')
                        //   }}
                          >{item?.fee_symbol} {item.fee} </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                  <hr style={{height: '3px', backgroundColor:' #b3b3b3',width:'100%',marginTop:'-9px'}}/>
              </div>
              
              <ReactPaginate
                previousLabel={<div className='tabler--player-track-prev' />}
                nextLabel={<div className='tabler--player-track-next' />}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </div>
            </div>

          
          </>
        )}

      </section>

      <Footer />

    </>
  )
}
