import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom'
import LogoDark from "../assets/images/logo-dark.png";
import LogoLight from "../assets/images/logo-light.png"
import Creator from "../assets/images/creator.png"
import SimpleBarReact from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';
import { Airplay, Image ,Film, Wifi, Copy, Users, FileText, File, LogIn, Layers } from 'feather-icons-react'
import { BsSearch } from "react-icons/bs";
import HealthListing from "../pages/index/health-listing";
import NavbarNew from "./navbar-new";
import DemoTesting from "../pages/index/demo-testing";

export default function AdminNavbar(){
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isDarkMode, setIsDarkMode] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };
    
    return(
        <>
        <nav className={isSidebarOpen ? "sidebar" : "sidebar close"}>
        <header>
            <div className="image-text">
                <span className="image">
                    {/*<img src="logo.png" alt="">*/}
                </span>
                <div className="text logo-text">
                    <span className="name">Codinglab</span>
                    <span className="profession">Web developer</span>
                </div>
            </div>
            <i className='bx bx-chevron-right toggle' onClick={toggleSidebar}></i>
        </header>
        <div className="menu-bar">
            <div className="menu">
                <ul className="menu-links">
                    <li className="nav-link">
                        <a href="#">
                            <i className='bx bx-home-alt icon'></i>
                            <span className="text nav-text">Dashboard</span>
                        </a>
                    </li>
                    <li className="nav-link">
                        <a href="#">
                            <i className='bx bx-bar-chart-alt-2 icon'></i>
                            <span className="text nav-text">Revenue</span>
                        </a>
                    </li>
                    <li className="nav-link">
                        <a href="#">
                            <i className='bx bx-bell icon'></i>
                            <span className="text nav-text">Notifications</span>
                        </a>
                    </li>
                    <li className="nav-link">
                        <a href="#">
                            <i className='bx bx-pie-chart-alt icon'></i>
                            <span className="text nav-text">Analytics</span>
                        </a>
                    </li>
                    <li className="nav-link">
                        <a href="#">
                            <i className='bx bx-heart icon'></i>
                            <span className="text nav-text">Likes</span>
                        </a>
                    </li>
                    <li className="nav-link">
                        <a href="#">
                            <i className='bx bx-wallet icon'></i>
                            <span className="text nav-text">Wallets</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="bottom-content">
                <li className="">
                    <a href="#">
                        <i className='bx bx-log-out icon'></i>
                        <span className="text nav-text">Logout</span>
                    </a>
                </li>
                <li className="mode" onClick={toggleDarkMode}>
                    <div className="sun-moon">
                        <i className='bx bx-moon icon moon'></i>
                        <i className='bx bx-sun icon sun'></i>
                    </div>
                    <span className="mode-text text">{isDarkMode ? "Light mode" : "Dark mode"}</span>
                    <div className="toggle-switch">
                        <span className="switch"></span>
                    </div>
                </li>
            </div>
        </div>
    </nav>
    <section className="home">
        <>
        <DemoTesting/>
       
        </>
    </section>
</>
    )
}