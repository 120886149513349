import React, { useState } from 'react'

export default function QA() {
    const accordionData = [
        {
            title: 'Can my service animal or pet travel with me?',
            content: 'Certain service animals are allowed to travel in the cabin on Emirates flights, subject to a number of conditions. For more information on travelling with a service animal, please see our accessible travel information, or contact your local Visa Search office.'
        },
        {
            title: 'Can children travelling alone use online check-in?',
            content: 'No. Passengers under the age of 16 and travelling without an adult must proceed to the airport for normal check‑in at the Visa desk.'
        },
        {
            title: 'Can I order a dietary meal for my flight?',
            content: 'Yes, you can order a meal for a specific diet up to 48 hours before your flight through Manage your booking(opens in the same window). Just enter your booking reference and last name, and you’ll be able to choose your meal preference.'
        },
        {
            title: 'What if I have special medications that I must carry with me?',
            content: 'If you require special medication, you should always carry your prescription with you when you board any flight. Different countries have different laws governing the possession and importation of prescription drugs, and some countries have limited supplies should you require a refill of your prescription, so it is always better to bring an extra supply with you. For further information concerning any particular medications, consult with your physician and contact the local embassy of the country to which you are travelling.'
        },
    ]
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold" style={{color:'#000'}}>Frequently Asked</h3>

                {/* <p className="text-slate-400 max-w-xl mx-auto">We are a huge marketplace dedicated to connecting great artists of all Giglink with their fans and unique token collectors!</p> */}
            </div>

            <div className="flex justify-center mt-5">
                <div className="lg:w-3/3">
                    <div id="accordion-collapseone" data-accordion="collapse" className="mt-6">
                        {accordionData.map((item, index) => (
                            <div key={index} className="relative shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-500 rounded-md overflow-hidden mt-4">
                                <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                    <button onClick={() => toggleAccordion(index)} type="button" style={{color:'black'}}
                                        className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-8001 text-violet-6001' : ''}`}
                                        data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                        <span>{item.title}</span>
                                        <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </h2>
                                {activeIndex === index && (
                                    <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                        <div className="p-5">
                                            <p style={{color:'black'}}>{item.content}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
