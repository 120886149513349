import item1 from '../assets/images/items/1.jpg';
import item2 from '../assets/images/items/2.gif';
import item3 from '../assets/images/items/2.jpg';
import item4 from '../assets/images/items/3.jpg';
import item5 from '../assets/images/items/3.gif';
import item6 from '../assets/images/items/4.jpg';
import item7 from '../assets/images/items/5.jpg';
import item8 from '../assets/images/items/6.jpg';
import item9 from '../assets/images/items/8.jpg';
import item10 from '../assets/images/items/4.gif';
import item11 from '../assets/images/items/5.gif';
import item12 from '../assets/images/items/9.jpg';
import item13 from '../assets/images/items/1.gif';
import item14 from '../assets/images/items/20.jpg';
import item15 from '../assets/images/items/7.jpg';
import item16 from '../assets/images/items/10.jpg';
import item17 from '../assets/images/items/11.jpg';
import item18 from '../assets/images/items/12.jpg';
import item19 from '../assets/images/items/16.jpg';
import item22 from '../assets/images/items/14.jpg';
import item23 from '../assets/images/items/7.gif';
import item24 from '../assets/images/items/8.gif';
import item25 from '../assets/images/items/15.jpg';
import item26 from '../assets/images/items/17.jpg';
import item27 from '../assets/images/items/18.jpg';
import item28 from '../assets/images/items/19.jpg';

import image1 from '../assets/images/avatar/1.jpg';
import image2 from '../assets/images/avatar/2.jpg';
import image3 from '../assets/images/items/3.gif';
import image4 from '../assets/images/avatar/3.jpg';
import image5 from '../assets/images/avatar/4.jpg';
import image6 from '../assets/images/avatar/5.jpg';
import image7 from '../assets/images/avatar/6.jpg';
import image8 from '../assets/images/items/5.gif';
import image9 from '../assets/images/avatar/7.jpg';
import image10 from '../assets/images/items/5.gif';
import image11 from '../assets/images/avatar/8.jpg';
import image12 from '../assets/images/items/2.gif';

import blog1 from '../assets/images/blog/01.jpg';
import blog2 from '../assets/images/blog/02.jpg';
import blog3 from '../assets/images/blog/03.jpg';
import blog4 from '../assets/images/blog/04.jpg';
import blog5 from '../assets/images/blog/05.jpg';
import blog6 from '../assets/images/blog/06.jpg';
import blog7 from '../assets/images/blog/07.jpg';
import blog8 from '../assets/images/blog/08.jpg';
import blog9 from '../assets/images/blog/09.jpg';
import blog10 from '../assets/images/blog/10.jpg';
import blog11 from '../assets/images/blog/11.jpg';
import blog12 from '../assets/images/blog/12.jpg';

const img1 = 'https://www.shutterstock.com/image-photo/metro-railway-among-glass-skyscrapers-600nw-2185205475.jpg'
const img2 = 'https://i.natgeofe.com/k/11c64d9e-7baa-44af-8589-726fcfef3f7c/egypt-pyramids.jpg?w=1084.125&h=609'
const img3 = 'https://static.toiimg.com/photo/101545435.cms'
const img4 = 'https://img.veenaworld.com/wp-content/uploads/2022/04/julien-de-salaberry-viwdmfrbXfI-unsplash.jpg'
const img5 = 'https://etimg.etb2bimg.com/photo/87297706.cms'
const img6 = 'https://goglobalgeo.com/wp-content/uploads/2023/05/Morocco-Spotlight.png'
const img7 = 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Bahrain_World_trade_Center_.jpg/1200px-Bahrain_World_trade_Center_.jpg'
const img8 = 'https://images.travelandleisureasia.com/wp-content/uploads/sites/3/2023/02/02192048/great-wall-of-china.jpeg'
const img9 = 'https://cdn.mos.cms.futurecdn.net/nCxeLJ64Nd8PH88rAtg6aZ.jpg'
const img10 = 'https://media.assettype.com/outlooktraveller/import/outlooktraveller/public/uploads/articles/explore/shutterstock_745400287.jpg?w=1200&h=675&auto=format%2Ccompress&fit=max&enlarge=true'
const img11 = 'https://media.istockphoto.com/id/505221662/photo/elephants-in-river.jpg?s=612x612&w=0&k=20&c=pd0-eIKu1knUVrwVJRqfIK8t_aFqSk6vnxnuZLFGvh4='
const img12 = 'https://www.indonesia.travel/content/dam/indtravelrevamp/home-revamp/bali-jack.jpg'
const healthimg1 = 'https://www.easietech.com/img/all-aboard-optimizing-the-boarding-process-at-airports.jpg'
const healthimg2 = 'https://live.staticflickr.com/5476/14223552827_1e53ac9ea2_b.jpg'
const healthimg3 = 'https://images.jpost.com/image/upload/q_auto/c_fill,g_faces:center,h_537,w_822/458918'
const university1 = 'https://www.isbos.org/uploaded/themes/2015_map/images/Boston_Landmarks/MIT.jpg'
const university2 = 'https://scholarship-positions.com/wp-content/uploads/2015/02/HEC-Fully-Funded-PhD-Scholarships-for-University-of-Cambridge-in-UK.jpg'
const university3 = 'https://mcdonnell.wustl.edu/wp-content/uploads/2020/10/NUS.png'
const university4 = 'https://www.careerindia.com/img/2018/07/1-1530939791.jpg'
const consultant1 = 'https://www.flyhigh-abroad.in/wp-content/uploads/2023/12/Professional-Visa-Consultant.jpg'
const consultant2 = 'https://seoslog.com/wp-content/uploads/2022/08/Shook-hands-1.jpg'
const consultant3 = 'https://pr0.nicelocal.ae/8CSwEq8hgfh3zcN9bE5PVA/587x440,q85/4px-BW84_n0QJGVPszge3NRBsKw-2VcOifrJIjPYFYkOtaCZxxXQ2YI-i1gAXF1lQoAav8wiDCPfAEhKHMafaNqcW5vNNHjh0HAHNanQ4CkB1MTX-82v9g'
const consultant4 = 'https://guidanceplus.in/wp-content/uploads/2022/12/How-do-the-best-student-visa-consultants-in-Kochi.png'
const immigrate1 = 'https://www.konnecting.com/media/Konnecting/client/24052023-new-zealand-migration.jpg'
const immigrate2 = 'https://newcanadians.tv/wp-content/uploads/2022/11/Canada-immigration-level-plan-2023-2025.png'
const immigrate3 = 'https://woburn.co.nz/wp-content/uploads/2023/06/AEWV-2.png'
const immigrate4 = 'https://www.byevisa.com/wp-content/uploads/2021/07/AdobeStock_228252835.jpeg'
const language1 = 'https://www.berlitzrecruitingasia.com/cdn/shop/files/DSC06274_1500x.jpg?v=1644741685'
const language2 = 'https://imageio.forbes.com/specials-images/imageserve/9751529ab6b747709c5e6d07ba826a41/640x0.jpg?height=400&width=711&fit=bounds'
const language3 = 'https://media.licdn.com/dms/image/C4E12AQFGhMSulDu3WA/article-cover_image-shrink_720_1280/0/1598334920069?e=2147483647&v=beta&t=Djt6GCPYwxF24xfc19lgYf1WeRbUk7aduiikJGsLZ4Y'
const language4 = 'https://languagetraining.com/wp-content/uploads/2018/10/shutterstock_649567159-970x650.jpg'
const offers1 = 'https://www.breakthroughmarketingsecrets.com/wp-content/uploads/2016/11/special-offer.jpg'
const offers2 = 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*BaQu2v1HNCTjYsvr0KCNQQ.png'
const offers3 = 'https://www.isamigrations.com/wp-content/uploads/2022/05/17.-Australian-Partner-Visas-Some-Important-Tips-To-Know.psd_-1024x536.jpg'
const languageoffer1 = 'https://qph.cf2.quoracdn.net/main-qimg-7b6c3e62b5799be6bad531b54a596083-lq'
const languageoffer2 = 'https://pbs.twimg.com/media/FFhrlrbVUAItwfQ?format=jpg&name=large'
const languageoffer3 = 'https://i.pinimg.com/736x/3e/a7/f7/3ea7f79e97bacc7411ae42712046a2fb.jpg'




export const data = [
     {
        id:1,
        image: img1,
        subtext: '31st Dec 10:30PM',
        title: 'United Arab Emirates',
        linkCountry:'UAE',
        avatar: image1,
        visaOn:'Visa Required',
        name:"Steven Townsend",
        date: 'December 29, 2023 6:0:0',
        liveDate:'September 13, 2024 6:0:0',
        category: 'Visa Required',
        visaTime: '53K+ Visas on Time',
        price: '₹11,200',
        flights: '39 Direct flights',
        from: 'from ₹20.3k',
        
        subImage:[
            {
                index:1,
                image:item13,
            },
            {
                index:2,
                image:item3,
            },
            {
                index:3,
                image:item4,
            },
        ]
        },
        {
            id:2,
            image: img2,
            subtext: '17th Jan 8:30AM',
            title: 'Egypt',
            linkCountry:'Egypt',
            avatar: image2,
            visaOn:'Visa Free',
            price: '₹16,200',
            flights: '21 Direct flights',
            from: 'from ₹11k',
            visaTime: '24K+ Visas on Time',
            name:"Tiffany Betancourt",
            liveDate:'November 29, 2024 6:0:0',
             category: 'Visa free',
            subImage:[
                {
                    index:4,
                    image:item1,
                },
                {
                    index:5,
                    image:image12,
                },
                {
                    index:6,
                    image:item6,
                },
            ]
        },
        {
            id:3,
            image: img3,
            subtext: '8th Feb 1:30PM',
            title: 'Vietnam',
            linkCountry:'Vietnam',
            avatar: image3,
            price: '₹8,000',
            visaOn:'E- Visa',
            flights: '29 Direct flights',
            from: 'from ₹17.3k',
            visaTime: '27K+ Visas on Time',
            name:"Jacqueline Burns",
            liveDate:'March 13, 2024 6:0:0',
            category: 'E - Visa',
            subImage:[
                {
                    index:7,
                    image:item14,
                },
                {
                    index:8,
                    image:item13,
                },
                {
                    index:9,
                    image:item5,
                },
            ]
        }, {
            id:4,
            image: img4,
            subtext: '1st Jan 5:30PM',
            title: 'Singapore',
            linkCountry:'Singapore',
            avatar: image4,
            name:"Mari Harrington",
            price: '₹5,000',
            visaOn:'Visa Required',
            flights: '27 Direct flights',
            from: 'from ₹18.3k',
            visaTime: '22K+ Visas on Time',
            date: 'January 13, 2024 5:3:1',
            liveDate:'May 6, 2024 6:0:0',
             category: 'Visa Required',
            subImage:[
                {
                    index:10,
                    image:item7,
                },
                {
                    index:11,
                    image:item8,
                },
                {
                    index:12,
                    image:item5,
                },
            ]
        }, {
            id:5,
            image: img5,
            subtext: '18th Nov 2:30PM',
            title: 'Australia',
            linkCountry:'Australia',
            avatar: image5,
            price: '₹4,700',
            visaOn:'Visa free',
            flights: '4 Direct flights',
            from: 'from ₹2.3k',
            visaTime: '11K+ Visas on Time',
            name:"Floyd Glasgow",
            liveDate:'July 19, 2024 6:0:0',
            category: 'Visa free',
            subImage:[
                {
                    index:13,
                    image:item15,
                },
                {
                    index:14,
                    image:item9,
                },
                {
                    index:15,
                    image:item12,
                },
            ]
        }, {
            id:6,
            image: img6,
            subtext: '13th Aug 12:00PM',
            title: 'Morocco',
            linkCountry:'Morocco',
            avatar: image6,
            price: '₹17,900',
            visaOn:'Visa On Arrival',
            flights: '22 Direct flights',
            from: 'from ₹12.3k',
            visaTime: '36K+ Visas on Time',
            name:"Donna Schultz",
            liveDate:'July 20, 2024 6:0:0',
            category: 'Visa On Arrival',
            subImage:[
                {
                    index:16,
                    image:item10,
                },
                {
                    index:17,
                    image:item3,
                },
                {
                    index:18,
                    image:item11,
                },
            ]
        }, {
            id:7,
            image: img7,
            subtext: 'Oct Jan 5:20PM',
            title: 'Bahrain',
            linkCountry:'Bahrain',
            avatar: image7,
            name:"Japan",
            price: '₹11,000',
            visaOn:'E - Visa',
            flights: '10 Direct flights',
            from: 'from ₹10k',
            visaTime: '23K+ Visas on Time',
            date: 'December 29, 2023 6:0:0',
            liveDate:'February 17, 2024 6:0:0',
            category: 'E - Visa',
            subImage:[
                {
                    index:19,
                    image:item16,
                },
                {
                    index:20,
                    image:item17,
                },
                {
                    index:21,
                    image:item18,
                },
            ]
        }, {
            id:8,
            image: img8,
            subtext: '8th Dec 5:01PM',
            linkCountry:'China',
            title: 'China',
            avatar: image8,
            name:"Rosaria Vargas",
            visaOn:'Visa Required',
            flights: '18 Direct flights',
            from: 'from ₹12k',
            price: '₹21,200',
            visaTime: '32K+ Visas on Time',
            liveDate:'April 15, 2024 6:0:0',
            category: 'Visa Required',
            subImage:[
                {
                    index:22,
                    image:item7,
                },
                {
                    index:24,
                    image:item9,
                },
                {
                    index:24,
                    image:item19,
                },
            ]
        },
        {
            id:9,
            image: img9,
            subtext: '25th Sept 3:35PM',
            linkCountry:'Oman',
            title: 'Oman',
            avatar: image9,
            name:"Carl Williams",
            visaOn:'Visa On Arrival',
            flights: '41 Direct flights',
            from: 'from ₹18k',
            price: '₹12,500',
            visaTime: '35K+ Visas on Time',
            liveDate:'May 17, 2024 6:0:0',
            category: 'Visa On Arrival',
            subImage:[
                {
                    index:25,
                    image:item22,
                },
                {
                    index:26,
                    image:item23,
                },
                {
                    index:27,
                    image:item24,
                },
            ]
        },
        {
            id:10,
            image: img10,
            subtext: '28th June 1:00PM',
            linkCountry:'Malaysia',
            title: 'Malaysia',
            avatar: image10,
            price: '₹10,000',
            visaOn:'E - Visa',
            flights: '4 Direct flights',
            from: 'from ₹6k',
            visaTime: '17K+ Visas on Time',
            name:"Rosaria Vargas",
            liveDate:'August 8, 2024 6:0:0',
             category: 'E - Visa',
            subImage:[
                {
                    index:28,
                    image:item25,
                },
                {
                    index:29,
                    image:item19,
                },
                {
                    index:30,
                    image:item26,
                },
            ]
        },
        {
            id:11,
            image: img11,
            subtext: '20th Feb 3:30PM',
            linkCountry:'Sri-Lanka',
            title: 'Sri Lanka',
            avatar: image11,
            price: '₹8,000',
            visaOn:'Visa Free',
            flights: '9 Direct flights',
            from: 'from ₹11.k',
            visaTime: '30K+ Visas on Time',
            name:"Julius Canale",
            liveDate:'December 27, 2024 6:0:0',
             category: 'Visa free',
            subImage:[
                {
                    index:31,
                    image:item27,
                },
                {
                    index:32,
                    image:item24,
                },
                {
                    index:33,
                    image:item28,
                },
            ]
        },
        {
            id:12,
            image: img12,
            subtext: '18th Jan 5:30PM',
            linkCountry:'Indonesia',
            title: 'Indonesia',
            price: '₹2,500',
            visaOn:'Visa Required',
            flights: '19 Direct flights',
            from: 'from ₹12.3k',
            visaTime: '45K+ Visas on Time',
            avatar: image12,
            name:"Michael Williams",
            liveDate:'December 25, 2024 6:0:0',
            category: 'Visa Required',
            subImage:[
                {
                    index:34,
                    image:item7,
                },
                {
                    index:35,
                    image:item14,
                },
                {
                    index:36,
                    image:item19,
                },
            ]
        },
]
export const visaDetails = [
     {
        id:1,
        image: img1,
        authorize: 'UAE',
        money: '0.044 Dirham',
        title: 'Dubai Tourist Visa',
        avatar: image1,
        visaDate:"3 February 2024 at 1:37 PM",
        date: 'December 29, 2023 6:0:0',
        liveDate:'September 13, 2024 6:0:0',
        category: 'Games',
        visaTime: '53K+ Visas on Time',
        price: '₹11,200',
        flights: '39 Direct flights from ₹20.3k',
        travelimg:'',
        traveltitle:'UAE',
        visaOn:'Visa On Arrival',
        // travelimg:'',
        // travelimg:'',
        lat:'23.61839605045764', 
        long:'53.81077845896061',
        
        },
        {
            id:2,
            image: img2,
            authorize: 'Egypt',
            money: '0.37 EGP',
            title: 'Egypt Tourist Visa',
            avatar: image2,
            price: '₹16,200',
            flights: '21 Direct flights from ₹11k',
            visaTime: '24K+ Visas on Time',
            visaDate:"6 February 2024 at 1:08 PM",
            liveDate:'November 29, 2024 6:0:0',
            category: 'Games',
            travelimg:'',
            traveltitle:'Egypt',
            visaOn:'E-Visa',
            // travelimg:'',
            // travelimg:'',
            lat:'26.825737652099786 ',
            long:'30.802492734271702',
        },
        {
            id:3,
            image: img3,
            authorize: 'Vietnam',
            money: '293.416289 VND',
            title: 'Vietnam Tourist Visa',
            avatar: image3,
            price: '₹8,000',
            flights: '29 Direct flights from ₹17.3k',
            visaTime: '27K+ Visas on Time',
            visaDate:"9 February 2024 at 11:38 PM",
            liveDate:'March 13, 2024 6:0:0',
            category: 'Art',
            travelimg:'',
            authorize: 'Vietnam',
            traveltitle:'Vietnam',
            visaOn:'Visa On Arrival',
            // travelimg:'',
            // travelimg:'',
            lat:'14.06148436935202',
            long:'108.27771256790729',
        }, {
            id:4,
            image: img4,
            authorize: 'Singapore',
            money: '0.01617 SGD',
            title: 'Singapore Tourist Visa',
            avatar: image4,
            visaDate:"27 February 2024 at 11:39 AM",
            price: '₹5,000',
            flights: '27 Direct flights from ₹18.3k',
            visaTime: '22K+ Visas on Time',
            date: 'January 13, 2024 5:3:1',
            liveDate:'May 6, 2024 6:0:0',
            category: 'Music',
            travelimg:'',
            traveltitle:'Singapore',
            visaOn:'Visa On Arrival',
            // travelimg:'',
            // travelimg:'',
            lat:'1.3548284692692016',
            long:'103.82034953809345',
        }, {
            id:5,
            image: img5,
            authorize: 'Australia',
            money: '0.018 Australian Dollar',
            title: 'Australia Tourist Visa',
            avatar: image5,
            price: '₹4,700',
            flights: '4 Direct flights from ₹2.3k',
            visaTime: '11K+ Visas on Time',
            visaDate:"26 February 2024 at 11:39 AM",
            liveDate:'July 19, 2024 6:0:0',
            category: 'Video',
            travelimg:'',
            traveltitle:'Australia',
            visaOn:'E-Visa',
            // travelimg:'',
            // travelimg:'',
            lat:'-25.26909721717682',
            long:'133.77478755827192',
        }, {
            id:6,
            image: img6,
            authorize: 'Morocco',
            money: '0.12 Moroccan Dirham',
            title: 'Morocco Tourist Visa',
            avatar: image6,
            price: '₹17,900',
            flights: '22 Direct flights from ₹12.3k',
            visaTime: '36K+ Visas on Time',
            visaDate:"2 February 2024 at 12:35 AM",
            liveDate:'July 20, 2024 6:0:0',
            category: 'Games',
            travelimg:'',
            traveltitle:'Morocco',
            visaOn:'Visa On Arrival',
            // travelimg:'',
            // travelimg:'',
            lat:'31.796344377219615',
            long:'-7.0922817107957625',
        }, {
            id:7,
            image: img7,
            authorize: 'Bahrain',
            money: '0.0045 Bahraini Dinar',
            title: 'Bahrain Tourist Visa',
            avatar: image7,
            visaDate:"6 February 2024 at 2:40 PM",
            price: '₹11,000',
            flights: '10 Direct flights from ₹10k',
            visaTime: '23K+ Visas on Time',
            date: 'December 29, 2023 6:0:0',
            liveDate:'February 17, 2024 6:0:0',
            category: 'Games',
            travelimg:'',
            traveltitle:'Bahrain',
            visaOn:'Visa On Arrival',
            // travelimg:'',
            // travelimg:'',
            lat:'26.070994155930375',
            long:'50.5570080570528',
        }, {
            id:8,
            image: img8,
            authorize: 'China',
            money: '0.0856 CNY',
            title: 'China Tourist Visa',
            avatar: image8,
            visaDate:"2 February 2024 at 5:40 PM",
            flights: '18 Direct flights from ₹12k',
            price: '₹21,200',
            visaTime: '32K+ Visas on Time',
            liveDate:'April 15, 2024 6:0:0',
            category: 'Art',
            travelimg:'',
            traveltitle:'China',
            visaOn:'Visa On Arrival',
            // travelimg:'',
            // travelimg:'',
            lat:'35.866640045285536',
            long:'104.1957355079714',
        },
        {
            id:9,
            image: img9,
            authorize: 'Oman',
            money: '0.0046 Omani Rial',
            title: 'Oman Tourist Visa',
            avatar: image9,
            visaDate:"11 February 2024 at 4:53 PM",
            flights: '41 Direct flights from ₹18k',
            price: '₹12,500',
            visaTime: '35K+ Visas on Time',
            liveDate:'May 17, 2024 6:0:0',
            category: 'Music',
            travelimg:'',
            traveltitle:'Oman',
            visaOn:'Visa On Arrival',
            // travelimg:'',
            // travelimg:'',
            lat:'21.476403252569632',
            long:'55.97541163381694',
        },
        {
            id:10,
            image: img10,
            authorize: 'Malaysia',
            money: '0.057 Malaysian Ringgit',
            title: 'Malaysia Tourist Visa',
            avatar: image10,
            price: '₹10,000',
            flights: '4 Direct flights from ₹6k',
            visaTime: '17K+ Visas on Time',
            visaDate:"11 February 2024 at 12:41 PM",
            liveDate:'August 8, 2024 6:0:0',
            category: 'Memes',
            travelimg:'',
            traveltitle:'Malaysia',
            visaOn:'Visa On Arrival',
            // travelimg:'',
            // travelimg:'',
            lat:'4.213050885151056',
            long:'101.97627953855518',
        },
        {
            id:11,
            image: img11,
            authorize: 'Sri Lanka',
            money: '3.78 Sri Lankan Rupee',
            title: 'Sri Lanka Tourist Visa',
            avatar: image11,
            price: '₹8,000',
            flights: '9 Direct flights from ₹11.k',
            visaTime: '30K+ Visas on Time',
            visaDate:"10 February 2024 at 11:41 AM",
            liveDate:'December 27, 2024 6:0:0',
            category: 'Memes',
            travelimg:'',
            traveltitle:'Sri Lanka',
            visaOn:'Visa On Arrival',
            // travelimg:'',
            // travelimg:'',
            lat:'7.87543259226873',
            long:'80.7712805762386',
        },
        {
            id:12,
            image: img12,
            authorize: 'Indonesia',
            money: '188.65 Indonesian Rupiah',
            title: 'Indonesia Tourist Visa',
            price: '₹2,500',
            flights: '19 Direct flights from ₹12.3k',
            visaTime: '45K+ Visas on Time',
            avatar: image12,
            visaDate:"1 February 2024 at 4:49 PM",
            liveDate:'December 25, 2024 6:0:0',
            category: 'Music',
            travelimg:'',
            traveltitle:'Indonesia',
            visaOn:'Visa On Arrival',
            // travelimg:'',
            // travelimg:'',
            lat:'-0.7868717610659562',
            long:'113.92063891053846',
        },
]
export const university = [
    {
       id:1,
       image: university1,
       location: 'Cambridge, USA',
       title: 'Massachusetts Institute',
       years:'3 Years',
       duration:'February, July 2024',
       logo:'https://www.fotw.info/images/u/us_u_mit.gif',
       avatar: image1,
       name:"Steven Townsend",
       date: 'December 29, 2023 6:0:0',
       fee:'31,077',
       category: 'Games',
       score: '6.2 IELTS',
       price: '₹11,200',
       course: 'Bachelor Degree',
       subImage:[
           {
               index:1,
               image:item13,
           },
           {
               index:2,
               image:item3,
           },
           {
               index:3,
               image:item4,
           },
       ]
       },
       {
           id:2,
           image: university2,
           location: 'Munich, Germany',
           title: 'Munich University',
           avatar: image2,
           years:'2 Years',
           duration:'August, February 2024',
           logo:'https://media.licdn.com/dms/image/C4E0BAQEYH5NEBGxLDQ/company-logo_200_200/0/1671618750583/tum_ed_logo?e=2147483647&v=beta&t=BHnug2rjeYoAJYWNptqeYfE7N43Myu-y1sOPlKV_4D0',
           price: '₹16,200',
           course: 'Graduate Certificate',
           score: '6.0 IELTS',
           name:"Tiffany Betancourt",
           fee:'22,000',
            category: 'Games',
           subImage:[
               {
                   index:4,
                   image:item1,
               },
               {
                   index:5,
                   image:image12,
               },
               {
                   index:6,
                   image:item6,
               },
           ]
       },
       {
           id:3,
           image: university3,
           location: 'Singapore, Singapore',
           title: 'Singapore University',
           avatar: image3,
           years:'5 Years',
           duration:'January, July 2024',
           logo:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhIb72L8zxCswnn2HizV59c_LP1mpACMce870dCKSBRA&s',
           price: '₹8,000',
           course: 'Graduate Diploma',
           score: '6.5 IELTS',
           name:"Jacqueline Burns",
           fee:'19,500',
           category: 'Art',
           subImage:[
               {
                   index:7,
                   image:item14,
               },
               {
                   index:8,
                   image:item13,
               },
               {
                   index:9,
                   image:item5,
               },
           ]
       }, {
           id:4,
           image: university4,
           location: 'Bradford, UK',
           title: 'The University Bradford',
           avatar: image4,
           years:'2 Years',
           duration:'April, December 2024',
           logo:'https://upload.wikimedia.org/wikipedia/commons/e/e9/Shield_of_the_University_of_Bradford.svg',
           name:"Mari Harrington",
           price: '₹5,000',
           course: 'Bachelor Degree',
           score: '6.5 IELTS',
           date: 'January 13, 2024 5:3:1',
           fee:'35,100',
           category: 'Music',
           subImage:[
               {
                   index:10,
                   image:item7,
               },
               {
                   index:11,
                   image:item8,
               },
               {
                   index:12,
                   image:item5,
               },
           ]
       }, 
]
export const UniversityDetails = [
     {
        id:1,
        image: university1,
        logo:'https://www.fotw.info/images/u/us_u_mit.gif',
        title: 'Massachusetts Institute of Technology',
        money: '0.044 Dirham',
        course: 'Bachelor Degree',
        ranking:'https://c8.alamy.com/comp/2AYRW7E/ranking-medal-icon-illustration-1st-place-gold-2AYRW7E.jpg',
        years:'3 Years',
        overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",
        score: '6.2 IELTS',
        duration:'February, July 2024',
        offerletterDate:"3 February 2024 at 1:37 PM",
        fee:'31,077',
        
        },
        {
            id:2,
            image: university2,
            money: '0.37 EGP',
            title: 'Technical University of Munich',
            fee:'22,000',
            logo:'https://media.licdn.com/dms/image/C4E0BAQEYH5NEBGxLDQ/company-logo_200_200/0/1671618750583/tum_ed_logo?e=2147483647&v=beta&t=BHnug2rjeYoAJYWNptqeYfE7N43Myu-y1sOPlKV_4D0',
            score: '6.0 IELTS',
            ranking:'https://c8.alamy.com/comp/2AYRW4R/ranking-medal-icon-illustration-2nd-place-silver-2AYRW4R.jpg',
            duration:'August, February 2024',
            course: 'Graduate Certificate',
            overview: "A Graduate Certificate program offers specialized education beyond the undergraduate level, typically focusing on a specific area of study or professional development. Studying abroad for such a program provides an enriching experience, allowing students to immerse themselves in different cultures, gain international perspectives, and access unique academic resources and expertise. It offers opportunities for personal and professional growth, fostering independence, adaptability, and intercultural communication skills that are invaluable in today's globalized world.",
            years:'2 Years',
            offerletterDate:"6 February 2024 at 1:08 PM",
            
        },
        {
            id:3,
            image: university3,
            money: '293.416289 VND',
            title: 'National University of Singapore', 
            fee:'19,500',
            logo:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhIb72L8zxCswnn2HizV59c_LP1mpACMce870dCKSBRA&s',
            score: '6.5 IELTS',
            ranking:'https://c8.alamy.com/comp/2AYRTYF/ranking-medal-icon-illustration-3rd-place-bronze-2AYRTYF.jpg',
            duration:'January, July 2024',
            course: 'Graduate Diploma',
            overview: "A Graduate Diploma program offered abroad provides an immersive educational experience tailored to equip students with advanced knowledge and specialized skills in their chosen field. This course typically delves deep into the subject matter, offering rigorous academic training and practical insights essential for professional advancement. Moreover, studying abroad offers exposure to diverse cultures, perspectives, and networks, enriching the learning journey and fostering global competency.",
            years:'5 Years',
            offerletterDate:"9 February 2024 at 11:38 PM",
            
        }, {
            id:4,
            image: university4,
            money: '0.01617 SGD',
            title: 'The University of Bradford',
            course: 'Bachelor Degree',
            overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",
            ranking:'https://c8.alamy.com/comp/2AYRW7E/ranking-medal-icon-illustration-1st-place-gold-2AYRW7E.jpg',
            logo:'https://upload.wikimedia.org/wikipedia/commons/e/e9/Shield_of_the_University_of_Bradford.svg',
            score: '6.5 IELTS',
            years:'2 Years',
            duration:'April, December 2024',
            offerletterDate:"27 February 2024 at 11:39 AM",
            fee:'35,100',
            
            
        }, 
]
export const consultants = [
    {
       id:1,
       image: consultant1,
       location: 'California, USA',
       title: 'Immivoyage',
       years:'3 Years',
       travel:'Visa Type Assessment',
       logo:'https://images.click.in/classifieds/images/148/20_09_2021_11_45_37_7255c729a12795c249c5c8e4826ad37e_b.png',
       avatar: image1,
       name:"Steven Townsend",
       date: 'December 29, 2023 6:0:0',
       fee:'₹15,200',
       category: 'Games',
       partner: 'Legal Assistance',
       rating: '4',
       assistants: 'Visa Interview Preparation',
       
       },
       {
           id:2,
           image: consultant2,
           location: 'Beijing, China',
           title: 'PH Visa',
           avatar: image2,
           years:'2 Years',
           travel:'Visa Application Assistance',
           logo:'https://img.freepik.com/premium-vector/go-traveling-logo-design-template_145155-3770.jpg',
           rating: '2',
           assistants: 'Application Assistance',
           partner: 'Consultation & Assessment',
           name:"Tiffany Betancourt",
           fee:'₹10,000',
            category: 'Games',
           
       },
       {
           id:3,
           image: consultant3,
           location: 'Kuala Lumpur, Malaysia',
           title: 'Giant Migration',
           avatar: image3,
           years:'5 Years',
           travel:'Visa Interview Preparation',
           logo:'https://mir-s3-cdn-cf.behance.net/projects/404/e8c111119868481.Y3JvcCwxMjAxLDkzOSwwLDEzMA.jpg',
           rating: '3.5',
           assistants: 'Educational Guidance',
           partner: 'Evidence Gathering',
           name:"Jacqueline Burns",
           fee:'₹21,500',
           category: 'Art',
          
       }, {
           id:4,
           image: consultant4,
           location: 'Delhi, India',
           title:'Global Visas',
           avatar: image4,
           years:'2 Years',
           travel:'Visa Category Assessment',
           logo:'https://media.licdn.com/dms/image/D4D0BAQGGVSWmwCpKiw/company-logo_200_200/0/1665953913806/global_visa_consultants_logo?e=2147483647&v=beta&t=bfQXVui6yD053GqMemgiFmrKW5rrXHdDWREHseF6e4o',
           name:"Mari Harrington",
           rating: '5',
           assistants: 'Post-Arrival Support',
           partner: 'Administrative Review',
           date: 'January 13, 2024 5:3:1',
           fee:'₹15,100',
           category: 'Music',
           
       }, 
]
export const ConstantDetails = [
     {
        id:1,
        image: consultant1,
        logo:'https://images.click.in/classifieds/images/148/20_09_2021_11_45_37_7255c729a12795c249c5c8e4826ad37e_b.png',
        title: 'Immivoyage',
        money: '0.044 Dirham',
        course: 'Bachelor Degree',
        ranking:'https://c8.alamy.com/comp/2AYRW7E/ranking-medal-icon-illustration-1st-place-gold-2AYRW7E.jpg',
        years:'3 Years',
        overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",
        score: '6.2 IELTS',
        duration:'February, July 2024',
        offerletterDate:"3 February 2024 at 1:37 PM",
        fee:'31,077',
        lat:'38.59312981498014',
        long:'-121.54002691998568',
        
        },
        {
            id:2,
            image: consultant2,
            money: '0.37 EGP',
            title: 'PH Visa',
            fee:'22,000',
            logo:'https://img.freepik.com/premium-vector/go-traveling-logo-design-template_145155-3770.jpg',
            score: '6.0 IELTS',
            ranking:'https://c8.alamy.com/comp/2AYRW4R/ranking-medal-icon-illustration-2nd-place-silver-2AYRW4R.jpg',
            duration:'August, February 2024',
            course: 'Graduate Certificate',
            overview: "A Graduate Certificate program offers specialized education beyond the undergraduate level, typically focusing on a specific area of study or professional development. Studying abroad for such a program provides an enriching experience, allowing students to immerse themselves in different cultures, gain international perspectives, and access unique academic resources and expertise. It offers opportunities for personal and professional growth, fostering independence, adaptability, and intercultural communication skills that are invaluable in today's globalized world.",
            years:'2 Years',
            offerletterDate:"6 February 2024 at 1:08 PM",
            lat:'39.928939891903646',
            long:'116.22984460222384', 
            
        },
        {
            id:3,
            image: consultant3,
            money: '293.416289 VND',
            title: 'Giant Migration', 
            fee:'19,500',
            logo:'https://mir-s3-cdn-cf.behance.net/projects/404/e8c111119868481.Y3JvcCwxMjAxLDkzOSwwLDEzMA.jpg',
            score: '6.5 IELTS',
            ranking:'https://c8.alamy.com/comp/2AYRTYF/ranking-medal-icon-illustration-3rd-place-bronze-2AYRTYF.jpg',
            duration:'January, July 2024',
            course: 'Graduate Diploma',
            overview: "A Graduate Diploma program offered abroad provides an immersive educational experience tailored to equip students with advanced knowledge and specialized skills in their chosen field. This course typically delves deep into the subject matter, offering rigorous academic training and practical insights essential for professional advancement. Moreover, studying abroad offers exposure to diverse cultures, perspectives, and networks, enriching the learning journey and fostering global competency.",
            years:'5 Years',
            offerletterDate:"9 February 2024 at 11:38 PM",
            lat:'3.1482105234841398',
            long:'101.69597153187145',
            
        }, {
            id:4,
            image: consultant4,
            money: '0.01617 SGD',
            title: 'Global Visas',
            ranking:'https://c8.alamy.com/comp/2AYRW7E/ranking-medal-icon-illustration-1st-place-gold-2AYRW7E.jpg',
            logo:'https://media.licdn.com/dms/image/D4D0BAQGGVSWmwCpKiw/company-logo_200_200/0/1665953913806/global_visa_consultants_logo?e=2147483647&v=beta&t=bfQXVui6yD053GqMemgiFmrKW5rrXHdDWREHseF6e4o',
            score: '6.5 IELTS',
            years:'2 Years',
            duration:'April, December 2024',
            offerletterDate:"27 February 2024 at 11:39 AM",
            fee:'35,100',
            lat:'28.654921990376526',
            long:'77.1757230443983',
            
            
        }, 
]
export const language = [
    {
       id:1,
       image: language1,
       location: 'Rosario, Argentina',
       title: 'Berlitz',
       years:'3 Years',
       language:'English, Spanish, French',
       logo:'https://img.freepik.com/premium-vector/logo-consulting-with-person-circle_833496-5361.jpg',
       avatar: image1,
       experience:"10",
       date: 'December 29, 2023 6:0:0',
       fee:'₹15,200',
       category: 'Games',
       duration: '3 months',
       rating: '4',
       assistants: 'Visa Interview Preparation',
       
       },
       {
           id:2,
           image: language2,
           location: 'Virginia, USA',
           title: 'Rosetta Stone',
           avatar: image2,
           years:'2 Years',
           language:'German, Italian, Japanese',
           logo:'https://img.freepik.com/premium-vector/go-traveling-logo-design-template_145155-3770.jpg',
           rating: '3',
           assistants: 'Application Assistance',
           duration: '6 weeks',
           experience:"7",
           fee:'₹10,000',
            category: 'Games',
           
       },
       {
           id:3,
           image: language3,
           location: 'New York City, USA',
           title: 'FluentU',
           avatar: image3,
           years:'5 Years',
           language:'Korean, Russian, Arabic',
           logo:'https://mir-s3-cdn-cf.behance.net/projects/404/e8c111119868481.Y3JvcCwxMjAxLDkzOSwwLDEzMA.jpg',
           rating: '2.5',
           assistants: 'Educational Guidance',
           duration: '4 months',
           experience:"5",
           fee:'₹21,500',
           category: 'Art',
          
       }, {
           id:4,
           image: language4,
           location: 'London, UK',
           title:'Language Trainers',
           avatar: image4,
           years:'2 Years',
           language:'Portuguese, Dutch, Swedish',
           logo:'https://media.licdn.com/dms/image/D4D0BAQGGVSWmwCpKiw/company-logo_200_200/0/1665953913806/global_visa_consultants_logo?e=2147483647&v=beta&t=bfQXVui6yD053GqMemgiFmrKW5rrXHdDWREHseF6e4o',
           experience:"12",
           rating: '4.5',
           assistants: 'Post-Arrival Support',
           duration: '2 weeks',
           date: 'January 13, 2024 5:3:1',
           fee:'₹15,100',
           category: 'Music',
           
       }, 
]
export const LanguageDetails = [
     {
        id:1,
        image: language1,
        logo:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAq1BMVEX///8AT+/F1/wEUvDn7foOWPGmwfn9/f8lZuszcfI/fPlrmfjq8P5EfvUCUvfX4/3z9v5rlu+qxfn4+f4UXvg5ePtXi/fA0/uRsfUZYPMKVfC1y/re6P270P3K2vzv8/4iZ/R7o/ayw+fQ3vyEqfcwcfiDqfgjafuXt/pOhfZznviivvlgkfciZe1JgPIPXPuVsu7X3vCku+rBzuxWh+2Eo+VskuIrb/xym/B0URCtAAAEZklEQVR4nO2Z6XaiShRGQSAVRQIBwyiCU+KA3bG7c837P9kVlFMM6gpI7l291rd/WVJU1baGc0BBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPw9WAZrdV80CSdRx2NpwtgksqI9GsULq3k7veVrsHh76Hp4X+dBEk9IiiCwByUrauOmzTix6bqruT35jjF+CRIRjyJj7yy1btiKZYfhRp8uzE2Dmx4rDOzptv3qLIkkeeFJT69FCXWyuz1Hw7UQJoG6Gv9o0LVYQ5K8gWt0IDKiFhfZ8DTq4mR2W2ShWk1EWF0k6/qHfL/IT2pt00zEsnvh6zCJAvVuEVHqN96hNZEl7fygmYjg9ANH6AVJg81+TeT8K94lEuYF32oowpzB++T9sG0Qg66KiMs2U1ISMexTyctiShMRQZBX01Wj1V0RkfhHpXeviCAnviRKXmA1F2lMQUTamKar+VQ2WzRXFhGE/coN88D+34mkI48GVFwV61mGLBtWac1Gck42VGZEFRFe4XiYz2a/+lzkfTb7zQoVSrTKzqoiwpSGsqU6cvAnViTJ97QPnQIMG0g5pmDpzwflqSLSpwpqFlQK6/b48cWwbOkSWqtwXBNR6ahxzlX2zzs+BEVz5VyEL8LoOc2qaiLUslqIjjmZSPXLlKCNR01kvMuLu/M6Pmd+vFpfrolssirdiNjt8v6iSOJ+/BNTcZo1yFa1EYhewMoi0yexM5G4ZZ53NY6cV6qrXLjmOWWR80nXhYjktvO4IqL0P0+H5tyjX2q6VXkhKomI3Ym8DDsViT/OE7yhrW8ygYU0PfY3iUhhS4+r2e9ylk6JQd2/ZZWTvOjNqyK+ovy8JXLwPB5rxZE30gwrUU74xYrdiqRx/jgpJhXjIMWmq25ZxFuYoRPeEtnvf/MbnoL9fi8IupMRUMXH9hG/KLLUBi+0DbKnoYTPvZ9CxWNuXBRR8rP/usiNFMWhTqftjt6KiGQyZhm/KJAousAP4yprqyhCK6KNCFvnXz+23elVkewbvpwSdunsPTEwCiIS5S1tROgev02+fV1kTFuvb/CF5g/KqMUZeawP6usi45hqtV9Yl0QmNJZ4rNHng1GhsEekhEbQXIS95rccT8IORZjMu4vHW/r8Vr+Ti6h3iNCEiEv3xPbe7FdMFoupWsh0l9GQ4oiS/lzG5/OZT70rETrRKcv3Wp3BRZHsaaBQXh8jO7e0BObyVWd0JBLUs5T7RSqkL3EmfLsv1/wJL3167ESk8N33iaQPBuzh0iNDNvJORB4Kact3iQxOr4PMelD0kzRwdSFiHC503K2In5xfa7F5JUeVdtss/nUhwrfg3SL1l9jHDa94mlMITr21d3zcldIrvrfM3293INLjO/B+kV6diV7NeSJ9croyH3LBOd3A30kOeSO1CkznXViV2qXuW/zDBQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP8n/wIal1M5GhnrLgAAAABJRU5ErkJggg==',
        title: 'Berlitz',
        money: '0.044 Dirham',
        course: 'Bachelor Degree',
        ranking:'https://c8.alamy.com/comp/2AYRW7E/ranking-medal-icon-illustration-1st-place-gold-2AYRW7E.jpg',
        years:'3 Years',
        overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",
        score: '6.2 IELTS',
        duration:'February, July 2024',
        offerletterDate:"3 February 2024 at 1:37 PM",
        fee:'31,077',
        lat:'-32.95884568135589',
        long:'-60.68852409880411',
        // lat:'10.76888029997169', //my
        // long:'76.27804878920713', //my
        
        },
        {
            id:2,
            image: language2,
            money: '0.37 EGP',
            title: 'Rosetta Stone',
            fee:'22,000',
            logo:'https://invitationdigital-res-1.cloudinary.com/image/upload/w_200,h_200,c_fill,q_auto,fl_strip_profile,f_auto/rosetta_stone_logo_5.jpg',
            score: '6.0 IELTS',
            ranking:'https://c8.alamy.com/comp/2AYRW4R/ranking-medal-icon-illustration-2nd-place-silver-2AYRW4R.jpg',
            duration:'August, February 2024',
            course: 'Graduate Certificate',
            overview: "A Graduate Certificate program offers specialized education beyond the undergraduate level, typically focusing on a specific area of study or professional development. Studying abroad for such a program provides an enriching experience, allowing students to immerse themselves in different cultures, gain international perspectives, and access unique academic resources and expertise. It offers opportunities for personal and professional growth, fostering independence, adaptability, and intercultural communication skills that are invaluable in today's globalized world.",
            years:'2 Years',
            offerletterDate:"6 February 2024 at 1:08 PM",
            lat:'38.49765330150025',
            long:'-81.1208721278442',
            
        },
        {
            id:3,
            image: language3,
            money: '293.416289 VND',
            title: 'FluentU', 
            fee:'19,500',
            logo:'https://www.perapera.org/wp-content/uploads/2019/07/logo.png',
            score: '6.5 IELTS',
            ranking:'https://c8.alamy.com/comp/2AYRTYF/ranking-medal-icon-illustration-3rd-place-bronze-2AYRTYF.jpg',
            duration:'January, July 2024',
            course: 'Graduate Diploma',
            overview: "A Graduate Diploma program offered abroad provides an immersive educational experience tailored to equip students with advanced knowledge and specialized skills in their chosen field. This course typically delves deep into the subject matter, offering rigorous academic training and practical insights essential for professional advancement. Moreover, studying abroad offers exposure to diverse cultures, perspectives, and networks, enriching the learning journey and fostering global competency.",
            years:'5 Years',
            offerletterDate:"9 February 2024 at 11:38 PM",
            lat:'40.71077665564374', 
            long:'-74.00775864134611',
            
        }, {
            id:4,
            image: language4,
            money: '0.01617 SGD',
            title: 'Language Trainers',
            ranking:'https://c8.alamy.com/comp/2AYRW7E/ranking-medal-icon-illustration-1st-place-gold-2AYRW7E.jpg',
            logo:'https://s3.amazonaws.com/resumator/customer_20170309151605_LUCVHCZGJZESLULY/logos/20181109152351_LT_logo.jpg',
            score: '6.5 IELTS',
            years:'2 Years',
            duration:'April, December 2024',
            offerletterDate:"27 February 2024 at 11:39 AM",
            fee:'35,100',
            lat:'51.50596315225419',
            long:'-0.12752177838857862',
            
            
        }, 
]

export const prpathway = [
    {
       id:1,
       image: immigrate1,
       location: 'Cambridge, USA',
       title: 'Australia',
       title1: 'Australia',
       icon:"vaadin--family",
       prstream:'Family stream',
       nominees:'australian',
       logo:'https://www.fotw.info/images/u/us_u_mit.gif',
       avatar: image1,
       startup:"Create jobs with Australia's Global Talent Visa.",
       date: 'December 29, 2023 6:0:0',
       fee:'31,077',
       category: 'Games',
       score: '6.2 IELTS',
       sponsorship: 'Sponsor family members',
       course: 'Bachelor Degree',
       route: 'pr-pathway-family-stream',
       
       },
       {
           id:2,
           image: immigrate2,
           location: 'Munich, Germany',
           title: 'Australia',
           title1: 'Canada',
           avatar: image2,
           icon:"pajamas--work",
           prstream:'Skilled Work',
           nominees:'canadian',
           logo:'https://media.licdn.com/dms/image/C4E0BAQEYH5NEBGxLDQ/company-logo_200_200/0/1671618750583/tum_ed_logo?e=2147483647&v=beta&t=BHnug2rjeYoAJYWNptqeYfE7N43Myu-y1sOPlKV_4D0',
           price: '₹16,200',
           sponsorship: 'Sponsor your relatives',
           score: '6.0 IELTS',
           startup:"Immigrate by starting a business and creating jobs",
           fee:'22,000',
            category: 'Games',
            route: 'pr-pathway-skilled-work',
           
       },
       {
           id:3,
           image: immigrate3,
           location: 'Singapore, Singapore',
           title: 'Australia',
           title1: 'New Zealand',
           avatar: image3,
           icon:"ri--visa-fill",
           prstream:'Start-Up Visa',
           nominees:'new zealand',
           logo:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhIb72L8zxCswnn2HizV59c_LP1mpACMce870dCKSBRA&s',
           sponsorship: 'Sponsor family members',
           course: 'Graduate Diploma',
           score: '6.5 IELTS',
           startup:"Create jobs via New Zealand's Entrepreneur Visa.",
           fee:'19,500',
           category: 'Art',
           route: 'pr-pathway-startup-visa',
           
       },
        {
           id:4,
           image: immigrate4,
           location: 'Bradford, UK',
           title: 'Australia',
           title1: 'USA',
           avatar: image4,
           icon:"gg--check-o",
           prstream:'Provincial Nominees',
           nominees:'american',
           logo:'https://upload.wikimedia.org/wikipedia/commons/e/e9/Shield_of_the_University_of_Bradford.svg',
           startup:"Boost economy with E-2 Investor Visa.",
           sponsorship: 'Sponsor your relatives',
           course: 'Bachelor Degree',
           score: '6.5 IELTS',
           date: 'January 13, 2024 5:3:1',
           fee:'35,100',
           category: 'Music',
           route: 'pr-pathway-provicial-nominees',
           
       }, 
]
export const prpathwayCountry = [
    {
       id:1,
       image: immigrate1,
       location: 'Cambridge, USA',
       title: 'Australia',
       icon:"vaadin--family",
       prstream:'Family stream',
       nominees:'australian',
       logo:'https://www.fotw.info/images/u/us_u_mit.gif',
       avatar: image1,
       startup:"Create jobs with Australia's Global Talent Visa.",
       date: 'December 29, 2023 6:0:0',
       fee:'31,077',
       category: 'Games',
       score: '6.2 IELTS',
       sponsorship: 'Sponsor family members',
       course: 'Bachelor Degree',
       route: 'pr-pathway-family-stream',
       
       },
       {
           id:2,
           image: immigrate1,
           title: 'Australia',
           icon:"pajamas--work",
           prstream:'Skilled Work',
           nominees:'canadian',
           logo:'https://media.licdn.com/dms/image/C4E0BAQEYH5NEBGxLDQ/company-logo_200_200/0/1671618750583/tum_ed_logo?e=2147483647&v=beta&t=BHnug2rjeYoAJYWNptqeYfE7N43Myu-y1sOPlKV_4D0',
           price: '₹16,200',
           sponsorship: 'Sponsor your relatives',
           score: '6.0 IELTS',
           startup:"Immigrate by starting a business and creating jobs",
           fee:'22,000',
            category: 'Games',
            route: 'pr-pathway-skilled-work',
           
       },
       {
           id:3,
           image: immigrate1,
           location: 'Singapore, Singapore',
           title: 'Australia',
           icon:"ri--visa-fill",
           prstream:'Start-Up Visa',
           nominees:'new zealand',
           logo:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhIb72L8zxCswnn2HizV59c_LP1mpACMce870dCKSBRA&s',
           sponsorship: 'Sponsor family members',
           course: 'Graduate Diploma',
           score: '6.5 IELTS',
           startup:"Create jobs via New Zealand's Entrepreneur Visa.",
           fee:'19,500',
           category: 'Art',
           route: 'pr-pathway-startup-visa',
           
       },
        {
           id:4,
           image: immigrate1,
           location: 'Bradford, UK',
           title: 'Australia',
           icon:"gg--check-o",
           prstream:'Provincial Nominees',
           nominees:'american',
           logo:'https://upload.wikimedia.org/wikipedia/commons/e/e9/Shield_of_the_University_of_Bradford.svg',
           startup:"Boost economy with E-2 Investor Visa.",
           sponsorship: 'Sponsor your relatives',
           course: 'Bachelor Degree',
           score: '6.5 IELTS',
           date: 'January 13, 2024 5:3:1',
           fee:'35,100',
           category: 'Music',
           route: 'pr-pathway-provicial-nominees',
           
       }, 
    {
       id:5,
       image: immigrate2,
       location: 'Cambridge, USA',
       title: 'Canada',
       icon:"vaadin--family",
       prstream:'Family stream',
       nominees:'australian',
       logo:'https://www.fotw.info/images/u/us_u_mit.gif',
       avatar: image1,
       startup:"Create jobs with Australia's Global Talent Visa.",
       date: 'December 29, 2023 6:0:0',
       fee:'31,077',
       category: 'Games',
       score: '6.2 IELTS',
       sponsorship: 'Sponsor family members',
       course: 'Bachelor Degree',
       route: 'pr-pathway-family-stream',
       
       },
       {
           id:6,
           image: immigrate2,
           title: 'Canada',
           icon:"pajamas--work",
           prstream:'Skilled Work',
           nominees:'canadian',
           logo:'https://media.licdn.com/dms/image/C4E0BAQEYH5NEBGxLDQ/company-logo_200_200/0/1671618750583/tum_ed_logo?e=2147483647&v=beta&t=BHnug2rjeYoAJYWNptqeYfE7N43Myu-y1sOPlKV_4D0',
           price: '₹16,200',
           sponsorship: 'Sponsor your relatives',
           score: '6.0 IELTS',
           startup:"Immigrate by starting a business and creating jobs",
           fee:'22,000',
            category: 'Games',
            route: 'pr-pathway-skilled-work',
           
       },
       {
           id:7,
           image: immigrate2,
           location: 'Singapore, Singapore',
           title: 'Canada',
           icon:"ri--visa-fill",
           prstream:'Start-Up Visa',
           nominees:'new zealand',
           logo:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhIb72L8zxCswnn2HizV59c_LP1mpACMce870dCKSBRA&s',
           sponsorship: 'Sponsor family members',
           course: 'Graduate Diploma',
           score: '6.5 IELTS',
           startup:"Create jobs via New Zealand's Entrepreneur Visa.",
           fee:'19,500',
           category: 'Art',
           route: 'pr-pathway-startup-visa',
           
       },
        {
           id:8,
           image: immigrate2,
           location: 'Bradford, UK',
           title: 'Canada',
           icon:"gg--check-o",
           prstream:'Provincial Nominees',
           nominees:'american',
           logo:'https://upload.wikimedia.org/wikipedia/commons/e/e9/Shield_of_the_University_of_Bradford.svg',
           startup:"Boost economy with E-2 Investor Visa.",
           sponsorship: 'Sponsor your relatives',
           course: 'Bachelor Degree',
           score: '6.5 IELTS',
           date: 'January 13, 2024 5:3:1',
           fee:'35,100',
           category: 'Music',
           route: 'pr-pathway-provicial-nominees',
           
       }, 
    {
       id:9,
       image: immigrate3,
       location: 'Cambridge, USA',
       title: 'New Zealand',
       icon:"vaadin--family",
       prstream:'Family stream',
       nominees:'australian',
       logo:'https://www.fotw.info/images/u/us_u_mit.gif',
       avatar: image1,
       startup:"Create jobs with Australia's Global Talent Visa.",
       date: 'December 29, 2023 6:0:0',
       fee:'31,077',
       category: 'Games',
       score: '6.2 IELTS',
       sponsorship: 'Sponsor family members',
       course: 'Bachelor Degree',
       route: 'pr-pathway-family-stream',
       
       },
       {
           id:10,
           image: immigrate3,
           title: 'New Zealand',
           icon:"pajamas--work",
           prstream:'Skilled Work',
           nominees:'canadian',
           logo:'https://media.licdn.com/dms/image/C4E0BAQEYH5NEBGxLDQ/company-logo_200_200/0/1671618750583/tum_ed_logo?e=2147483647&v=beta&t=BHnug2rjeYoAJYWNptqeYfE7N43Myu-y1sOPlKV_4D0',
           price: '₹16,200',
           sponsorship: 'Sponsor your relatives',
           score: '6.0 IELTS',
           startup:"Immigrate by starting a business and creating jobs",
           fee:'22,000',
            category: 'Games',
            route: 'pr-pathway-skilled-work',
           
       },
       {
           id:11,
           image: immigrate3,
           location: 'Singapore, Singapore',
           title: 'New Zealand',
           icon:"ri--visa-fill",
           prstream:'Start-Up Visa',
           nominees:'new zealand',
           logo:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhIb72L8zxCswnn2HizV59c_LP1mpACMce870dCKSBRA&s',
           sponsorship: 'Sponsor family members',
           course: 'Graduate Diploma',
           score: '6.5 IELTS',
           startup:"Create jobs via New Zealand's Entrepreneur Visa.",
           fee:'19,500',
           category: 'Art',
           route: 'pr-pathway-startup-visa',
           
       },
        {
           id:12,
           image: immigrate3,
           location: 'Bradford, UK',
           title: 'New Zealand',
           icon:"gg--check-o",
           prstream:'Provincial Nominees',
           nominees:'american',
           logo:'https://upload.wikimedia.org/wikipedia/commons/e/e9/Shield_of_the_University_of_Bradford.svg',
           startup:"Boost economy with E-2 Investor Visa.",
           sponsorship: 'Sponsor your relatives',
           course: 'Bachelor Degree',
           score: '6.5 IELTS',
           date: 'January 13, 2024 5:3:1',
           fee:'35,100',
           category: 'Music',
           route: 'pr-pathway-provicial-nominees',
           
       }, 
    {
       id:13,
       image: immigrate4,
       location: 'Cambridge, USA',
       title: 'USA',
       icon:"vaadin--family",
       prstream:'Family stream',
       nominees:'australian',
       logo:'https://www.fotw.info/images/u/us_u_mit.gif',
       avatar: image1,
       startup:"Create jobs with Australia's Global Talent Visa.",
       date: 'December 29, 2023 6:0:0',
       fee:'31,077',
       category: 'Games',
       score: '6.2 IELTS',
       sponsorship: 'Sponsor family members',
       course: 'Bachelor Degree',
       route: 'pr-pathway-family-stream',
       
       },
       {
           id:14,
           image: immigrate4,
           title: 'USA',
           icon:"pajamas--work",
           prstream:'Skilled Work',
           nominees:'canadian',
           logo:'https://media.licdn.com/dms/image/C4E0BAQEYH5NEBGxLDQ/company-logo_200_200/0/1671618750583/tum_ed_logo?e=2147483647&v=beta&t=BHnug2rjeYoAJYWNptqeYfE7N43Myu-y1sOPlKV_4D0',
           price: '₹16,200',
           sponsorship: 'Sponsor your relatives',
           score: '6.0 IELTS',
           startup:"Immigrate by starting a business and creating jobs",
           fee:'22,000',
            category: 'Games',
            route: 'pr-pathway-skilled-work',
           
       },
       {
           id:15,
           image: immigrate4,
           location: 'Singapore, Singapore',
           title: 'USA',
           icon:"ri--visa-fill",
           prstream:'Start-Up Visa',
           nominees:'new zealand',
           logo:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhIb72L8zxCswnn2HizV59c_LP1mpACMce870dCKSBRA&s',
           sponsorship: 'Sponsor family members',
           course: 'Graduate Diploma',
           score: '6.5 IELTS',
           startup:"Create jobs via New Zealand's Entrepreneur Visa.",
           fee:'19,500',
           category: 'Art',
           route: 'pr-pathway-startup-visa',
           
       },
        {
           id:16,
           image: immigrate4,
           location: 'Bradford, UK',
           title: 'USA',
           icon:"gg--check-o",
           prstream:'Provincial Nominees',
           nominees:'american',
           logo:'https://upload.wikimedia.org/wikipedia/commons/e/e9/Shield_of_the_University_of_Bradford.svg',
           startup:"Boost economy with E-2 Investor Visa.",
           sponsorship: 'Sponsor your relatives',
           course: 'Bachelor Degree',
           score: '6.5 IELTS',
           date: 'January 13, 2024 5:3:1',
           fee:'35,100',
           category: 'Music',
           route: 'pr-pathway-provicial-nominees',
           
       }, 
]
export const PRPathwayDetails = [
     {
        id:1,
        image: immigrate1,
        logo:'https://play-lh.googleusercontent.com/OzjD74LK_zd-sLzSmCB131sA23VefimKd630It_C8PK5pwa7_XabAwHX4WGtW1Ka9_8',
        country: 'Australia',
        title: 'Australia PR Family - Stream',
        money: '0.044 Dirham',
        course: 'Bachelor Degree',
        ranking:'https://c8.alamy.com/comp/2AYRW7E/ranking-medal-icon-illustration-1st-place-gold-2AYRW7E.jpg',
        years:'3 Years',
        overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",
        score: '6.2 IELTS',
        duration:'February, July 2024',
        offerletterDate:"3 February 2024 at 1:37 PM",
        fee:'31,077',
        
        },
        {
            id:2,
            image: immigrate1,
            money: '0.37 EGP',
            title1:'Australia PR Skilled Work',
            fee:'22,000',
            logo:'https://play-lh.googleusercontent.com/OzjD74LK_zd-sLzSmCB131sA23VefimKd630It_C8PK5pwa7_XabAwHX4WGtW1Ka9_8',
            score: '6.0 IELTS',
            country: 'Australia',
            duration:'August, February 2024',
            course: 'Graduate Certificate',
            overview: "A Graduate Certificate program offers specialized education beyond the undergraduate level, typically focusing on a specific area of study or professional development. Studying abroad for such a program provides an enriching experience, allowing students to immerse themselves in different cultures, gain international perspectives, and access unique academic resources and expertise. It offers opportunities for personal and professional growth, fostering independence, adaptability, and intercultural communication skills that are invaluable in today's globalized world.",
            years:'2 Years',
            
            
        },
        {
            id:3,
            image: immigrate1,
            money: '293.416289 VND',
            title3:'Australia PR Start-Up Visa',
            fee:'19,500',
            logo:'https://play-lh.googleusercontent.com/OzjD74LK_zd-sLzSmCB131sA23VefimKd630It_C8PK5pwa7_XabAwHX4WGtW1Ka9_8',
            score: '6.5 IELTS',
            country: 'Australia',
            duration:'January, July 2024',
            course: 'Graduate Diploma',
            overview: "A Graduate Diploma program offered abroad provides an immersive educational experience tailored to equip students with advanced knowledge and specialized skills in their chosen field. This course typically delves deep into the subject matter, offering rigorous academic training and practical insights essential for professional advancement. Moreover, studying abroad offers exposure to diverse cultures, perspectives, and networks, enriching the learning journey and fostering global competency.",
            years:'5 Years',
           
            
        }, {
            id:4,
            image: immigrate1,
            money: '0.01617 SGD',
            title4:'Australia PR Provincial Nominees',
            course: 'Bachelor Degree',
            overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",           
            logo:'https://play-lh.googleusercontent.com/OzjD74LK_zd-sLzSmCB131sA23VefimKd630It_C8PK5pwa7_XabAwHX4WGtW1Ka9_8',
            score: '6.5 IELTS',
            years:'2 Years',
            duration:'April, December 2024',           
            fee:'35,100',
            country: 'Australia',
            
        }, 
     {
        id:5,
        image: immigrate2,
        logo:'https://5.imimg.com/data5/SELLER/Default/2021/4/ZO/HA/LD/127546891/canada-pr-visa-services-500x500.jpg',
        title: 'Canada PR Family - Stream',
        money: '0.044 Dirham',
        course: 'Bachelor Degree',
        ranking:'https://c8.alamy.com/comp/2AYRW7E/ranking-medal-icon-illustration-1st-place-gold-2AYRW7E.jpg',
        years:'3 Years',
        overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",
        score: '6.2 IELTS',
        duration:'February, July 2024',
        offerletterDate:"3 February 2024 at 1:37 PM",
        fee:'31,077',
        country: 'Canada',
        
        },
        {
            id:6,
            image: immigrate2,
            money: '0.37 EGP',
            title1:'Canada PR Skilled Work',
            fee:'22,000',
            logo:'https://5.imimg.com/data5/SELLER/Default/2021/4/ZO/HA/LD/127546891/canada-pr-visa-services-500x500.jpg',
            score: '6.0 IELTS',
            country: 'Canada',
            duration:'August, February 2024',
            course: 'Graduate Certificate',
            overview: "A Graduate Certificate program offers specialized education beyond the undergraduate level, typically focusing on a specific area of study or professional development. Studying abroad for such a program provides an enriching experience, allowing students to immerse themselves in different cultures, gain international perspectives, and access unique academic resources and expertise. It offers opportunities for personal and professional growth, fostering independence, adaptability, and intercultural communication skills that are invaluable in today's globalized world.",
            years:'2 Years',
            
            
        },
        {
            id:7,
            image: immigrate2,
            money: '293.416289 VND',
            title3:'Canada PR Start-Up Visa',
            fee:'19,500',
            logo:'https://5.imimg.com/data5/SELLER/Default/2021/4/ZO/HA/LD/127546891/canada-pr-visa-services-500x500.jpg',
            score: '6.5 IELTS',
            country: 'Canada',
            duration:'January, July 2024',
            course: 'Graduate Diploma',
            overview: "A Graduate Diploma program offered abroad provides an immersive educational experience tailored to equip students with advanced knowledge and specialized skills in their chosen field. This course typically delves deep into the subject matter, offering rigorous academic training and practical insights essential for professional advancement. Moreover, studying abroad offers exposure to diverse cultures, perspectives, and networks, enriching the learning journey and fostering global competency.",
            years:'5 Years',
           
            
        }, {
            id:8,
            image: immigrate2,
            money: '0.01617 SGD',
            title4:'Canada PR Provincial Nominees',
            course: 'Bachelor Degree',
            overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",           
            logo:'https://5.imimg.com/data5/SELLER/Default/2021/4/ZO/HA/LD/127546891/canada-pr-visa-services-500x500.jpg',
            score: '6.5 IELTS',
            years:'2 Years',
            duration:'April, December 2024',           
            fee:'35,100',
            country: 'Canada',
            
        }, 
     {
        id:9,
        image: immigrate3,
        logo:'https://play-lh.googleusercontent.com/OzjD74LK_zd-sLzSmCB131sA23VefimKd630It_C8PK5pwa7_XabAwHX4WGtW1Ka9_8',
        title: 'New-Zealand PR Family - Stream',
        money: '0.044 Dirham',
        course: 'Bachelor Degree',
        ranking:'https://w7.pngwing.com/pngs/654/103/png-transparent-flag-of-new-zealand-cook-islands-australia-flag-of-new-zealand-new-zealand-flag-s-flag-sphere-flag-of-the-united-states-thumbnail.png',
        years:'3 Years',
        overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",
        score: '6.2 IELTS',
        duration:'February, July 2024',
        offerletterDate:"3 February 2024 at 1:37 PM",
        fee:'31,077',
        country: 'New Zealand',
        
        },
        {
            id:10,
            image: immigrate3,
            money: '0.37 EGP',
            title1:'New-Zealand PR Skilled Work',
            fee:'22,000',
            logo:'https://w7.pngwing.com/pngs/654/103/png-transparent-flag-of-new-zealand-cook-islands-australia-flag-of-new-zealand-new-zealand-flag-s-flag-sphere-flag-of-the-united-states-thumbnail.png',
            score: '6.0 IELTS',
            country: 'New Zealand',
            duration:'August, February 2024',
            course: 'Graduate Certificate',
            overview: "A Graduate Certificate program offers specialized education beyond the undergraduate level, typically focusing on a specific area of study or professional development. Studying abroad for such a program provides an enriching experience, allowing students to immerse themselves in different cultures, gain international perspectives, and access unique academic resources and expertise. It offers opportunities for personal and professional growth, fostering independence, adaptability, and intercultural communication skills that are invaluable in today's globalized world.",
            years:'2 Years',
            
            
        },
        {
            id:11,
            image: immigrate3,
            money: '293.416289 VND',
            title3:'New-Zealand PR Start-Up Visa',
            fee:'19,500',
            logo:'https://w7.pngwing.com/pngs/654/103/png-transparent-flag-of-new-zealand-cook-islands-australia-flag-of-new-zealand-new-zealand-flag-s-flag-sphere-flag-of-the-united-states-thumbnail.png',
            score: '6.5 IELTS',
            country: 'New Zealand',
            duration:'January, July 2024',
            course: 'Graduate Diploma',
            overview: "A Graduate Diploma program offered abroad provides an immersive educational experience tailored to equip students with advanced knowledge and specialized skills in their chosen field. This course typically delves deep into the subject matter, offering rigorous academic training and practical insights essential for professional advancement. Moreover, studying abroad offers exposure to diverse cultures, perspectives, and networks, enriching the learning journey and fostering global competency.",
            years:'5 Years',
           
            
        }, {
            id:12,
            image: immigrate3,
            money: '0.01617 SGD',
            title4:'New-Zealand PR Provincial Nominees',
            course: 'Bachelor Degree',
            overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",           
            logo:'https://w7.pngwing.com/pngs/654/103/png-transparent-flag-of-new-zealand-cook-islands-australia-flag-of-new-zealand-new-zealand-flag-s-flag-sphere-flag-of-the-united-states-thumbnail.png',
            score: '6.5 IELTS',
            years:'2 Years',
            duration:'April, December 2024',           
            fee:'35,100',
            country: 'New Zealand',
            
        }, 
     {
        id:13,
        image: immigrate4,
        logo:'https://img.freepik.com/premium-vector/country-usa-usa-flag-vector-illustration_601298-3545.jpg',
        title: 'USA PR Family - Stream',
        money: '0.044 Dirham',
        course: 'Bachelor Degree',
        ranking:'https://c8.alamy.com/comp/2AYRW7E/ranking-medal-icon-illustration-1st-place-gold-2AYRW7E.jpg',
        years:'3 Years',
        overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",
        score: '6.2 IELTS',
        duration:'February, July 2024',
        offerletterDate:"3 February 2024 at 1:37 PM",
        fee:'31,077',
        country: 'USA',
        
        },
        {
            id:14,
            image: immigrate4,
            money: '0.37 EGP',
            title1:'USA PR Skilled Work',
            fee:'22,000',
            logo:'https://img.freepik.com/premium-vector/country-usa-usa-flag-vector-illustration_601298-3545.jpg',
            score: '6.0 IELTS',
            country: 'USA',
            duration:'August, February 2024',
            course: 'Graduate Certificate',
            overview: "A Graduate Certificate program offers specialized education beyond the undergraduate level, typically focusing on a specific area of study or professional development. Studying abroad for such a program provides an enriching experience, allowing students to immerse themselves in different cultures, gain international perspectives, and access unique academic resources and expertise. It offers opportunities for personal and professional growth, fostering independence, adaptability, and intercultural communication skills that are invaluable in today's globalized world.",
            years:'2 Years',
            
            
        },
        {
            id:15,
            image: immigrate4,
            money: '293.416289 VND',
            title3:'USA PR Start-Up Visa',
            fee:'19,500',
            logo:'https://img.freepik.com/premium-vector/country-usa-usa-flag-vector-illustration_601298-3545.jpg',
            score: '6.5 IELTS',
            country: 'USA',
            duration:'January, July 2024',
            course: 'Graduate Diploma',
            overview: "A Graduate Diploma program offered abroad provides an immersive educational experience tailored to equip students with advanced knowledge and specialized skills in their chosen field. This course typically delves deep into the subject matter, offering rigorous academic training and practical insights essential for professional advancement. Moreover, studying abroad offers exposure to diverse cultures, perspectives, and networks, enriching the learning journey and fostering global competency.",
            years:'5 Years',
           
            
        }, {
            id:16,
            image: immigrate4,
            money: '0.01617 SGD',
            title4:'USA PR Provincial Nominees',
            course: 'Bachelor Degree',
            overview: "Studying for a bachelor's degree abroad offers a transformative educational experience that extends beyond mere travel. It provides students with exposure to diverse cultures, perspectives, and academic environments, fostering personal growth and global competence. This experience not only enriches their academic journey but also equips them with invaluable skills for navigating an interconnected world and succeeding in an increasingly competitive job market.",           
            logo:'https://img.freepik.com/premium-vector/country-usa-usa-flag-vector-illustration_601298-3545.jpg',
            score: '6.5 IELTS',
            years:'2 Years',
            duration:'April, December 2024',           
            fee:'35,100',
            country: 'USA',
            
            
        }, 
]
export const health = [
     {
        id:1,
        image: healthimg1,
        subtext: "Find out everything you need to know about COVID-19 test requirements, and our flexible ticket options.",
        title: 'COVID-19 information hub',
        // avatar: image1,
        // name:"Steven Townsend",
        // date: 'December 29, 2023 6:0:0',
        // liveDate:'September 13, 2024 6:0:0',
        // category: 'Games',
        // visaTime: '53K+ Visas on Time',
        // price: '₹11,200',
        // flights: '39 Direct flights from ₹20.3k',
        subImage:[
            {
                index:1,
                image:item13,
            },
            {
                index:2,
                image:item3,
            },
            {
                index:3,
                image:item4,
            },
        ]
        },
        {
            id:2,
            image: healthimg2,
            subtext: 'Take a look around our Services and discover all the services that make your journey smoother.',
            title: 'Visa Search',
            // avatar: image2,
            // price: '₹16,200',
            // flights: '21 Direct flights from ₹11k',
            // visaTime: '24K+ Visas on Time',
            // name:"Tiffany Betancourt",
            // liveDate:'November 29, 2024 6:0:0',
            //  category: 'Games',
            subImage:[
                {
                    index:4,
                    image:item1,
                },
                {
                    index:5,
                    image:image12,
                },
                {
                    index:6,
                    image:item6,
                },
            ]
        },
        {
            id:3,
            image: healthimg3,
            subtext: 'Plan your journey with information about baggage, visas, flying as a family, assisted travel and more.',
            title: 'Before you fly',
            // avatar: image3,
            // price: '₹8,000',
            // flights: '29 Direct flights from ₹17.3k',
            // visaTime: '27K+ Visas on Time',
            // name:"Jacqueline Burns",
            // liveDate:'March 13, 2024 6:0:0',
            // category: 'Art',
            subImage:[
                {
                    index:7,
                    image:item14,
                },
                {
                    index:8,
                    image:item13,
                },
                {
                    index:9,
                    image:item5,
                },
            ]
        },
]
export const offers = [
     {
        id:1,
        image: offers1,
        image2:'https://immivoyage.com/wp-content/uploads/2021/08/Artboard-1-copy-23TOURIST-VISA-scaled.jpg',
        // subtext: "Find out everything you need to know about COVID-19 test requirements, and our flexible ticket options.",
        title: 'FLAT 40% OFF BY BOOKING US VIA INSTAGRAM',
        
        
        },
        {
            id:2,
            image: offers2,
            image2:'https://immivoyage.com/wp-content/uploads/2021/08/Artboard-1-copy-11visa-scaled.jpg',
            // subtext: 'Take a look around our Services and discover all the services that make your journey smoother.',
            title: 'FLAT 60% OFF BOOKING US VIA OUR WEBSITE',
            
        },
        {
            id:3,
            image: offers3,
            image2:'https://immivoyage.com/wp-content/uploads/2021/08/Artboard-1-copy-14GIF-scaled.jpg',
            // subtext: 'Plan your journey with information about baggage, visas, flying as a family, assisted travel and more.',
            title: 'FLAT 30% OFF BOOKING US VIA FACBOOK',
            
        },
]
export const languageoffers = [
     {
        id:1,
        image: languageoffer1,
        image2:"https://www.raoconsultants.com/wp-content/uploads/2024/01/Dhurv-Patel.png",
        // subtext: "Find out everything you need to know about COVID-19 test requirements, and our flexible ticket options.",
        title: 'FLAT 40% OFF BY BOOKING US VIA INSTAGRAM',
        
        
        },
        {
            id:2,
            image: languageoffer2,
            image2:"https://res.cloudinary.com/dzptyqozu/images/w_600,h_600,c_scale/f_auto,q_auto/v1703074067/IELTS-Arham-Shah-1/IELTS-Arham-Shah-1.jpg?_i=AA",
            // subtext: 'Take a look around our Services and discover all the services that make your journey smoother.',
            title: 'FLAT 60% OFF BOOKING US VIA OUR WEBSITE',
            
        },
        {
            id:3,
            image: languageoffer3,
            image2:"https://i.pinimg.com/736x/37/98/0e/37980e01f6cf97a53f7bed54f5413007.jpg",
            // subtext: 'Plan your journey with information about baggage, visas, flying as a family, assisted travel and more.',
            title: 'FLAT 30% OFF BOOKING US VIA FACBOOK',
            
        },
]
export const insurance = [
     {
        id:1,
        image: 'https://www.emory.edu/home/_includes/images/sections/admission/4.4/_0002_0050801-16bm-raw-f049-gobal-thinkers.jpg',
        student: 'Bupa is a well-known international healthcare group that provides health insurance and healthcare services. If Niva Bupa Health Insurance is part of this collaboration, it may offer a range of health insurance plans with coverage for medical expenses, hospitalization, and other healthcare-related costs.',
        title: 'Niva Bupa',
        insurance:'Student health cover',
        rating:'4',
        location:'Kochi, India',
        logo:'https://bsmedia.business-standard.com/_media/bs/img/article/2023-11/20/full/1700468622-5944.jpg',
        avatar: image1,
        name:"Steven Townsend",
        date: 'December 29, 2023 6:0:0',
        liveDate:'September 13, 2024 6:0:0',
        category: 'Games',
        visaTime: '53K+ Visas on Time',
        price: '₹11,200',
        flights: '39 Direct flights from ₹20.3k',
        
        },
        {
            id:2,
            image: 'https://navi.com/blog/wp-content/uploads/2022/07/Travel-Health-Insurance.jpg',
            student: 'National Insurance is a social security and welfare program that operates in various countries, providing financial protection and benefits to individuals in times of need. It is typically a government-run system designed to offer financial assistance during specific life events.',
            title: 'Allianz',
            insurance:'Visitor health cover',
            rating:'3',
            location:'Rio de Janeiro, Brazil',
            logo:'https://toppng.com/uploads/preview/allianz-logo-3d-allianz-logo-11563352755xfxw08gyfz.png',
            avatar: image2,
            price: '₹16,200',
            flights: '21 Direct flights from ₹11k',
            visaTime: '24K+ Visas on Time',
            name:"Tiffany Betancourt",
            liveDate:'November 29, 2024 6:0:0',
             category: 'Games',
            
        },
        {
            id:3,
            image: 'https://images.moneycontrol.com/static-mcnews/2022/09/Health-insurance-770x433.png?impolicy=website&width=770&height=431',
            student: 'The New India Assurance Company Limited, commonly known as New India Assurance, is one of the largest and oldest insurance companies in India. Established in 1919, the company has a rich history of providing a wide range of insurance products and services to individuals, businesses, and organizations.',
            title: 'Medibank',
            insurance:'Work health cover',
            rating:'3.8',
            location:'Tokyo, Japan',
            logo:'https://www.medibank.com.au/content/dam/medibank/custom-campaigns/Digital/medibank_logo_red.jpg',
            avatar: image3,
            price: '₹8,000',
            flights: '29 Direct flights from ₹17.3k',
            visaTime: '27K+ Visas on Time',
            name:"Jacqueline Burns",
            liveDate:'March 13, 2024 6:0:0',
            category: 'Art',
            
        }, {
            id:4,
            image: 'https://www.providentins.com/wp-content/uploads/2022/05/Provident_CriticalIllnessInsurance2.jpg',
            student: "The Oriental Insurance Company Limited, commonly known as The Oriental Insurance, is a prominent general insurance company based in India. Established on September 12, 1947, it is one of the oldest and largest insurance companies in the country. The company was nationalized in 1973 as part of the government's initiative to consolidate the insurance sector.",
            title: 'HBF',
            insurance:'Student health cover',
            rating:'4.6',
            location:'Paris, France',
            logo:'https://smilesincurrambine.com.au/wp-content/uploads/2023/07/hbf.png',
            avatar: image4,
            name:"Mari Harrington",
            price: '₹5,000',
            flights: '27 Direct flights from ₹18.3k',
            visaTime: '22K+ Visas on Time',
            date: 'January 13, 2024 5:3:1',
            liveDate:'May 6, 2024 6:0:0',
             category: 'Music',
            
        }, 
]
export const insuranceDetails = [
     {
        id:1,
        image: 'https://www.emory.edu/home/_includes/images/sections/admission/4.4/_0002_0050801-16bm-raw-f049-gobal-thinkers.jpg',
        student: 'Bupa is a well-known international healthcare group that provides health insurance and healthcare services. If Niva Bupa Health Insurance is part of this collaboration, it may offer a range of health insurance plans with coverage for medical expenses, hospitalization, and other healthcare-related costs.',
        title: 'Niva Bupa',
        insurance:'Student health cover',
        rating:'4',
        location:'Kochi, India',
        logo:'https://bsmedia.business-standard.com/_media/bs/img/article/2023-11/20/full/1700468622-5944.jpg',
        avatar: image1,
        name:"Steven Townsend",
        date: 'December 29, 2023 6:0:0',
        liveDate:'September 13, 2024 6:0:0',
        category: 'Games',
        visaTime: '53K+ Visas on Time',
        price: '₹11,200',
        flights: '39 Direct flights from ₹20.3k',
        lat:'-32.95884568135589',
        long:'-60.68852409880411',
        
        },
        {
            id:2,
            image: 'https://navi.com/blog/wp-content/uploads/2022/07/Travel-Health-Insurance.jpg',
            student: 'National Insurance is a social security and welfare program that operates in various countries, providing financial protection and benefits to individuals in times of need. It is typically a government-run system designed to offer financial assistance during specific life events.',
            title: 'Allianz',
            insurance:'Visitor health cover',
            rating:'3',
            location:'Rio de Janeiro, Brazil',
            logo:'https://toppng.com/uploads/preview/allianz-logo-3d-allianz-logo-11563352755xfxw08gyfz.png',
            avatar: image2,
            price: '₹16,200',
            flights: '21 Direct flights from ₹11k',
            visaTime: '24K+ Visas on Time',
            name:"Tiffany Betancourt",
            liveDate:'November 29, 2024 6:0:0',
             category: 'Games',
             lat:'-32.95884568135589',
        long:'-60.68852409880411',
            
        },
        {
            id:3,
            image: 'https://images.moneycontrol.com/static-mcnews/2022/09/Health-insurance-770x433.png?impolicy=website&width=770&height=431',
            student: 'The New India Assurance Company Limited, commonly known as New India Assurance, is one of the largest and oldest insurance companies in India. Established in 1919, the company has a rich history of providing a wide range of insurance products and services to individuals, businesses, and organizations.',
            title: 'Medibank',
            insurance:'Work health cover',
            rating:'3.8',
            location:'Tokyo, Japan',
            logo:'https://www.medibank.com.au/content/dam/medibank/custom-campaigns/Digital/medibank_logo_red.jpg',
            avatar: image3,
            price: '₹8,000',
            flights: '29 Direct flights from ₹17.3k',
            visaTime: '27K+ Visas on Time',
            name:"Jacqueline Burns",
            liveDate:'March 13, 2024 6:0:0',
            category: 'Art',
            lat:'-32.95884568135589',
        long:'-60.68852409880411',
            
        }, {
            id:4,
            image: 'https://www.providentins.com/wp-content/uploads/2022/05/Provident_CriticalIllnessInsurance2.jpg',
            student: "The Oriental Insurance Company Limited, commonly known as The Oriental Insurance, is a prominent general insurance company based in India. Established on September 12, 1947, it is one of the oldest and largest insurance companies in the country. The company was nationalized in 1973 as part of the government's initiative to consolidate the insurance sector.",
            title: 'HBF',
            insurance:'Student health cover',
            rating:'4.6',
            location:'Paris, France',
            logo:'https://smilesincurrambine.com.au/wp-content/uploads/2023/07/hbf.png',
            avatar: image4,
            name:"Mari Harrington",
            price: '₹5,000',
            flights: '27 Direct flights from ₹18.3k',
            visaTime: '22K+ Visas on Time',
            date: 'January 13, 2024 5:3:1',
            liveDate:'May 6, 2024 6:0:0',
             category: 'Music',
             lat:'-32.95884568135589',
        long:'-60.68852409880411',
            
        }, 
]
export const loan = [
     {
        id:1,
        image: 'https://media.assettype.com/fortuneindia/2022-09/f7209055-4998-4bff-a050-da4a84c80d0d/SBI_09131_copy.jpg?w=700',
        student: 'Bupa is a well-known international healthcare group that provides health insurance and healthcare services. If Niva Bupa Health Insurance is part of this collaboration, it may offer a range of health insurance plans with coverage for medical expenses, hospitalization, and other healthcare-related costs.',
        title: 'SBI',
        loan:'Education loan',
        rating:'3.8',
        location:'Mumbai, India',
        logo:'https://logowik.com/content/uploads/images/sbi-state-bank-of-india9251.logowik.com.webp',
        avatar: image1,
        name:"Steven Townsend",
        date: 'December 29, 2023 6:0:0',
        liveDate:'September 13, 2024 6:0:0',
        category: 'Games',
        visaTime: '53K+ Visas on Time',
        price: '₹11,200',
        flights: '39 Direct flights from ₹20.3k',
        
        },
        {
            id:2,
            image: 'https://content.fortune.com/wp-content/uploads/2023/05/F500-2023-Bank-of-America-GettyImages-1251913638.jpg',
            student: 'National Insurance is a social security and welfare program that operates in various countries, providing financial protection and benefits to individuals in times of need. It is typically a government-run system designed to offer financial assistance during specific life events.',
            title: 'Bank of America',
            loan:'Vehicle loan',
            rating:'4.6',
            location:'California, USA',
            logo:'https://icons.veryicon.com/png/o/business/bank-logo-collection/bank-of-america-logo.png',
            avatar: image2,
            price: '₹16,200',
            flights: '21 Direct flights from ₹11k',
            visaTime: '24K+ Visas on Time',
            name:"Tiffany Betancourt",
            liveDate:'November 29, 2024 6:0:0',
             category: 'Games',
            
        },
        {
            id:3,
            image: 'https://static01.nyt.com/images/2023/05/03/multimedia/00hsbc-twvm/00hsbc-twvm-facebookJumbo.jpg',
            student: 'The New India Assurance Company Limited, commonly known as New India Assurance, is one of the largest and oldest insurance companies in India. Established in 1919, the company has a rich history of providing a wide range of insurance products and services to individuals, businesses, and organizations.',
            title: 'HSBC Bank',
            loan:'Personal loan',
            rating:'2.8',
            location:'England, UK',
            logo:'https://live.staticflickr.com/8321/7975802296_a5ed97e46c_b.jpg',
            avatar: image3,
            price: '₹8,000',
            flights: '29 Direct flights from ₹17.3k',
            visaTime: '27K+ Visas on Time',
            name:"Jacqueline Burns",
            liveDate:'March 13, 2024 6:0:0',
            category: 'Art',
            
        }, {
            id:4,
            image: 'https://cloudfront-us-east-2.images.arcpublishing.com/reuters/FT7HVGBBPFJ73CLUK5ZRQLK55E.jpg',
            student: "The Oriental Insurance Company Limited, commonly known as The Oriental Insurance, is a prominent general insurance company based in India. Established on September 12, 1947, it is one of the oldest and largest insurance companies in the country. The company was nationalized in 1973 as part of the government's initiative to consolidate the insurance sector.",
            title: 'ANZ Bank',
            loan:'International student loan',
            rating:'5',
            location:'Victoria, Australia',
            logo:'https://1000logos.net/wp-content/uploads/2018/08/anz-bank-logo.jpg',
            avatar: image4,
            name:"Mari Harrington",
            price: '₹5,000',
            flights: '27 Direct flights from ₹18.3k',
            visaTime: '22K+ Visas on Time',
            date: 'January 13, 2024 5:3:1',
            liveDate:'May 6, 2024 6:0:0',
             category: 'Music',
            
        }, 
]
export const loanDetails = [
     {
        id:1,
        image: 'https://media.assettype.com/fortuneindia/2022-09/f7209055-4998-4bff-a050-da4a84c80d0d/SBI_09131_copy.jpg?w=700',
        student: 'Bupa is a well-known international healthcare group that provides health insurance and healthcare services. If Niva Bupa Health Insurance is part of this collaboration, it may offer a range of health insurance plans with coverage for medical expenses, hospitalization, and other healthcare-related costs.',
        title: 'SBI',
        loan:'Education loan',
        rating:'3.8',
        location:'Mumbai, India',
        logo:'https://logowik.com/content/uploads/images/sbi-state-bank-of-india9251.logowik.com.webp',
        avatar: image1,
        name:"Steven Townsend",
        date: 'December 29, 2023 6:0:0',
        liveDate:'September 13, 2024 6:0:0',
        category: 'Games',
        visaTime: '53K+ Visas on Time',
        price: '₹11,200',
        flights: '39 Direct flights from ₹20.3k',
        
        },
        {
            id:2,
            image: 'https://content.fortune.com/wp-content/uploads/2023/05/F500-2023-Bank-of-America-GettyImages-1251913638.jpg',
            student: 'National Insurance is a social security and welfare program that operates in various countries, providing financial protection and benefits to individuals in times of need. It is typically a government-run system designed to offer financial assistance during specific life events.',
            title: 'Bank of America',
            loan:'Vehicle loan',
            rating:'4.6',
            location:'California, USA',
            logo:'https://icons.veryicon.com/png/o/business/bank-logo-collection/bank-of-america-logo.png',
            avatar: image2,
            price: '₹16,200',
            flights: '21 Direct flights from ₹11k',
            visaTime: '24K+ Visas on Time',
            name:"Tiffany Betancourt",
            liveDate:'November 29, 2024 6:0:0',
             category: 'Games',
            
        },
        {
            id:3,
            image: 'https://static01.nyt.com/images/2023/05/03/multimedia/00hsbc-twvm/00hsbc-twvm-facebookJumbo.jpg',
            student: 'The New India Assurance Company Limited, commonly known as New India Assurance, is one of the largest and oldest insurance companies in India. Established in 1919, the company has a rich history of providing a wide range of insurance products and services to individuals, businesses, and organizations.',
            title: 'HSBC Bank',
            loan:'Personal loan',
            rating:'2.8',
            location:'England, UK',
            logo:'https://live.staticflickr.com/8321/7975802296_a5ed97e46c_b.jpg',
            avatar: image3,
            price: '₹8,000',
            flights: '29 Direct flights from ₹17.3k',
            visaTime: '27K+ Visas on Time',
            name:"Jacqueline Burns",
            liveDate:'March 13, 2024 6:0:0',
            category: 'Art',
            
        }, {
            id:4,
            image: 'https://cloudfront-us-east-2.images.arcpublishing.com/reuters/FT7HVGBBPFJ73CLUK5ZRQLK55E.jpg',
            student: "The Oriental Insurance Company Limited, commonly known as The Oriental Insurance, is a prominent general insurance company based in India. Established on September 12, 1947, it is one of the oldest and largest insurance companies in the country. The company was nationalized in 1973 as part of the government's initiative to consolidate the insurance sector.",
            title: 'ANZ Bank',
            loan:'International student loan',
            rating:'5',
            location:'Victoria, Australia',
            logo:'https://1000logos.net/wp-content/uploads/2018/08/anz-bank-logo.jpg',
            avatar: image4,
            name:"Mari Harrington",
            price: '₹5,000',
            flights: '27 Direct flights from ₹18.3k',
            visaTime: '22K+ Visas on Time',
            date: 'January 13, 2024 5:3:1',
            liveDate:'May 6, 2024 6:0:0',
             category: 'Music',
            
        }, 
]

export const dataArray = [
     {
        name: 'Cleopatra',
        id:1,
        image: 'https://pixinvent.com/materialize-material-design-admin-template/app-assets/images/user/12.jpg',        
        location: 'Cairo International Film Festival',
        from:'Canada',
        heading: 'Smooth and Trouble-free experience',       
        content:"Visa Search app made my Egypt visa application a breeze. The whole process was simplified and the service was super quick! Constant updates saved me from anxieties. If you're looking for a seamless experience for your visa application, Visa Search is the one to go for!",
                  
        },
        {
            name: 'Liam',
            id:2,
            image: 'https://htmlstream.com/preview/unify-v2.6.2/assets/img-temp/400x450/img5.jpg',
            from:'USA',
            location: 'Egypt Open (Squash)',
            heading: 'Reliable and Trustworthy Service',
            content: 'The professionalism and expertise of Visa Search in handling visa applications are praiseworthy. While applying for my Egypt visa, they were efficient and considerate, giving me real-time updates about the process. A magnificent app for all visa needs, I highly recommend their services.',
                      
        },
        {
            name: 'Oliver',
            id:3,
            image: 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
            location: 'Egyptian Table Tennis Championship',
            heading: 'Efficient and Simple App for Visa',
            from:'Germany',
            content: 'I was stuck applying for my Egypt visa when I stumbled upon Visa Search. The interface is simple and the process, clear. I appreciate the continuous communication from their end about the status of my application. I recommend Visa Search for a hassle-free and reliable service.',
            
        }, {
            name: 'William',
            id:4,
            image: 'https://rimage.gnst.jp/livejapan.com/public/article/detail/a/00/04/a0004213/img/basic/a0004213_main.jpg?20200710184501&q=80&rw=750&rh=536',
            location: 'Cairo ICT',
            from:'Morocco',
            heading: 'Efficient and Reliable Visa Application',
            content: "Had a wonderful experience using Visa Search for my Egypt Visa. The team was extremely professional and prompt. The app's user-friendly interface made the application process far easier than I had anticipated. Visa Search has my highest recommendation!'",
            
        }, 

]
export const images = [item13,item3,item4,item1,image12,item6, item14, item13,item5, item7, item8, item5, item15, item9, item12, item10, item3, item11, item16 ,item17, item18, item7, item9, item19, item22, item23, item24, item25, item19, item26, item27, item24, item28, item7, item14, item19  ]

export  const blogData = [
    {
        id:1,
        title: 'Mindfulness Activities for Kids & Toddlers with NFT',
        image: blog1,
        category: 'Arts',
        subtext: '@StreetBoy',
        date:"13th September,2023",
    },
    {
        id:2,
        title: 'Save Thousands Of Lives Through This NFT',
        image: blog2,
        category: 'Illustration',
        subtext: '@CutieGirl',
        date:"29th November, 2023"
    },
    {
        id:3,
        title: 'A place where technology meets craftsmanship',
        image: blog3,
        category: 'Music',
        subtext: '@ButterFly',
        date: "29th December, 2023"
    },
    {
        id:4,
        title: 'NFT Market - A Compact Trike with the Big Benefits',
        image: blog4,
        category: 'Video',
        subtext: '@NorseQueen',
        date:'13th March, 2023',
    },
    {
        id:5,
        title: 'Honoring Black History Month with Toddlers',
        image: blog5,
        category: 'Games',
        subtext: '@@BigBull',
        date:"6th June, 2023"
    },
    {
        id:6,
        title: 'Setting Intentions Instead of Resolutions for 2021',
        image: blog6,
        category: 'Memes',
        subtext: '@Angel',
        date:'19th July, 2023'
    },
    {
        id:7,
        title: 'Clever Ways to Purchase Extraordinart Items',
        image: blog7,
        category: 'GIFs',
        subtext: '@CrazyAnyone',
        date:"20th July, 2023"
    },
    {
        id:8,
        title: 'How to Save Money on Baby Essentials for NFT',
        image: blog8,
        category: 'Video',
        subtext: '@Princess',
        date:"31st August, 2023"
    },
    {
        id:9,
        title: 'Liki Trike - A Compact Trike with the Big Benefits',
        image: blog9,
        category: 'GIFs',
        subtext: '@CrazyAnyone',
        date:"13th September,2023",
    },
    {
        id:10,
        title: 'NFT Market - A Compact the Big Benefits',
        image: blog10,
        category: 'Tech',
        subtext: '@Princess',
        date:"29th November, 2023"
    },
    {
        id:11,
        title: 'Behind the Scenes of the creabik App',
        image: blog11,
        category: 'Arts',
        subtext: '@PandaOne',
        date:'13th March, 2023',
    },
    {
        id:12,
        title: 'Meet fennouni, Product Designer at GitHub',
        image: blog12,
        category: 'GIFs',
        subtext: '@FunnyGuy',
        date:'19th July, 2023'
    }
]