import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Select from 'react-select'
import Switcher from '../../components/switcher';
import { Icon } from '@iconify/react';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import { Link, useParams } from 'react-router-dom';
import { prpathway } from '../../data/data';
import { LuClock, LuCalendarDays, RiDragDropLine, LuSearch, AiOutlineAppstore, AiOutlineStar, MdKeyboardArrowLeft, MdKeyboardArrowRight, PiBrowsersLight, MdOutlineSportsVolleyball, AiOutlinePieChart, LuMusic4, TbCameraPlus, PiRocketLaunchBold, FaImages, ImImages, BiImage, BiImages, RiArrowDropDownLine, MdOutlineArrowForward } from "../../assets/icons/vander"
import PrPathwaySameCountryListing from './pr-pathway-same-country';
import axios from 'axios';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import Error from '../special/error';
import { DNA } from 'react-loader-spinner';


export default function PrPathwayListing({ activeIndex, prSelected ,refresh}) {
  const { tab, country } = useParams();
  const [prCountry, setPrCountry] = useState([])
  const [prData, setPRData] = useState([])
  const [selectCountry, setSelectCountry] = useState({})
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [list, setList] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchPRCountries = () => {
    axios.post(`${REST_API_END_POINT}pr_pathway/get-all-pr`,{from_user:true})
      .then((res) => {
        if (res.data.status === 1) {
          setPrCountry(res.data.data?.filter(d => d.status == 1))
        }
        else{
          setPrCountry([])
        }
      })
  }
  useEffect(() => {
    setPrCountry([prSelected])
  }, [prSelected])

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    fetchPRCountries()
    return () => clearTimeout(timer);
  }, [refresh])

  const fetchPR_by_coutryId = async (id) => {
    let val = prCountry.find(row => row.id === id)
    setSelectCountry(val)
    ////////////////
    axios.post(`${REST_API_END_POINT}pr-country/get-list`, { id })
      .then((res) => {
        if (res.data.status === 1) {
          setPRData(res.data.data?.filter(d => d.status == 1))
          setList(true)
        }
      })
  }


  return (
    <>
      {!list ? 
       loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{}}
         wrapperClass="dna-wrapper"
           />
        </div>
      ) : (
      <section className="relative mb-6" style={{ marginTop: '30px' }}>
      {prCountry?.length > 0 ?
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]" style={{ padding: '20px' }}>
          {
            prCountry?.map((ele, index) => (
              <Link key={index} onClick={() => { ele.verified === 1 && fetchPR_by_coutryId(ele.id) }} style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', alignContent: 'stretch' }}>
                <div key={index} className="group relative overflow-hidden shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-100 " style={{ borderRadius: '20px', }}>
                  <img style={{ maxWidth: '100%', height: '300px',width:'600px', objectFit: 'cover' }} src={ele.image} alt={ele.name} />

                  <div className="relative p-6">

                    <div>
                      <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start' }}>
                        <div className="color-option">
                          <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <div className="color-option1" >
                                                    <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Link
                                                             className="color"  data-ripple-light="true"
                                                             data-tooltip-target="tooltip" style={{ fontWeight: 'bold', fontSize: '21px', color: '#333333', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '10px', marginBottom: '6px' }} >
                                                                          
                                                                           {ele.name}  </Link>
                                                                           <div
                                                                data-tooltip="tooltip"
                                                             class="tooltip"
                                                               >
                                                         {ele.name} 
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                          </div>
                          </div>
                        </div>

                        <div className="color-price" style={{ marginTop: '20px', marginLeft: '-20px' }}>
                          <div className="flex items-center">
                            <Link
                              style={{
                                color: '#000000',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                cursor: 'pointer',
                                marginLeft: '15px',
                                paddingBottom: '3px',
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              className='under'>
                              Check PR's
                              <MdOutlineArrowForward color='#000' style={{ marginLeft: '5px' }} />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
              </Link>
            ))
          }
        </div>
        : <Error />}
        {/* <div className="container mt-8" style={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                        <div className="flex items-center">
                          <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',}}>
                         Load More
                          </Link>
                          <div style={{marginTop:'17px',fontSize:'40px',color:'#000000',cursor:'pointer' }} >
                          <RiArrowDropDownLine  style={{color:'#000000'}}/>
                          </div>
                        </div> 
                    </div>   */}
      </section>
      )
        :
        <PrPathwaySameCountryListing activeIndex={2} selectCountry={selectCountry} setList={setList} data={prData} />
      }
    </>
  )
}
