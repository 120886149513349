import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import OTPInput from 'react-otp-input';
import { REST_API } from '../../constants/DefaultValue';
import Footer from '../../components/footer';

export default function ResetPassword() {
    const [emailOTP, setEmailOTP] = useState(null);
    const [otpVerify, setOtpVerify] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [otp, setOtp] = useState('');
    const [emailData, setEmailData] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleForgetPassword =async (event) => {
        event.preventDefault();
        const emailInput = document.querySelector('input[name="email"]');
        const email = emailInput ? emailInput.value.trim() : emailData;

        if (!email) {
            toast.error("Please enter your email address");
            return;
        }

        // console.log("Email:", email);
        await axios.post(`${REST_API}resetPassword-sent-otp`, { email })
            .then(res => {
                if (res.data.status === 1) {
                    toast.success(` OTP has been sent to ${email}`)
                    // console.log("Response:", res.data);
                    setEmailOTP(res.data.otp);
                    setOtpVerify(true);
                    setEmailData(email);
                } else {
                    toast.error("Failed to get OTP");
                }
            })
            .catch(err => console.log(err));
    };

    const handleOtpSet = () => {
       
        if (otp == emailOTP) {
            toast.success("OTP is verified");
            setChangePassword(true)
            setOtp('');

        } else {
            toast.error("Incorrect OTP. Please try again.");
            setOtp('');
        }
    };

    const backtoEmail = () => {
        setOtpVerify(false);
        setChangePassword(false);
        setOtp('');
    };
    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
      };

    const  handleChangepassword=async()=>{
        const newPasswordInput = document.querySelector('input[name="new-password"]');
        const newPassword = newPasswordInput ? newPasswordInput.value.trim() : newPasswordInput;
        const confirmPasswordInput = document.querySelector('input[name="confirm-password"]');
        const password = confirmPasswordInput ? confirmPasswordInput.value.trim() : confirmPasswordInput;
        const values={password}
        if (newPassword!==password) {
            toast.error("Passwords do not match");
            return;
        }
        if (!newPassword || !password) {
            toast.error("Please fill out all required fields.");
            return;
        }
        if (newPassword.length < 6) {
            toast.error("Password must be at least 6 characters long.");
            return;
        }
        try{
           const res= await axios.post(`${REST_API}resetPassword`,{email:emailData,values})
        //    console.log("log",res.data);
           if (res.data.status === 1) {
            toast.success("Password updated successfully");
            navigate('/signup-visa/visa');
        } else {
            toast.error("Failed to update password");
        }
        }
    catch (err) {
        console.log(err);
        toast.error("An error occurred. Please try again.");
    }
      }
    return (
        <>
       
            <section className="md:h-screen py-36 flex items-center bg-no-repeat bg-center">
                <div className="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden">
                    {/* Background video or image */}
                </div>
                <div className="container">
                    <div className="flex justify-center">
                        <div className="w-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md" style={{ maxWidth: '600px' }}>
                        {/* <div className="w-full m-auto p-6 bg-white   rounded-md" style={{ maxWidth: '600px' }}> */}
                            {!otpVerify ? (
                                <div>
                                    <h5 className="mb-2 font-semibold" style={{ fontSize: '26px', color: 'black' }}>Forgot your password?</h5>
                                    <div className="grid">
                                        <p className="text-slate-400 mb-6">Please enter the email address associated with your account and we will email you a link to reset your password.</p>
                                        <div className="grid">
                                            <div style={{ display: "flex", justifyContent: 'center', width: '100%' }}>
                                                <div className="input-field-verify" style={{ width: '90%', }}>
                                                    <input type="email" placeholder="Email address" name="email" style={{ marginLeft: '10px', width: '95%' }} />
                                                </div>
                                            </div>
                                            <div className="mb-4" style={{ display: "flex", justifyContent: 'center', fontSize: '14px' }}>
                                                <button onClick={handleForgetPassword} className="btnnVerify">Reset Password</button>
                                            </div>
                                            <div className="text-center">
                                                <span className="text-slate-400 me-2">Remember your password? </span>
                                                <Link to="/signup-visa/visa" className="under1 text-black">Sign in</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : !changePassword && otpVerify &&(
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                    <div className="grid" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                        <h5 className="mb-2 font-semibold" style={{ fontSize: '26px', color: 'black', alignContent: 'center' }}>Request sent successfully</h5>
                                        <p className="text-slate-400 mb-6">We have sent a confirmation email to <span style={{ fontWeight: 'bold' }}>{emailData}</span> please check your email.</p>
                                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                                            <OTPInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={6}
                                                placeholder={<span>-</span>}
                                                renderInput={(props) => <input {...props}
                                                    className='OTP'
                                                    style={{ width: '65px', height: '65px', display: 'flex', textAlign: 'center', justifyContent: 'center', borderRadius: '7px', color: '#000', marginRight: '15px', }}
                                                />}
                                            />
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px', color: 'black' }}>
                                            <div>
                                                <p>A one-time authentication code has been sent</p>
                                                <button onClick={handleOtpSet} className="btnnVerify" style={{ marginTop: '18px' }}>Verify</button>
                                            </div>
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px', color: 'black' }}>
                                            <div>
                                                <p>Don’t have a code? <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={handleForgetPassword}>Resend code</span> </p>
                                                <button className="btnn" style={{ borderRadius: '10px', marginTop: '15px' }} onClick={backtoEmail}>Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {changePassword && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                <div className="grid" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                    <h5 className="mb-2 font-semibold" style={{ fontSize: '26px', color: 'black', alignContent: 'center' }}>Reset your password?</h5>
                                    <p className="text-slate-400 mb-6">Please enter the new password associated with your Visa Account</p>
                                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                                       
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px', color: 'black' }}>
                                        <div>
                                        <div className="input-field-verify">
                                            
                                          <input type={showPassword ? "text" : "password"} placeholder="Enter New Password" name="new-password" style={{marginLeft:'14px'}}/>
                                          <span className="password-toggle" onClick={togglePasswordVisibility} style={{marginRight:'14px',marginLeft:'14px'}}>
                                              {showPassword ? <i style={{fontSize:'1.2rem',marginTop:'6px'}} className="el--eye-open"></i> : <i style={{fontSize:'1.4rem',marginTop:'6px'}} className="basil--eye-closed-solid"></i>}
                                          </span>
                                         </div>
                                         <div className="input-field-verify">
                                            
                                          <input type={showPassword ? "text" : "password"} placeholder="Confirm Password" name="confirm-password" style={{marginLeft:'14px'}} />
                                          <span className="password-toggle" onClick={togglePasswordVisibility} style={{marginRight:'14px',marginLeft:'14px'}}>
                                              {showPassword ? <i style={{fontSize:'1.2rem',marginTop:'6px'}} className="el--eye-open"></i> : <i style={{fontSize:'1.4rem',marginTop:'6px'}} className="basil--eye-closed-solid"></i>}
                                          </span>
                                         </div>
                                            <button onClick={handleChangepassword} className="btnnVerify" style={{ marginTop: '18px' }}>Update Password</button>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px', color: 'black' }}>
                                        <div>
                                            {/* <p>Don’t have a code? <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={handleForgetPassword}>Resend code</span> </p> */}
                                            <button className="btnn" style={{ borderRadius: '10px', marginTop: '15px' }} onClick={backtoEmail}>Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                rtl={false}
                draggable
                theme="light"
            />
        </>
    );
}



// export function getEmailOTP() {
//     return EmailOTP;
// }

// import React from 'react'
// import logo_dark from '../../assets/images/logo-dark.png';
// import logo_light from '../../assets/images/logo-light.png';
// import { Link } from "react-router-dom";
// import Switcher from '../../components/switcher';

// export default function ResetPassword() {
//     return (
//         <>
//             <section className="md:h-screen py-36 flex items-center bg-[url('../../assets/images/cta.jpg')] bg-no-repeat bg-center">
//                 <div className="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden">
//                     {/* <iframe title="iframe" src="https://player.vimeo.com/video/502163294?background=1&autoplay=1&loop=1&byline=0&title=0" className="absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw]"></iframe> */}
//                 </div>
//                 <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
//                 <div className="container">
//                     <div className="flex justify-center">
//                         <div className="max-w-[400px] w-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
//                             <Link to="#">
//                                 <img src={logo_dark} className="mx-auto h-7 block dark:hidden" alt="" />
//                                 <img src={logo_light} className="mx-auto h-7 dark:block hidden" alt="" />
//                             </Link>
//                             <h5 className="my-6 text-xl font-semibold">Reset Your Password</h5>
//                             <div className="grid grid-cols-1">
//                                 <p className="text-slate-400 mb-6">Please enter your email address. You will receive a link to create a new password via email.</p>
//                                 <form className="text-start">
//                                     <div className="grid grid-cols-1">
//                                         <div className="mb-4">
//                                             <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label>
//                                             <input id="LoginEmail" type="email" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="name@example.com" />
//                                         </div>

//                                         <div className="mb-4">
//                                             <input type="submit" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full w-full" value="Send" />
//                                         </div>

//                                         <div className="text-center">
//                                             <span className="text-slate-400 me-2">Remember your password ? </span><Link to="/login" className="text-black dark:text-white font-bold">Sign in</Link>
//                                         </div>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <Switcher />
//         </>
//     )
// }
