import React, { useState } from 'react';
import { BsGoogle } from "../../assets/icons/vander";
import NavbarNew from '../../components/navbar-new';
import Footer from '../../components/footer';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ADMIN_DASH_URL, REST_API, REST_API_END_POINT } from '../../constants/DefaultValue';
import ResetPassword from './reset-password';


export default function VisaSignup() {
  const navigate = useNavigate()
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleMode = () => {
    setIsSignUpMode((prevMode) => !prevMode);
  };

  const handleSignInClick = (event) => {
    event.preventDefault();
    const username = event.target.querySelector('input[type="text"]').value;
    const password = event.target.querySelector('.password-input').value;
    if(!username||!password){
      toast.error("Choose Email and Password")
      return 
    }
      axios.post(`${REST_API}login`,{username, password})
      .then((res)=>{
        if(res.data.status===1){
         if(res.data.userType===3){
              navigate('/visa') 
              if(res.data.accessToken){ 
              let user = res.data.user
              localStorage.setItem('user_id', user.user_id); 
              localStorage.setItem('user_type', user.user_type);
              localStorage.setItem('userVisa',JSON.stringify(user))
              }
           } 
        }
      })
      .catch((error)=>{
        console.log(error)
        toast.error( "Invalid Email or Password")
      })
  };

  const handleSignUpClick =(event) => {
    event.preventDefault();
     const first_name = event.target.querySelector('input[name="first_name"]').value;
     const last_name = event.target.querySelector('input[name="last_name"]').value;
    const email = event.target.querySelector('input[name="email"]').value.trim();
    const password = event.target.querySelector('input[name="password"]').value.trim();

    // console.log("Signup Details:", { email, password });
     axios.post(`${REST_API}sign-up`,{ email, password,first_name,last_name})
     .then((res)=>{
      if(res.data.status===1){
        toast.success(res.data.message)
        toggleMode()
      }else{
        toast.error(res.data.message)
      }
     })
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <NavbarNew tab={useParams().tab}/>
      <section className="md:h-screen py-36 flex items-center bg-no-repeat bg-center bg-cover" id='bodyy'>
        <div className={`containerre ${isSignUpMode ? 'sign-up-mode' : ''}`}>
          <div className="signin-signup">
            <form onSubmit={handleSignInClick} className="sign-in-form">
              <h2 className="title">Sign in</h2>
              <div className="input-field">
                <i className="fas fa-user"></i>
                <input type="text" placeholder="Email" />
              </div>
              <div className="input-field">
                <i className="fas fa-lock"></i>
                <input className="password-input"  type={showPassword ? "text" : "password"} placeholder="Password" />
                <span className="password-toggle" onClick={togglePasswordVisibility} style={{marginRight:'14px',}}>
                  {showPassword ? <i style={{fontSize:'1.2rem',marginTop:'6px'}} className="el--eye-open">

                  </i> : <i style={{fontSize:'1.4rem',marginTop:'6px'}} className="basil--eye-closed-solid"></i>}
                </span>
              </div>
                <div style={{display:"flex",justifyContent:'end',width:"90%"}}>
                  <a  style={{color:'red',fontSize:'15px'}} href='/reset-password'>forgot password?</a>
                  </div>
              <input type="submit" value="Login" className="btnn" />
              <p className="account-text" style={{ color: '#000',marginTop:'10px' }}>
                Don't have an account? <a href="#" id="sign-up-btn2" className='under1' onClick={toggleMode}>Sign up</a>
              </p>
            </form>
            <form onSubmit={handleSignUpClick} className="sign-up-form">
              <h2 className="title">Sign up</h2>
              <div className="input-field">
                <i className="fas fa-user"></i>
                <input type="text" placeholder="First name" name="first_name" />
              </div>
              <div className="input-field">
                <i className="fas fa-user"></i>
                <input type="text" placeholder="Last name" name="last_name" />
              </div>
              <div className="input-field">
                <i className="fas fa-envelope"></i>
                <input type="text" placeholder="Email" name="email" />
              </div>
              <div className="input-field">
                <i className="fas fa-lock"></i>
                <input type={showPassword ? "text" : "password"} placeholder="Password" name="password" />
                <span className="password-toggle" onClick={togglePasswordVisibility} style={{marginRight:'14px',}}>
                  {showPassword ? <i style={{fontSize:'1.2rem',marginTop:'6px'}} className="el--eye-open"></i> : <i style={{fontSize:'1.4rem',marginTop:'6px'}} className="basil--eye-closed-solid"></i>}
                </span>
              </div>
              <input type="submit" value="Sign up" className="btnn" />
              <p className="account-text" style={{ color: '#000',marginTop:'10px' }}>
              Do you have an account? <a href="#" id="sign-up-btn2" className='under1' onClick={toggleMode}>Sign in</a>
              </p>
              
            </form>
          </div>
          <div className="panels-container">
            <div className="panel left-panel">
              <div className="content">
                <h3>Sign In</h3>
                <p>Click here to log in to Visa Search</p>
                <button className="btnn" onClick={toggleMode} id="sign-in-btn">
                  Sign in
                </button>
              </div>
            </div>
            <div className="panel right-panel">
              <div className="content">
                <h3>Sign Up</h3>
                <p>Click here to signup to Visa Search</p>
                <button className="btnn" onClick={toggleMode} id="sign-up-btn">
                  Sign up
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      rtl={false}
      draggable
      theme="light"
       />
      <Footer />
     
    </>
  );
}