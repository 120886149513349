import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Select from 'react-select'
import Switcher from '../../components/switcher';
import { Icon } from '@iconify/react';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import { Link, useNavigate } from 'react-router-dom';
import { language } from '../../data/data';
import star from '../../assets/images/rating-star.png';
import { LuClock, LuCalendarDays, RiDragDropLine, LuSearch, AiOutlineAppstore, AiOutlineStar, MdKeyboardArrowLeft, MdKeyboardArrowRight, PiBrowsersLight, MdOutlineSportsVolleyball, AiOutlinePieChart, LuMusic4, TbCameraPlus, PiRocketLaunchBold, FaImages, ImImages, BiImage, BiImages, RiArrowDropDownLine } from "../../assets/icons/vander"
import axios from 'axios';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import Error from '../special/error';
import { DNA } from 'react-loader-spinner';


const options = [
  { value: 'Art', label: 'Art' },
  { value: 'Domain', label: 'Domain Names' },
  { value: 'Music', label: 'Music' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Trading', label: 'Trading Cards' },
  { value: 'Virtual', label: 'Virtual World' },
];

const buyOptions = [
  { value: 'now', label: 'Buy Now' },
  { value: 'auctions', label: 'Auctions' },
  { value: 'offers', label: 'Offers' }
]
const rateOptions = [
  { value: 'top', label: 'Top Rated' },
  { value: 'low', label: 'Lower Rated' }
]

const serviceFilters = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
  13: '13',
  14: '14',
  15: '15',
  16: '16'
};



// const serviceFilters = {};
// for (let i = 1; i <= 16; i++) {
//   serviceFilters[i] = i.toString();
// }

export default function LanguagesListing({ activeIndex, languageSelect,refresh,countryPrefer,activIndexLanguage}) {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);

  const fetchAllLanguages = () => {
    axios.post(`${REST_API_END_POINT}language/language-list`, {from_user:true})
      .then((res) => {
        if (res.data.status === 1) {
          console.log("filteredDatafilteredData",res.data.data);
          console.log("filteredDatafilteredData",activIndexLanguage);
          let filteredData = res.data.data
          
          .filter(d => d.status === 1)
         .filter(d => d.location?.toLowerCase().includes(countryPrefer.name.toLowerCase()));
         if (activIndexLanguage in serviceFilters) {
          console.log("serviceFilters[activIndexLanguage]",serviceFilters[activIndexLanguage]);
          
          const languageId = serviceFilters[activIndexLanguage];
          filteredData = filteredData.filter((d) =>
            d.languages?.split(',').includes(languageId)
          );
          console.log(`Filtered by Service ID ${languageId}:`, filteredData);
        }
          setData(filteredData);
        }
        else{
          setData([])
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    fetchAllLanguages()
    return () => clearTimeout(timer);
  }, [refresh,countryPrefer,activIndexLanguage])

  useEffect(() => {
    setData([languageSelect])
  }, [languageSelect])

  return (
    <>
     {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{}}
         wrapperClass="dna-wrapper"
           />
        </div>
      ) : (
      <section className="relative mb-6" style={{ marginTop: '30px' }}>
      {data?.length > 0 ?
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]" style={{ padding: '20px' }}>
          {
            data.map((ele, index) => (
              <Link
                onClick={() => {
                  ele.detail_added === 1 && ele.verified ===1 &&
                    ele.status ===1 && navigate(`/languages-details/${ele.id}/${activeIndex}`)

                }}
                style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', alignContent: 'stretch' }}>
                <div key={index} className="group relative overflow-hidden shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-100 " style={{ borderRadius: '20px', }}>
                  <img style={{ width: '600px', height: '300px', objectFit: 'cover' }} src={ele.cover_image} alt={ele.name} />

                  <div className="relative p-6">
                    {/* <div className="absolute start-6 -top-4">
                      <span className="bg-violet-600 text-white text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">{ele.category}</span>
                    </div> */}
                    <div>
                      {/* <div className="flex mb-4">
                        <span className="text-slate-400 text-[16px] inline-flex items-center">{ele.date}</span>
                        <span className="text-slate-400 text-[16px] ms-3 inline-flex items-center">5 min read</span>
                      </div> */}



                      <div className="color-price" style={{ marginTop: '-10px', marginLeft: '-10px' }}>
                        {/* <div className="color-option1" >
                          <Link onClick={() => {
                            ele.detail_added === 1 && ele.status == 1 &&
                              navigate(`/languages-details/${ele.id}/${activeIndex}`)
                          }}
                            style={{ fontWeight: 'bold', fontSize: '21px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center',  maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} class="color">{ele.name}</Link>
                        </div> */}
                         <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start' }}>
                            <div className="color-option">
                              <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Link
                                  data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                                  onClick={() => {
                                    ele.detail_added === 1 &&ele.verified ===1 && ele.status == 1 && navigate(`/languages-details/${ele.id}/${activeIndex}`)
                                  }}
                                  className="color"
                                  style={{ fontWeight: 'bold', fontSize: '21px', color: '#333333', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '10px', marginBottom: '6px' }}
                                >
                                  {ele.name}
                                </Link>
                                <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.name}
                                </div>

                                </div>
                              </div>
                            </div>
                          </div>
                          
                        {(ele.detail_added != 1 || ele.verified !=1) ? (<div className="price" style={{ marginRight: '-15px' }}>
                          <div className="color-option">
                            <div className="flex items-center justify-center" style={{ marginTop: '0px', textAlign: 'center' }}>
                              <Link style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center', maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} class="color">
                                Coming Soon
                              </Link>
                              {/* <Link className="color" style={{ marginLeft: '0px', color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer', textAlign: 'center' }}>
                              {ele.rating}
                              </Link> */}
                              {/* <div style={{ marginRight: '-15px' }}>
                                <img src={star} height={32} width={32} alt="Image Description" className="badge-image" style={{ marginLeft: '-5px', marginTop: '3px' }} />
                              </div> */}
                            </div>
                          </div>
                        </div>) : ''}
                      </div>

                      <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start' }}>
                        <div className="color-option">
                          <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{ fontSize: '26px', color: '#000000', marginRight: '5px' }}>
                              <span class="ion--location-outline"></span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Link onClick={() => {
                                ele.detail_added === 1 &&ele.verified ===1 && ele.status == 1 && navigate(`/languages-details/${ele.id}/${activeIndex}`)
                              }} className="color"   data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                              style={{ fontSize: '15px', color: '#000',maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{ele.location}
                                <span className="underline"></span> 
                              </Link>
                              <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.location}
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div className="color-price" style={{ marginTop: '30px', marginLeft: '-12px' }}>
                          <div className="flex items-center">
                            <div style={{ fontSize: '30px', color: '#000000', marginRight: '16px' }}>
                              <span class="clarity--language-line"></span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Link onClick={() => {
                                ele.detail_added === 1 && ele.verified == 1 &&
                                  navigate(`/languages-details/${ele.id}/${activeIndex}`)
                              }}
                                className="color" style={{ fontSize: '18px', color: '#858585', marginBottom: '4px', fontWeight: 'bold' }}>Language Portfolio</Link>


                              <Link onClick={() => {
                                ele.detail_added === 1 && ele.verified == 1 &&
                                  navigate(`/languages-details/${ele.id}/${activeIndex}`)
                              }}
                                className="color" style={{ fontSize: '16px', color: '#000000', fontWeight: 'bold' }}>{ele.extra_details?ele.extra_details:'Not Yet Added'}</Link>

                            </div>
                          </div>
                        </div>
                      {/* <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px' }}>
                                            <div className="flex items-center">
                                              <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                              <span class="ci--calendar-days"></span>
                                              </div>
                                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Link to={`/languages-details/${ele.id}/${activeIndex}`} className="color"  style={{ fontSize: '18px', color: '#858585', marginBottom: '4px',fontWeight:'bold' }}>Seasoned Insight</Link>
                                                <Link to={`/languages-details/${ele.id}/${activeIndex}`} className="color"  style={{ fontSize: '16px', color: '#000000',fontWeight:'bold' }}>{ele.experience} years of experience</Link>      
                                              </div>
                                            </div>
                                          </div> */}

                      {/*                                            
                                            <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px' }}>
                                            <div className="flex items-center">
                                            <div style={{ fontSize: '28px', color: '#000000', marginRight: '19px' }}>
                                              <span style={{marginLeft:'-3px'}} class="game-icons--duration"></span>
                                              </div>
                                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Link to={`/languages-details/${ele.id}/${activeIndex}`} className="color"  style={{ fontSize: '18px', color: '#858585', marginBottom: '4px',fontWeight:'bold' }}>Study Period</Link>
                                                <Link to={`/languages-details/${ele.id}/${activeIndex}`} className="color"  style={{ fontSize: '16px', color: '#000000',fontWeight:'bold' }}>{ele.duration}</Link>      
                                              </div>
                                            </div>
                                          </div> */}

                      {/* <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px' }}>
                                            <div className="flex items-center">
                                              <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                                              <span class="mdi--dollar"></span>
                                              </div>
                                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Link to={`/languages-details/${ele.id}/${activeIndex}`} className="color"  style={{ fontSize: '18px', color: '#858585', marginBottom: '4px',fontWeight:'bold' }}>Linguistic Advisory Charge</Link>
                                                <Link to={`/languages-details/${ele.id}/${activeIndex}`} className="color"  style={{ fontSize: '16px', color: '#000000',fontWeight:'bold' }}>{ele.fee}</Link>      
                                              </div>
                                            </div>
                                          </div> */}
                    </div>
                  </div>


                </div>
              </Link>
            ))
          }
        </div>
        :<Error/>}
        <div className="container mt-8" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          {/* <div className="flex items-center">
            <Link style={{ color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer', marginTop: '15px', }}>
              Load More
            </Link>
            <div style={{ marginTop: '17px', fontSize: '40px', color: '#000000', cursor: 'pointer' }} >
              <RiArrowDropDownLine style={{ color: '#000000' }} />
            </div>
          </div> */}
        </div>
      </section>
      )}
    </>
  )
}
