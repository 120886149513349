import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Select from 'react-select'
import Switcher from '../../components/switcher';
import { Icon } from '@iconify/react';
import star from '../../assets/images/rating-star.png';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { university } from '../../data/data';
import { LuClock, LuCalendarDays, RiDragDropLine, LuSearch, AiOutlineAppstore, AiOutlineStar, MdKeyboardArrowLeft, MdKeyboardArrowRight, PiBrowsersLight, MdOutlineSportsVolleyball, AiOutlinePieChart, LuMusic4, TbCameraPlus, PiRocketLaunchBold, FaImages, ImImages, BiImage, BiImages, RiArrowDropDownLine } from "../../assets/icons/vander"
import axios from 'axios';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import { empty } from '../../common/Helper';
import rank from '../../images/global-rank.png'
import Error from '../special/error';
import { DNA } from 'react-loader-spinner';





export default function UniversityListing({ activeIndex, universitySelected ,refresh,countryPrefer}) {
  const navigate = useNavigate()
  const [universities, setUniversities] = useState([])
  const [courses, setCourses] = useState([])
  const [loading, setLoading] = useState(true);

  const fetchAllUniversity = async () => {
    try {
      const response = await axios.post(`${REST_API_END_POINT}university/get-all-list`, {from_user:true});
      if (response.data.status === 1) {
        const filteredData = response.data.data
        .filter(d => d.status === 1 )
        .filter(d => d.location.toLowerCase().includes(countryPrefer.name.toLowerCase()));
        setUniversities(filteredData)

      //   const promises = filteredData.map(async eachRow => {
      //     try {
      //       const res = await axios.post(`${REST_API_END_POINT}university/get-all-course`, { u_id: eachRow?.id, getUniverity: true ,from_user:true});
      //       return res.data.data.filter(d => d.status === 1);
      //     } catch (err) {
      //       console.error(`Error fetching universities for university ID ${eachRow?.id}:`, err);
      //       return [];
      //     }
      //   });
      //   const universityData = await Promise.all(promises);
      //   setCourses(universityData.flat().filter(d => d.course_status == 1));

       }
      else{
        setUniversities([])
      }
    }
     catch (err) {
      console.error('Error fetching university data:', err);
    }
  };

  useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    fetchAllUniversity()
    return () => clearTimeout(timer);
  }, [refresh,countryPrefer])
 
  console.log("universitySelected-11111",universitySelected);
  useEffect(() => {
    setUniversities([universitySelected])
  }, [universitySelected])
  console.log("universities-11111",universities);
  console.log("universities-11111",universities.length);

  return (
    <>
    {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
        <DNA
        visible={true}
        height="140"
        width="140"     
        ariaLabel="dna-loading"
        wrapperStyle={{}}
         wrapperClass="dna-wrapper"
           />
        </div>
      ) : (
      <section className="relative mb-6" style={{ marginTop: '30px' }}>
        {universities?.length>=1?
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]" style={{ padding: '20px' }}>
          {
            universities?.map((ele, index) => (
              <Link key={index} onClick={() => { ele.verified == 1 &&
                //  navigate(`/university-details/${ele.course_id}/${activeIndex}`)
                 navigate(`/university-profile/${ele.id}/${activeIndex}`) 
                 }}
                style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', alignContent: 'stretch' }}>
                <div key={index}
                  className="group relative overflow-hidden shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-100 "
                  style={{ borderRadius: '20px', }}>
                  <img style={{ maxWidth: '100%',height:'300px',width:'600px', objectFit: 'cover' }} src={ele.cover_image} alt={ele.name} />


                  <div className="relative p-6">
                  {/* {ele?.rank_button===1 &&(  <div className="bagde-flag-wrap4">
                              <img
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    zIndex: '2', 
                                }}
                                src={rank} 
                                alt="Ranking"
                              />
                            </div>)} */}
                            {ele?.rank_button===1 &&(
                  <span
                      style={{
                        top: "-280px",
                        right: "10px",
                        position: "absolute",
                        backdropFilter: "blur(1000px)",
                      }}
                      className="px-2 color text-white text-sm font-semibold rounded-lg shadow items-center"
                    >
                      <li className="inline-block m-2">
                        <Link to="#" style={{ backdropFilter: "blur(10px)" }}>
                          Global Ranking # {ele?.global_rank_number}
                        </Link>
                      </li>
                    </span>
                  )}
                    <div className="bagde-flag-wrap1">
                      <img
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          zIndex: '2',
                        }}
                        src={ele.logo}
                        alt={ele.name}
                      />
                    </div>


                    <div>
                      <div className="color-price" style={{ marginTop: '-10px', marginLeft: '-10px' }}>
                        <div className="color-option1" >
                          <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {/* <Link onClick={()=>{ele.verified==1 && navigate(`/university-details/${ele.course_id}/${activeIndex}`) }}
                                            style={{fontWeight:'bold',fontSize:'20px'}} class="color">
                                              {ele.name}</Link> */}
                              <Link
                                data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                              onClick={() => {
                                ele.verified === 1 && ele.status == 1 &&
                                  // navigate(`/university-details/${ele.course_id}/${activeIndex}`)  
                                  navigate(`/university-profile/${ele.course_id}/${activeIndex}`)  
                              }} className="color" style={{ fontWeight: 'bold', fontSize: '21px', color: '#333333', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '10px', marginBottom: '6px' }}>{ele.name}
                              </Link>
                              <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.name}
                                </div>
                            </div>
                          </div>
                        </div>
                        {(ele.verified != 1 || ele.verified !=1)? (
                          <div className="price" style={{ marginRight: '-15px' }}>
                          <div className="color-option">
                            <div className="flex items-center justify-center" style={{ marginTop: '0px', textAlign: 'center' }}>
                              <Link style={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center', maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} class="color">
                                Coming Soon
                              </Link>
                        {/* <div className="price" style={{ marginRight: '-15px' }}>
                          <div className="color-option">
                            <div className="flex items-center justify-center"
                              style={{ marginTop: '0px', textAlign: 'center' }}>
                              <Link className="color"
                                style={{
                                  marginLeft: '0px', color: '#000000', fontWeight: 'bold', fontSize: '18px',
                                  cursor: 'pointer', textAlign: 'center'
                                }}>
                                3.5
                              </Link>
                              <div style={{ marginRight: '-15px' }}>
                                <img src={star} height={32} width={32} alt="Image Description" className="badge-image"
                                  style={{ marginLeft: '-5px', marginTop: '3px' }} />
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      </div>
                      </div>):''}
                      </div>

                      <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px', position: 'relative', display: 'flex', justifyContent: 'flex-start'  }}>
                        <div className="color-option">
                          <div className="flex items-center" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{ fontSize: '28px', color: '#000000', marginRight: '18px' }}>
                              <span class="ion--location-outline"></span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Link onClick={() => { ele.verified == 1 && 
                              // navigate(`/university-details/${ele.course_id}/${activeIndex}`)
                              navigate(`/university-profile/${ele.course_id}/${activeIndex}`) 
                             }}
                              
                                className="color" data-ripple-light="true"
                                  data-tooltip-target="tooltip"
                                style={{fontSize: '15px', color: '#000',maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                  {ele.location}
                                {/* <span className="underline"></span> */}
                              </Link>
                              <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.location}
                                </div>
                              <div
                                  data-tooltip="tooltip"
                                  class="tooltip"
                                >
                                  {ele.location}
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="color-price" style={{ marginTop: '30px', marginLeft: '-10px' }}>
                        <div className="flex ">
                          <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                            <span class="material-symbols--school-outline"></span>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link
                              onClick={() => { ele.verified == 1 && navigate(`/university-details/${ele.course_id}/${activeIndex}`) }}
                              className="color" style={{ fontSize: '18px', color: '#858585', marginBottom: '4px', fontWeight: 'bold' }}>Course Qualification</Link>
                            <Link onClick={() => { ele.verified == 1 && navigate(`/university-details/${ele.course_id}/${activeIndex}`) }}
                              className="color"
                              style={{ fontSize: '16px', color: '#000000', fontWeight: 'bold' }}>
                              {ele.course_name}
                            </Link>
                          </div>
                        </div>
                      </div> */}


                      {/* <div className="color-price" style={{ marginTop: '30px', marginLeft: '-12px' }}>
                        <div className="flex items-center">
                          <div style={{ fontSize: '30px', color: '#000000', marginRight: '16px' }}>
                          <span class="material-symbols--school-outline"></span>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link onClick={() => { ele.verified == 1 && 
                            navigate(`/university-profile/${ele.course_id}/${activeIndex}`) 
                           }}
                              className="color" style={{ fontSize: '18px', color: '#858585', marginBottom: '4px', fontWeight: 'bold' }}>Course Qualification</Link>


                            <Link onClick={() => { ele.verified == 1 && 
                            navigate(`/university-profile/${ele.course_id}/${activeIndex}`) 
                           }}
                              className="color" style={{ fontSize: '16px', color: '#000000', fontWeight: 'bold' }}>{ele.course_name ? ele.course_name : 'Not Yet Added'}</Link>

                          </div>
                        </div>
                      </div> */}



                    </div>
                  </div>
                </div>
              </Link>
            ))
          }
        </div>
        : <Error />}
        {/* <div className="container mt-8" style={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                        <div className="flex items-center">
                          <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',}}>
                         Load More
                          </Link>
                          <div style={{marginTop:'17px',fontSize:'40px',color:'#000000',cursor:'pointer' }} >
                          <RiArrowDropDownLine  style={{color:'#000000'}}/>
                          </div>
                        </div> 
                    </div>   */}
      </section>
      )}
    </>
  )
}









// import React, { useEffect, useState } from 'react'
// import Navbar from '../../components/navbar'
// import Footer from '../../components/footer';
// import Select from 'react-select'
// import Switcher from '../../components/switcher';
// import { Icon } from '@iconify/react';
// import star from '../../assets/images/rating-star.png';
// import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
// import { Link, useNavigate } from 'react-router-dom';
// import { university } from '../../data/data';
// import {LuClock,LuCalendarDays,RiDragDropLine, LuSearch,AiOutlineAppstore, AiOutlineStar,MdKeyboardArrowLeft, MdKeyboardArrowRight,PiBrowsersLight, MdOutlineSportsVolleyball, AiOutlinePieChart, LuMusic4, TbCameraPlus, PiRocketLaunchBold,FaImages,ImImages, BiImage, BiImages, RiArrowDropDownLine} from "../../assets/icons/vander"
// import axios from 'axios';
// import { REST_API_END_POINT } from '../../constants/DefaultValue';
// import { empty } from '../../common/Helper';





// export default function UniversityListing({ activeIndex ,universitySelected}) {
//   const navigate = useNavigate()
//    const [universities,setUniversities] = useState([])

//     const fetchAllUniversity = async () => {
//       try {
//           const response = await axios.post(`${REST_API_END_POINT}university/get-all-list`, {});
//           if (response.data.status === 1) {
//               const filteredData = response.data.data.filter(d => d.status === 1 && d.verified===1);
  
//               const promises = filteredData.map(async eachRow => {
//                   try {
//                       const res = await axios.post(`${REST_API_END_POINT}university/get-all-course`, { u_id: eachRow?.id, getUniverity: true });
//                       return res.data.data.filter(d => d.status === 1);
//                   } catch (err) {
//                       console.error(`Error fetching universities for university ID ${eachRow?.id}:`, err);
//                       return [];
//                   }
//               });
//               const universityData = await Promise.all(promises);
//                setUniversities(universityData.flat().filter(d=>d.course_status==1));

//           }
//       } catch (err) {
//           console.error('Error fetching university data:', err);
//       }
//   };
  
//   useEffect(()=>{
//     fetchAllUniversity()
//   },[])
// useEffect(()=>{
//  setUniversities([universitySelected])
// },[universitySelected])
//     return (
//         <>
//          <section className="relative mb-6" style={{marginTop:'30px'}}>
//                 <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]" style={{padding:'20px'}}>
//                         {
//                             universities?.map((ele, index) => (
//                               <Link onClick={()=>{ele.verified==1 && navigate(`/university-details/${ele.course_id}/${activeIndex}`) }}               
//                               style={{display:'flex',alignItems:'stretch',justifyContent:'stretch',alignContent:'stretch'}}>
//                                 <div key={index} 
//                                 className="group relative overflow-hidden shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-100 " 
//                                 style={{borderRadius:'20px',}}>
                                                                   
//                                       <img style={{maxWidth:'100%',height:'300px',objectFit: 'cover'}}  src={ele.cover_image} alt={ele.name} />
                                    

//                                     <div className="relative p-6">
                                      
//                                         <div className="bagde-flag-wrap1">
//                                         <img
//                                               style={{
//                                                   position: 'absolute',
//                                                   top: '0',
//                                                   right: '0',
//                                                   width: '100%',
//                                                   height: '100%',
//                                                   objectFit: 'cover',
//                                                   zIndex: '2', 
//                                               }}
//                                               src={ele.logo} 
//                                               alt={ele.name}
//                                         />
//                                         </div>


//                                         <div>
//                                             <div className="color-price" style={{marginTop:'-10px',marginLeft:'-10px'}}>
//                                             <div className="color-option1" >
//                                             <Link onClick={()=>{ele.verified==1 && navigate(`/university-details/${ele.course_id}/${activeIndex}`) }}
//                                             style={{fontWeight:'bold',fontSize:'20px'}} class="color">
//                                               {ele.name}</Link>
//                                             </div>
//                                             <div className="price" style={{marginRight:'-15px'}}>
//                                             <div className="color-option">
//                                               <div className="flex items-center justify-center" 
//                                               style={{marginTop:'0px',textAlign:'center'}}>
//                                                   <Link className="color" 
//                                                   style={{marginLeft: '0px', color: '#000000', fontWeight: 'bold', fontSize: '18px',
//                                                    cursor: 'pointer',textAlign:'center' }}>
//                                                     3.5
//                                                   </Link>
//                                                   <div style={{marginRight:'-15px'}}>
//                                                     <img src={star} height={32} width={32} alt="Image Description" className="badge-image"
//                                                      style={{ marginLeft: '-5px',marginTop:'3px' }} />
//                                                   </div>
//                                               </div>
//                                             </div>
//                                             </div>
//                                             </div>
                                            
//                                             <div className="color-price" style={{ marginTop: '10px', marginLeft: '-10px', position: 'relative' }}>
//                                             <div className="color-option">
//                                             <div className="flex items-center">
//                                               <div style={{ fontSize: '28px', color: '#000000', marginRight: '18px' }}>
//                                               <span class="ion--location-outline"></span>
//                                               </div>
//                                               <div style={{ display: 'flex', flexDirection: 'column' }}>
//                                                 <Link onClick={()=>{ele.verified==1 && navigate(`/university-details/${ele.course_id}/${activeIndex}`) }}
//                                                  className="color"  style={{fontSize: '15px', color: '#000', }}>{ele.location}
//                                                 <span className="underline"></span>
//                                                 </Link>
//                                               </div>
//                                             </div>                                                                                                                                        
//                                             </div>
//                                           </div>

//                                             <div className="color-price" style={{ marginTop: '30px', marginLeft: '-10px' }}>
//                                             <div className="flex ">
//                                               <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
//                                               <span class="material-symbols--school-outline"></span>
//                                               </div>
//                                               <div style={{ display: 'flex', flexDirection: 'column' }}>
//                                                 <Link 
//                                                 onClick={()=>{ele.verified==1 && navigate(`/university-details/${ele.course_id}/${activeIndex}`) }} 
//                                                 className="color"  style={{ fontSize: '18px', color: '#858585', marginBottom: '4px',fontWeight:'bold' }}>Course Qualification</Link>
//                                                 <Link onClick={()=>{ele.verified==1 && navigate(`/university-details/${ele.course_id}/${activeIndex}`) }} 
//                                                 className="color"  
//                                                 style={{ fontSize: '16px', color: '#000000',fontWeight:'bold' }}>
//                                                   {ele.course_name}
//                                                   </Link>      
//                                               </div>
//                                             </div>
//                                           </div>   
//                                         </div>
//                                     </div>
//                                 </div>
//                                 </Link>
//                             ))
//                         }
//                     </div>
//                     {/* <div className="container mt-8" style={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
//                         <div className="flex items-center">
//                           <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',}}>
//                          Load More
//                           </Link>
//                           <div style={{marginTop:'17px',fontSize:'40px',color:'#000000',cursor:'pointer' }} >
//                           <RiArrowDropDownLine  style={{color:'#000000'}}/>
//                           </div>
//                         </div> 
//                     </div>   */}
//             </section>            
//         </>
//     )
// }
