// Local Server 

// export const REST_API_END_POINT = "http://localhost:4000/visa/";
// export const REST_API = "http://localhost:4000/";


// NameChip Server

// export const REST_API_END_POINT = "https://visa-api.skyniche.website/visa/"
// export const REST_API = "https://visa-api.skyniche.website/";


// AWS server 

export const REST_API_END_POINT = "https://visa-api.visasearch.info/visa/";
export const REST_API = "https://visa-api.visasearch.info/";


//port sharing

// export const REST_API_END_POINT = "http://192.168.1.19:4000/visa/";
// export const REST_API = "http://192.168.1.19:4000/";


// Map API 

export const MAP_API_KEY = 'AIzaSyD9vQeloU0By-tgfPWLErF8StGIPPamjt8'

// export const MAP_API_KEY = 'pk.eyJ1IjoidmlzYXNlYXJjaCIsImEiOiJjbHo2a3lzanoxaHp6MmpxMjNjcDQyNGs3In0.uRtxhuAaWdb259Tv44Wv5g'


///currency  exchange api key and URL
export const EXCHANGE_API_KEY = 'd57478d7c5018080fd66957c';
export const EXCHANGE_BASE_URL = `https://v6.exchangerate-api.com/v6/${EXCHANGE_API_KEY}/pair`;