import React, { useEffect,useState } from 'react'
import image from '../../assets/images/items/1.gif';
import image1 from '../../assets/images/items/1.jpg';
import image2 from '../../assets/images/items/2.jpg';
import image3 from '../../assets/images/items/3.jpg';
import image4 from '../../assets/images/items/2.gif';
import image5 from '../../assets/images/items/4.jpg';
import image6 from '../../assets/images/items/3.gif';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import tick from '../../assets/images/check.png';
import star from '../../assets/images/rating-star.png';
import check from '../../assets/images/tick.png';
import travel from '../../assets/images/travel-insurance.png';
import { Icon } from '@iconify/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faImage, faPassport,faHotel } from '@fortawesome/free-solid-svg-icons';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import airplaneTakeoffFill from '@iconify/icons-ph/airplane-takeoff-fill';
import { Link, useParams } from 'react-router-dom';
import {LuSearch, BiCheckCircle,CgArrowDown,FiTarget, BsTag,BiPlusCircle,BiDoorOpen, RiArrowDropDownLine, FiHeart, PiBrowsersLight,FiUsers,VscListFlat, PiMusicNotesBold, LuCamera,FaRegPaperPlane, BiWallet, BiMinusCircle, BiShield, BiTargetLock, FaSearchLocation, FaSearchDollar, LuUsers2, LuFolderCheck, MdOutlineChatBubbleOutline, TiPhoneOutline, IoMdClose, GrVisa, AiTwotoneInsurance, RiExchangeBoxFill, RiExchangeDollarLine, MdWorkOff, GoLocation, MdWork, BiHealth, GiHealthIncrease, BiFontFamily, GiFamilyHouse, CgGhost, BsCash, GiCash, GrFormAdd, GiAges, BiLabel, CiMedicalMask, CiMedicalClipboard, AiFillInsurance, GiRelationshipBounds, MdOutlineArrowBack} from "../../assets/icons/vander"
import { Users,Calendar,Clock,DollarSign, Folder } from 'feather-icons-react/build/IconComponents';
import NavbarNew from '../../components/navbar-new';
import File from 'feather-icons-react/build/IconComponents/File';
import { dataArray } from '../../data/data';
import HealthListing from '../index/health-listing';
import Comingsoon from '../special/comingsoon';
import { PRPathwayDetails } from '../../data/data';
import NavbarPR from '../../components/navbar-pr';


const documentsRequired = [
  { 
    icon: 'solar--user-id-linear',
    text: 'Identity Documents',
    details: [
      "Should be valid for at least six months beyond the trip's duration.",
      "Must have a minimum of 2 blank pages.",
      "A scanned copy of the first and last page of the passport.",
      "Scan and include the first and last pages of your previous passport, along with its travel history."
     ]
  },
  { 
    icon: 'fluent--form-new-20-filled',
    text: 'Character Documents',
    details: [
    "Should be valid for at least six months beyond the trip's duration.",
    "Must have a minimum of 2 blank pages.",
    "A scanned copy of the first and last page of the passport.",
    "Scan and include the first and last pages of your previous passport, along with its travel history."
   ] },
  { 
    icon: 'fluent--form-new-20-filled',
    text: 'Previous & current relationship documents',
    details: [
    "Should be valid for at least six months beyond the trip's duration.",
    "Must have a minimum of 2 blank pages.",
    "A scanned copy of the first and last page of the passport.",
    "Scan and include the first and last pages of your previous passport, along with its travel history."
   ] },
  {
    icon: 'uil--18-plus',
    text: 'Dependents under/over 18 documents',
    details: [
    "Should be valid for at least six months beyond the trip's duration.",
    "Must have a minimum of 2 blank pages.",
    "A scanned copy of the first and last page of the passport.",
    "Scan and include the first and last pages of your previous passport, along with its travel history."
   ] }
];

export default function ProvincialNominees() {

  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    
    const getCurrentDate = () => {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const today = new Date();
      return today.toLocaleDateString('en-US', options);
    };

    
    setCurrentDate(getCurrentDate());
}, []);


  
  const { PRPathwayId, tab } = useParams();

  const prpathwayData = PRPathwayDetails.find((university) => university.id === parseInt(PRPathwayId));

  const course = prpathwayData ? prpathwayData.course : 'University Course';
  const title = prpathwayData ? prpathwayData.title4 : 'PR Title';
  const years = prpathwayData ? prpathwayData.years : 'Course Time';
  const logo = prpathwayData ? prpathwayData.logo : 'College Logo';
  const fee = prpathwayData ? prpathwayData.fee : 'Course Fee';
  const duration = prpathwayData ? prpathwayData.duration : 'Course Fee';
  const score = prpathwayData ? prpathwayData.score : 'Course Fee';
  const ranking = prpathwayData ? prpathwayData.ranking : 'College Rank';
  const offerletterDate = prpathwayData ? prpathwayData.offerletterDate : 'Offer Letter On';
  const money = prpathwayData ? prpathwayData.money : 'Exchange Money';
  const image = prpathwayData ? prpathwayData.image : 'Visa Search Image';  
  const overview = prpathwayData ? prpathwayData.overview : 'Course Overview';  
  const subImages = prpathwayData && prpathwayData.subImage ? prpathwayData.subImage : [];
  
  const [selectedLink, setSelectedLink] = useState('Provincial Nominees');

  const handleLinkClick = (link) => {
      setSelectedLink(link);
  };

  const [placeBid, setPlaceBid] = useState(false);

  useEffect(() => {
    document.documentElement.classList.add('dark');
  }, []);

  const rankingDemo = 'https://cdn-icons-png.flaticon.com/512/9143/9143755.png'

  const [isForApplicants, setIsForApplicants] = useState(true);

  const handleToggle = () => {
      setIsForApplicants(prevState => !prevState);
      const newPath = isForApplicants && `/pr-pathway-provicial-nominees/${PRPathwayId}/${tab}`
      window.location.href = newPath;
  };
  return (
    <>
      <NavbarPR tab={tab}/>

                <section className="relative md:py-24 py-16" style={{ marginTop: '20px' }}>
                  <div className="container">
                  <div className="md:flex">
                    <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" style={{paddingInline:'1rem'}}>
                    <div className="color-price" style={{marginTop:'-20px',marginBottom:'30px'}}>
                        <div className="flex items-center">
                            <Link                                                 
                            to={`/pr-pathway/${prpathwayData.country}`}  
                                style={{
                                    color: '#000000',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    marginLeft: '15px',
                                    paddingBottom: '3px', 
                                    whiteSpace: 'nowrap', 
                                    display: 'flex', // Added display flex
                                    alignItems: 'center', // Added align items center
                                }}
                                className='under'>

                                <MdOutlineArrowBack color='#000' style={{ marginRight: '5px' }} /> 
                                Go back to PR's
                            </Link>
                        </div>
                      </div>
                    <div style={{ position: 'relative', width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                      <div style={{ position: 'absolute', bottom: 75, right: 0, width: '285px', height: '100px', backgroundColor: 'transparent', backdropFilter: 'blur(100px)', borderTopLeftRadius: '10px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <div className="side-heading" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'space-between', color: 'white', fontWeight: 'bold', }}>
                              <h2 style={{ marginBottom: "5px", fontSize: '25px',textAlign:'left' }}>Personalize this page..</h2>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                  <span style={{ marginRight: "5px", fontSize: '16px' }}>Applicants</span>
                                  <label className="switch" style={{ marginBottom: "0", marginLeft: "5px", marginRight: "8px" }}>
                                      <input type="checkbox" id="togBtn" onChange={handleToggle} />
                                      <div className="slider round"></div>
                                  </label>
                                  <span style={{ fontSize: '16px' }}>Sponsors</span>
                              </div>
                          </div>
                      </div>
                      
                        <img
                            style={{
                                width: '100%',
                                left: '0',
                                minWidth: '300px',
                                height: '310px',
                                borderRadius: '10px',
                                objectFit: 'cover',
                            }}
                            src={image}
                            alt="Visa Search Image"
                        />
                        
                        <div className='visa-image' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{title}</span>

                            <div className="bagde-flag-wrap4">
                              <img
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    zIndex: '2', 
                                }}
                                src={logo} 
                                alt="Ranking"
                              />
                            </div>
                        </div>
                        
                    </div>
                                                                                                               
                    </div>                                                                
                    </div>
                </div>

                <div className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl " style={{ zIndex: 1, marginTop: '40px',display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>

                <Link
                  className='font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 '                                  
                  type="button"
                  to='#'                
                  // onClick={() => setIndex(0)}
                  style={{ color: 'black',width:'550px',height:'70px',display:'flex',justifyContent:'center',textAlign:'center',padding:'22px',borderRadius:'14px'}}
                  >
                  Get Started Now
                </Link>
   
              </div>

              <hr style={{ marginTop: '-36px', borderColor: 'black', borderWidth: '1px' }} />
                                  
                <div className="container" style={{marginLeft:'0px', marginTop:'60px'}}>
                  <div className="flex justify-between">
                  <div style={{display: 'flex', justifyContent: 'center',}}>
                    <div className="flex items-center">
                      <Link to={`/consultants`} style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',marginLeft:'10px'}}>
                        Find a <span className='under1'>Registred consultants</span> to help in your journey
                      </Link>
                    </div>                        
                  </div>                           
                  </div>
                </div>
                <div className="container" style={{marginLeft:'0px', marginTop:'30px'}}>
                  <div className="flex justify-between">
                  <div style={{display: 'flex', justifyContent: 'center',}}>
                    <div className="flex items-center">
                      <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',marginLeft:'10px'}}>
                        Visas you can apply
                      </Link>
                    </div>                        
                  </div>                           
                  </div>
                </div>
                <div className="container" style={{marginLeft:'0px', marginTop:'30px'}}>
                    <div className="flex justify-between">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div className="flex items-center" style={{flexWrap:'wrap'}}>
                                <div className='visa-text' style={{marginBottom: '20px', marginRight:'20px'}}>
                                    <div className="flex items-center" style={{marginTop:'10px'}}>
                                        <button 
                                            className={`px-6 py-2 color font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${selectedLink === 'Provincial Nominees' ? 'text-white bg-violet-6001' : 'text-black bg-white'}`}                                  
                                            type="button" 
                                            style={{textAlign:'left'}}
                                            onClick={() => handleLinkClick('Provincial Nominees')}
                                        >
                                            <span>Provincial Nominees</span><br/>Subclass 300
                                        </button>
                                    </div>
                                </div>

                                <div style={{marginBottom: '20px', marginRight:'20px'}}>
                                    <div className="flex items-center" style={{marginTop:'10px'}}>
                                        <button 
                                            className={`px-6 py-2 color font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${selectedLink === 'Nominees 309 and 100' ? 'text-white bg-violet-6001' : 'text-black bg-white'}`}                                  
                                            type="button" 
                                            style={{textAlign:'left'}}
                                            onClick={() => handleLinkClick('Nominees 309 and 100')}
                                        >
                                            <span>Nominees</span><br/>Subclass 309 and 100
                                        </button>
                                    </div>
                                </div>

                                <div style={{marginBottom: '20px'}}>
                                    <div className="flex items-center" style={{marginTop:'10px'}}>
                                        <button 
                                            className={`px-6 py-2 color font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${selectedLink === 'Nominees 820 and 801' ? 'text-white bg-violet-6001' : 'text-black bg-white'}`}                                  
                                            type="button" 
                                            style={{textAlign:'left'}}
                                            onClick={() => handleLinkClick('Nominees 820 and 801')}
                                        >
                                            <span>Nominees</span><br/>Subclass 820 and 801
                                        </button>
                                    </div>
                                </div>
                            </div>                        
                        </div>                           
                    </div>
                </div>

                <div className="container" style={{marginLeft:'0px', marginTop:'20px'}}>
                  <div className="flex justify-between">
                  <div style={{display: 'flex', justifyContent: 'center',}}>
                    <div className="flex items-center">
                      <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',marginLeft:'10px'}}>
                        With 300, you can
                      </Link>
                    </div>                        
                  </div>                           
                  </div>
                </div>

                <div className="lg:flex" style={{ marginLeft: '0px', marginBottom: '-20px' ,marginTop:'-50px',paddingRight:'60px'}}>
                  <div className="p-6 w-full" style={{ display: 'flex', flexDirection: 'column',alignItems:'center',justifyContent:'center' }}>
                    <div className="flex flex-wrap  wrapp" style={{gap:'10px'}}>             
                      <div className="color-price" style={{ marginTop: '30px', }}>
                        <div className="flex items-center">
                          <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <Calendar style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' ,marginTop:'25px'}}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'left'}}>Stay</Link>
                            <div className="popover-container1">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>From 9-15 months</Link>
                              

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px' }}>
                        <div className="flex items-center">
                          <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <Icon style={{color:'black'}} icon={airplaneTakeoffDuotone} />
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column',marginTop:'25px' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px',textAlign:'left' }}>Travel to and from</Link>
                            <div className="popover-container1">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>Australia</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px',}}>
                        <div className="flex items-center">
                          <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <span className='icomoon-free--books' style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column',marginTop:'50px' }}>
                          <div className="popover-container1">
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign:'left',textDecoration:'underline'}}>Study</Link><br></br>
                            <img src={check} height={60} width={60} alt="Image Description" className="badge-image" style={{marginLeft:'-13px',}} />

                              <div className="popover-content11" style={{marginLeft:'15px'}}>
                                <div className="popover">
                                  <h3 className="popover-heading1 color-price">Study:</h3>
                                  <p className="popover-paragraph1 color-price">
                                  Pursuing education overseas offers exposure to diverse cultures, advanced academic programs, and a global network. 
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px', }}>
                        <div className="flex items-center">
                          <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <MdWork style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' ,marginTop:'50px'}}>
                            <div className="popover-container1">
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign:'left',textDecoration:'underline' }}>Work</Link><br></br>
                            <img src={check} height={60} width={60} alt="Image Description" className="badge-image" style={{marginLeft:'-13px',}} />
                              <div className="popover-content11">
                                <div className="popover">
                                  <h3 className="popover-heading1 color-price">Work:</h3>
                                  <p className="popover-paragraph1 color-price">
                                  Working in a foreign country opens doors to new experiences, professional growth, and cross-cultural collaboration. 
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px',  }}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <GiHealthIncrease style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' ,marginTop:'50px'}}>
                            <div className="popover-container1">
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign:'left',textDecoration:'underline' }}>Health care</Link><br></br>
                            <img src={check} height={60} width={60} alt="Image Description" className="badge-image" style={{marginLeft:'5px',}} />
                              <div className="popover-content11">
                                <div className="popover">
                                  <h3 className="popover-heading1 color-price">Health care:</h3>
                                  <p className="popover-paragraph1 color-price">
                                  Accessing healthcare services abroad ensures quality medical treatment, advanced technology, and specialized care options. 
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px', }}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>                          
                          <span className='fluent-mdl2--family' style={{color:'black'}}></span>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' ,marginTop:'50px'}}>
                            <div className="popover-container1">
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign:'left',textDecoration:'underline' }}>Bring family</Link><br></br>
                            <img src={check} height={60} width={60} alt="Image Description" className="badge-image" style={{marginLeft:'5px',}} />
                              <div className="popover-content11">
                                <div className="popover">
                                  <h3 className="popover-heading1 color-price">Bring family:</h3>
                                  <p className="popover-paragraph1 color-price">
                                  Reuniting with family members in a new country fosters strong bonds, shared experiences, and cultural exchange.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px', }}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                        <GiFamilyHouse style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px',marginTop: '15px', textAlign:'left'}}>Settle in Australia</Link>
                            <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>Apply 820 and 801</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container" style={{marginLeft:'0px', marginTop:'20px'}}>
                  <div className="flex justify-between">
                  <div style={{display: 'flex', justifyContent: 'center',}}>
                    <div className="flex items-center">
                      <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',marginLeft:'10px'}}>
                        About 300 Visa
                      </Link>
                    </div>                        
                  </div>                           
                  </div>
                </div>

                <div className="lg:flex" style={{ marginLeft: '0px', marginBottom: '30px' ,marginTop:'-50px',paddingRight:'60px',}}>
                  <div className="p-6 w-full" style={{ display: 'flex', flexDirection: 'column',alignItems:'center',justifyContent:'center' }}>
                    <div className="flex flex-wrap wrpp" style={{gap:'10px',paddingLeft:'60px'}}>             
                      <div className="color-price" style={{ marginTop: '30px', }}>
                        <div className="flex items-center">
                          <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <GiCash style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' ,marginTop:'25px'}}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000', marginBottom: '4px',textAlign:'left'}}>Cost</Link>
                            <div className="popover-container1">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>AUD $$$</Link>
                              

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px' }}>
                        <div className="flex items-center">
                          <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                            <Clock style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column',marginTop:'25px' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px',textAlign:'left' }}>Processing Times</Link>
                            <div className="popover-container1">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>20 Days</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px',}}>
                        <div className="flex items-center">
                          <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <span className='clarity--form-line' style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column',marginTop:'25px' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px',textAlign:'left' }}>Apply from</Link>
                            <div className="popover-container1">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>In/out Australia</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px', }}>
                        <div className="flex items-center">
                          <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <span className='uil--award' style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' ,marginTop:'50px'}}>
                            <div className="popover-container1">
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px', textAlign:'left',textDecoration:'underline' }}>Sponsor</Link><br></br>
                            <img src={check} height={60} width={60} alt="Image Description" className="badge-image" style={{marginLeft:'-13px',}} />
                              <div className="popover-content11">
                                <div className="popover">
                                  <h3 className="popover-heading1 color-price">Sponsor:</h3>
                                  <p className="popover-paragraph1 color-price">
                                  Being sponsored for immigration or employment abroad provides individuals with the necessary support and resources to pursue their goals in a new country. 
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px',  }}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <GiAges style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column',marginTop:'25px' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px',textAlign:'left' }}>Be the right age</Link>
                            <div className="popover-container1">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>18 years</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px', }}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <GrVisa style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column',marginTop:'25px' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px',textAlign:'left' }}>Visa label</Link>
                            <div className="popover-container1">
                              <Link className="color" style={{ fontSize: '16px', color: '#000000',}}>Digitally</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px', }}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <CiMedicalClipboard style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px',marginTop: '15px', textAlign:'left'}}>Medical</Link>
                            <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>Required</Link>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px', }}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <span className='fluent-mdl2--commitments' style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px',marginTop: '15px', textAlign:'left'}}>Marriage</Link>
                            <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>Must marry</Link>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px', }}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <span className='streamline--insurance-hand' style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px',marginTop: '15px', textAlign:'left'}}>Insurance</Link>
                            <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>Required</Link>
                          </div>
                        </div>
                      </div>
                      <div className="color-price" style={{ marginTop: '30px', }}>
                        <div className="flex items-center">
                        <div style={{ fontSize: '26px', color: '#000000', marginRight: '18px' }}>
                          <span className='game-icons--relationship-bounds' style={{color:'black'}}/>
                          </div>
                          <div className="color-option" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="color" style={{ fontSize: '18px', color: '#000000', marginBottom: '4px',marginTop: '15px', textAlign:'left'}}>Meet relationship</Link>
                            <Link className="color" style={{ fontSize: '16px', color: '#000000', }}>Requirements</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container mt-24">
                  <div className="md:flex">
                      <div className="lg:w-2/5 md:w-full md:pe-4 md:order-1 md:mt-0 mt-10 md:mt-0 lg:mt-0">
                        <div className="sticky top-20">
                            <ol class="c-stepper">
                                <li className="block items-center">                                                
                                  <span style={{color: '#000000', fontWeight: 'bold', fontSize: '22px', cursor: 'pointer',}}>Documents Required</span>
                                </li>
                                {documentsRequired.map((item, index) => (
                                <li className="c-stepper__item" key={index} style={{ marginTop: '20px' }}>
                                  <div className="popover-container1">
                                    <div className="c-stepper__content">
                                      <a className="stepper-link c-stepper__title">
                                        <span className={item.icon} style={{ color: 'black', fontSize: '27px', marginLeft: '-50px', marginTop: '5px' }} />
                                        <span  className="color" style={{ fontSize: '20px', color: '#000', fontWeight: 'bold',marginLeft: '10px', marginTop: '5px', }}>{item.text}</span>
                                      </a>
                                    </div>
                                    <div className="popover-content1111">
                                      <div className="popover1">
                                        {item.details.map((detail, index) => (
                                          <p key={index} className="popover-paragraph1 color-price" style={{ marginTop: index === 0 ? '-6px' : '0' }}>
                                            <span className="font-medium inline-flex"><span style={{ color: 'black' }} className="me-2">●</span><span style={{ color: '#000000', fontSize: '16px' }}>{detail}</span></span>
                                          </p>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ol>
                            <li className="block items-center" style={{marginTop:'-30px',marginBottom:'30px'}}>                                                
                              <span style={{color: '#000000', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer',}}>* Translate all non-English documents into English</span>
                            </li>
                            {/* <li className="block items-center mt-5" style={{marginTop:'-30px',marginBottom:'30px'}}>                                                
                              <span style={{color: '#000000', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer',fontStyle:'italic'}}>...All documents should be verified by authorized country staff's</span>
                            </li> */}
                          </div>
                      </div>
                    
                    <div className="lg:w-3/5 md:w-full md:mb-8 md:order-2" style={{paddingInline:'1rem'}}>
                        <ol class="c-stepper">
                            <li className="block items-center" style={{marginBottom:'30px'}}>                                                
                              <span style={{color: '#000000', fontWeight: 'bold', fontSize: '24px', cursor: 'pointer',}}>PR Pathway</span>
                            </li>  
                            <li class="c-stepper__item">
                              <div class="c-stepper__content">
                                  <a class="stepper-link c-stepper__title" style={{display: 'flex',alignItems: 'center'}}>
                                      <span class="healthicons--i-documents-accepted-negative" style={{ color: 'black', fontSize:'30px',marginLeft:'-51px',marginTop:'5px' }}></span>
                                      <span style={{ marginLeft:'10px',marginTop:'5px', flex: '1',fontWeight: 'bold', fontSize: '20px',}}>Gather all required documents</span>
                                  </a>                                           
                              </div>
                            </li>

                            <li class="c-stepper__item">
                              <div class="c-stepper__content">
                                  <a class="stepper-link c-stepper__title" style={{display: 'flex',alignItems: 'center'}}>
                                      <span class="icons8--visa" style={{ color: 'black', fontSize:'35px',marginLeft:'-51px',marginTop:'5px' }}></span>
                                      <span style={{ marginLeft:'10px',marginTop:'5px', flex: '1' ,fontWeight: 'bold', fontSize: '20px',}}>Apply for subclass 300 Visa</span>
                                  </a>
                              </div>
                            </li>
                        
                        </ol> 

                        <li className="block items-center" style={{marginTop:'-30px'}}>                                                
                          <div className="flex items-center">
                            <Link to={`/consultants`} style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',marginLeft:'10px'}}>
                              Find a <span className='under1'>Registred consultants</span> to help in your journey
                            </Link>
                          </div> 
                        </li>  

                    </div>                                        
                  </div>
                </div>

                <div className="container" style={{marginLeft:'0px', marginTop:'30px'}}>
                  <div className="flex justify-between">
                  <div style={{display: 'flex', justifyContent: 'center',}}>
                    <div className="flex items-center">
                      <Link style={{color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer',marginTop:'15px',marginLeft:'10px'}}>
                       <span className='under1'>Other Visa Options</span>
                      </Link>
                    </div>                        
                  </div>                           
                  </div>
                </div>
                  
            </section>

            <Footer />
           
        </>
    )
}
