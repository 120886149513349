import React, { useState, useEffect, useRef } from 'react';
import image from '../../assets/images/items/1.gif';
import image1 from '../../assets/images/items/1.jpg';
import image2 from '../../assets/images/items/2.jpg';
import image3 from '../../assets/images/items/3.jpg';
import image4 from '../../assets/images/items/2.gif';
import image5 from '../../assets/images/items/4.jpg';
import image6 from '../../assets/images/items/3.gif';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import tick from '../../assets/images/tick1.png';
import star from '../../assets/images/rating-star.png';
import check from '../../assets/images/tick.png';
import travel from '../../assets/images/travel-insurance.png';
import { Icon } from '@iconify/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faImage, faPassport, faHotel } from '@fortawesome/free-solid-svg-icons';
import airplaneTakeoffDuotone from '@iconify/icons-ph/airplane-takeoff-duotone';
import airplaneTakeoffFill from '@iconify/icons-ph/airplane-takeoff-fill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LuSearch, BiCheckCircle, CgArrowDown, FiTarget, BsTag, BiPlusCircle, BiDoorOpen, RiArrowDropDownLine, FiHeart, PiBrowsersLight, FiUsers, VscListFlat, PiMusicNotesBold, LuCamera, FaRegPaperPlane, BiWallet, BiMinusCircle, BiShield, BiTargetLock, FaSearchLocation, FaSearchDollar, LuUsers2, LuFolderCheck, MdOutlineChatBubbleOutline, TiPhoneOutline, IoMdClose, GrVisa, AiTwotoneInsurance, RiExchangeBoxFill, RiExchangeDollarLine, MdWorkOff, GoLocation, GoLaw, MdOutlineArrowForward, BsExclamationOctagon } from "../../assets/icons/vander"
import { Users, Calendar, Clock, DollarSign, Folder } from 'feather-icons-react/build/IconComponents';
import NavbarNew from '../../components/navbar-new';
import File from 'feather-icons-react/build/IconComponents/File';
import { dataArray } from '../../data/data';
import HealthListing from '../index/health-listing';
import Comingsoon from '../special/comingsoon';
import { insuranceDetails } from '../../data/data';
import { languageoffers } from '../../data/data';
import QA from '../../components/qa';
import axios from 'axios';
import { REST_API_END_POINT } from '../../constants/DefaultValue';
import moment from 'moment';
import { empty, formatDescription } from '../../common/Helper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const getData = (source, ids) => {
    if (!ids) return [];
    return ids.split(',').map(id => source.find(item => item.id == id)).filter(item => item !== undefined);
};
export default function InsuranceDetail() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [placeBid, setPlaceBid] = useState(false);
    const [benefitsIndex, setBenefitsIndex] = useState();
    const { InsuranceId, tab } = useParams();
    const [companyData, setCompanyData] = useState({})
    const [covers, setCovers] = useState([])
    const [faqs, setFaq] = useState([])
    const [chooseData, setChooseData] = useState([])
    const [insurancePlans, setInsurancePlans] = useState([])
    const [benefits, setBenefits] = useState([])
    const [insuranceDetail, setInsuranceDetails] = useState({})
    const [benefitIds, setBenefitIds] = useState('')
    const [notification, setNotification] = useState({})
    const navigate=useNavigate()
    const getinsuranceDetailsByid = () => {
        axios.post(`${REST_API_END_POINT}insurance/details`, { id: InsuranceId })
            .then((res) => {
                if (res.data.status === 1) {
                    // console.log("insuranceeeeeeee", res.data)
                    setInsuranceDetails(res.data.insuranceData)
                    setNotification(res.data.insuranceData.notification_btn)
                    setCompanyData(res.data.companyData)
                    setCovers(res.data.subMenus.filter(r => r.sub_menu === 1))
                    setChooseData(res.data.subMenus.filter(r => r.sub_menu === 2))
                    setFaq(res.data.subMenus.filter(r => r.sub_menu === 3))
                    setInsurancePlans(res.data.insurancePlans)
                    setBenefits(res.data.benefits)
                }
            })
    }
    useEffect(() => {
        getinsuranceDetailsByid()
    }, [InsuranceId])

    const initailCoverData = covers.find(d => d.id === insuranceDetail.cover_doc_id)
    const initialFAQ = getData(faqs, insuranceDetail?.faq_id)
    const initialChoose = getData(chooseData, insuranceDetail?.choose_id)

    const [InsuranceData, setInsuranceData] = useState([]);



    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);


    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }


    const toggleBenefitsIndexAccordion = (index) => {
        if (benefitsIndex === index) {
            setBenefitsIndex();
        } else {
            setBenefitsIndex(index);
        }
    }
    const userStr = localStorage.getItem('userVisa');
    const user = JSON.parse(userStr)||{};
    // console.log("user",user);
    // console.log("user_id",user.user_id);
    const handleEnquiry=async()=>{
        if(!empty(user)){
         try {
        const response = await axios.post(`${REST_API_END_POINT}insurance/add-enquiry`, {
            user_id:user.user_id,insurance_id:insuranceDetail?.id,company_id:companyData?.id
        });
        
        if(response.data.status===1){
            toast.success(response.data.message);
        }else{
            toast.error(response.data.message);
        }

      } catch (error) {
        console.error('Error fetching :', error);
        toast.error(error.data.message)
      }
    }
    else{
        navigate('/signup-visa/' + tab)
    }
        }

    const expireDate = moment.unix(insuranceDetail?.expiredate);
    const formattedExpireDate = expireDate.format('MMMM D, YYYY');

    const handleClickButton = (ids) => {
        setPlaceBid(!placeBid)
        setBenefitIds(ids)
    }
    return (

        <>
            <NavbarNew tab={tab}  />

            <section className="relative md:py-24 py-16" style={{ marginTop: '20px' }}>
                <div className="container">


                    {/* {(notification===1) &&(  
                   
                  <div style={{display:"flex", justifyContent:"center"}}>
                    <div style={{backgroundColor:'grey',borderRadius:'10px',marginBottom:'10px',display:'flex',padding:'10px',width:'80%',justifyContent:"space-between"}}>
         <div style={{display:"flex",flexDirection:"column"}}>             
    <span style={{color: '#fff', fontSize: '14px', cursor: 'pointer',textAlign:'left', wordWrap: 'break-word'}}>
       Notification
    </span>
    <span style={{color: '#fff', fontSize: '16px', cursor: 'pointer',textAlign:'left', wordWrap: 'break-word'}}>
        {insuranceDetail?.notifications}
    </span>
</div>

    <div  style={{display:"flex",flexDirection:"column"}}>
      <span>.</span>
      <span style={{color: '#fff', fontSize: '16px', cursor: 'pointer',textAlign:'right', wordWrap: 'break-word'}}>
         Expire Date : {formattedExpireDate}
      </span>
    </div>
</div>
</div>
       )} */}
                    {(notification === 1) && (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="flex items-center p-3 mt-3 rounded-lg" style={{ width: '100%', backgroundColor: 'grey', marginBottom: '10px', justifyContent: 'space-between', color: 'white' }}>


                                <div className="ms-2 items-center" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', textAlign: 'left' }}>
                                    <BsExclamationOctagon style={{ color: 'white', marginRight: '10px' }} className="text-3xl" />
                                    <div>
                                        {/* <span className="block font-semibold">Master Notification</span> */}
                                        <span className="block" >{insuranceDetail?.notifications}</span>
                                    </div>
                                </div>
                                <div className="ms-2" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'right' }}>
                                    {/* <span className="block font-semibold">Expire Date</span> */}
                                    <span className="block">{formattedExpireDate}</span>
                                </div>

                            </div>

                        </div>
                    )}
                    <div className="md:flex">

                        <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" style={{ paddingInline: '1rem', }}>


                            <div style={{ position: 'relative', width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                                <img
                                    style={{
                                        width: '100%',
                                        left: '0',
                                        minWidth: '300px',
                                        height: '360px',
                                        borderRadius: '10px',
                                        objectFit: 'cover',
                                    }}
                                    src={companyData?.cover_image}
                                    alt="VIsa Search Image"
                                />
                                <div className='visa-image' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>{companyData?.name} <span style={{ marginLeft: '10px', fontSize: '20px', fontWeight: '200' }}>{insuranceDetail?.name}</span></span>

                                    <div className="bagde-flag-wrap2">
                                        <img
                                            style={{
                                                position: 'absolute',
                                                top: '0',
                                                right: '0',
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                zIndex: '2',
                                            }}
                                            src={companyData?.logo}
                                            alt="College Logo"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lg:flex" style={{ marginLeft: '0px', marginTop: '30px' }}>
                    <div className="p-6 w-full" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
                        <div className="flex flex-wrap justify-between wrapp" style={{ marginBottom: '20px', gap: '20px' }}>
                            {/* First color-price */}
                            <div style={{ marginBottom: '20px', marginRight: '30px' }}>
                                <div className="popover-container">
                                    <div className="flex items-center">
                                        {/* <Link className="color" style={{marginLeft: '10px', color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer', textDecoration:'underline',marginRight:'230px'}}>
                                            Compare this Cover
                                        </Link> */}

                                    </div>
                                </div>
                            </div>

                            {/* Second color-price */}
                            <div className="flex items-center justify-center" style={{ marginTop: '-23px', textAlign: 'center' }}>
                                <div className="popover-container">
                                    <Link className="color" style={{ marginLeft: '10px', color: '#000000', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer', textAlign: 'center', textDecoration: 'underline' }}>
                                        4.5
                                    </Link>
                                    <div className="popover-top-review" style={{ textAlign: 'left' }}>
                                        <div className="popover">
                                            <h3 className="popover-heading color-price">Rating:</h3>
                                            <p className="popover-paragraph color-price">
                                                Rating From Our Recent Clients
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <img src={star} height={32} width={32} alt="Image Description" className="badge-image" style={{ marginLeft: '0px', marginTop: '3px' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: '-40px' }}>
                    <div className="group rounded-xl bg-white dark:bg-slate-900 w-full mx-auto lg:max-w-2xl " style={{ zIndex: 1, marginTop: '0px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>

                        <button
                            className='font-semibold transition-all duration-500 ease-in-out text-white bg-violet-6001 '
                            type="button"
                            onClick={handleEnquiry}
                           
                            style={{ color: 'black', width: '550px', height: '70px', display: 'flex', justifyContent: 'center', textAlign: 'center', padding: '22px', borderRadius: '40px' }}
                        >
                            Apply Now !
                        </button>

                    </div>
                    <hr style={{ marginTop: '-36px', borderColor: 'black', borderWidth: '1px' }} />

                </div>
                <div className="container" style={{ marginLeft: '0px', marginTop: '60px', marginBottom: '-100px' }}>

                    <li className="block" style={{ marginBottom: '20px' }}>
                        <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '30px', cursor: 'pointer', textAlign: 'left' }}>
                            Choose your right cover
                        </span>
                    </li>
                    <div className="flex justify-between">
                        {/* <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div className="flex items-center" style={{flexWrap:'wrap'}}>
                                <div className='visa-text' style={{marginBottom: '20px', marginRight:'20px'}}>
                                    <div className="flex items-center" style={{marginTop:'10px'}}>
                                        <button 
                                            className={`px-6 py-2 color font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${selectedLink === 'Working in Australia' ? 'text-white bg-violet-6001' : 'text-black bg-white'}`}                                  
                                            type="button" 
                                            style={{textAlign:'left'}}
                                            onClick={() => handleLinkClick('Working in Australia')}
                                        >
                                            <span>Working in Australia</span>
                                        </button>
                                    </div>
                                </div>

                                <div style={{marginBottom: '20px', marginRight:'20px'}}>
                                    <div className="flex items-center" style={{marginTop:'10px'}}>
                                        <button 
                                            className={`px-6 py-2 color font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${selectedLink === 'Partner visa 309 and 100' ? 'text-white bg-violet-6001' : 'text-black bg-white'}`}                                  
                                            type="button" 
                                            style={{textAlign:'left'}}
                                            onClick={() => handleLinkClick('Partner visa 309 and 100')}
                                        >
                                            <span>Visiting Australia</span>
                                        </button>
                                    </div>
                                </div>

                                <div style={{marginBottom: '20px'}}>
                                    <div className="flex items-center" style={{marginTop:'10px'}}>
                                        <button 
                                            className={`px-6 py-2 color font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${selectedLink === 'Partner visa 820 and 801' ? 'text-white bg-violet-6001' : 'text-black bg-white'}`}                                  
                                            type="button" 
                                            style={{textAlign:'left'}}
                                            onClick={() => handleLinkClick('Partner visa 820 and 801')}
                                        >
                                            <span>Studying in Australia</span>
                                        </button>
                                    </div>
                                </div>
                            </div>                        
                        </div>                            */}
                    </div>
                    <div className="md:flex" style={{ marginTop: '-20px' }}>
                        <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" >
                            <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-[30px]">
                                <div className="group relative rounded-xl bg-white dark:bg-slate-900 shadow1 p-6 text-center mt-10 " style={{ display: 'flex', justifyContent: 'left', alignContent: 'left' }}>
                                    <div className="relative -mt-16" style={{ display: 'flex', justifyContent: 'left', alignContent: 'left', textAlign: 'left' }}>
                                        <span style={{ marginTop: '60px', textAlign: 'left' }}>
                                            <li className="block">
                                                <span style={{ color: '#000000', fontWeight: '600', fontSize: '30px', cursor: 'pointer', textAlign: 'left' }}>
                                                    {initailCoverData?.title}
                                                </span>
                                            </li>
                                            <li className="block mt-4">
                                                <span style={{
                                                    color: '#000000', fontFamily: 'Montserrat,Arial,Helvetica,sans-serif',
                                                    fontWeight: '400', fontSize: '16px', cursor: 'pointer', textAlign: 'left',
                                                }}
                                                    dangerouslySetInnerHTML={{ __html: formatDescription(initailCoverData?.description) }}
                                                ></span>
                                            </li>
                                            {/* <div className="mt-12">
                                                <div className="flex justify-between items-center">
                                                    <span style={{color: '#000000', fontWeight: '500', fontSize: '16px', cursor: 'pointer',textAlign:'left',paddingRight:'20px'}}>Price based on <span style={{color: '#000000', fontWeight: 'bold', fontSize: '16px', cursor: 'pointer',}}>Single </span>in <span style={{color: '#000000', fontWeight: 'bold', fontSize: '16px', cursor: 'pointer',}}>NSW</span> aged <span style={{color: '#000000', fontWeight: 'bold', fontSize: '16px', cursor: 'pointer',}}>34</span> on a <span style={{color: '#000000', fontWeight: 'bold', fontSize: '16px', cursor: 'pointer',}}>Working Visa.</span> Price may vary if details change</span>
                                                    <div className="color-price" style={{marginTop:'-5px'}}>
                                                        <div className="flex items-center">
                                                            <Link
                                                                className='px-4 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out text-white bg-violet-6001'                                  
                                                                type="button"
                                                                style={{ color: 'black' }}
                                                            >
                                                              
                                                                Get a quote
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="md:flex" style={{ marginTop: '60px' }}>
                        <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" >
                            <li className="block">
                                <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '30px', cursor: 'pointer', textAlign: 'left' }}>Choose A Plan</span>
                            </li>
                            <div class="pricee mt-12">
                                {insurancePlans?.map((ele, index) => (
                                    <div key={index} class={`table ${"premium"} ${index > 0 ? 'other-cards' : ''}`}>
                                        <h5 className="text-lg" style={{ color: 'rgba(36,40,45,1)', textAlign: 'left', fontFamily: 'Montserrat,Arial,Helvetica,sans-serif', fontWeight: '600', fontSize: '20px', lineHeight: '1.2' }}>
                                            {ele.name}
                                        </h5>
                                        <p className="mt-1" style={{
                                            color: 'rgba(36,40,45,1)', textAlign: 'left',
                                            fontFamily: 'Montserrat,Arial,Helvetica,sans-serif', fontWeight: '400',
                                            lineHeight: '1.5', fontSize: '16px'
                                        }}>{ele.p}</p>
                                        <hr style={{ marginTop: '20px', border: '0.5px solid #858585' }} />
                                        <ul class="features mt-6">
                                            <div style={{
                                                textAlign: 'center', display: 'flex', justifyContent: 'center',
                                                alignContent: 'center',
                                            }}>
                                                <h5 style={{ color: 'rgba(36,40,45,1)', fontSize: '43px', }}>₹{ele.fee}</h5>
                                            </div>
                                            <div style={{
                                                textAlign: 'center', display: 'flex',
                                                justifyContent: 'center', alignContent: 'center'
                                            }}>
                                                <p className="mt-1" style={{
                                                    color: 'rgba(36,40,45,1)', textAlign: 'left',
                                                    fontFamily: 'Montserrat,Arial,Helvetica,sans-serif', fontWeight: '400',
                                                    lineHeight: '1.5', fontSize: '16px'
                                                }}>Inclusive of {ele.tax}% {ele.tax_name}</p>
                                            </div>

                                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                                <ul className="mt-6 text-center p-2 bg-white shadow2" id="myTab" data-tabs-toggle="#StarterContent" role="tablist" style={{ borderRadius: '15px', width: '90%', justifyContent: 'center', backgroundColor: '#e6e6e6' }}>
                                                    <p className="mt-1" style={{ color: 'rgba(36,40,45,1)', textAlign: 'center', fontFamily: 'Montserrat,Arial,Helvetica,sans-serif', fontWeight: '400', lineHeight: '1.5', fontSize: '16px' }}>Cover <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#000' }}>
                                                        ${ele.cover_fee}</span> per traveller</p>
                                                </ul>
                                            </div>
                                        </ul>
                                        <div class="butn"
                                            onClick={() => handleClickButton(ele.benefits_ids)}
                                        ><button> Benefits</button></div>
                                    </div>
                                ))}
                            </div>


                        </div>
                    </div>

                    <div className="md:flex" style={{ marginTop: '100px' }}>
                        <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" >
                            <li className="block">
                                <span style={{
                                    color: '#000000', fontWeight: 'bold',
                                    fontSize: '30px', cursor: 'pointer', textAlign: 'left'
                                }}>
                                    Why choose {InsuranceData?.title}
                                </span>
                            </li>
                            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 grid-cols-1 gap-[30px] " >
                                {
                                    initialChoose?.map((ele, index) => (
                                        <div key={index} className="group relative rounded-xl bg-white dark:bg-slate-900 shadow1 p-6 text-center mt-10">
                                            <div className="relative">
                                                <span className={'solar--siren-outline'} style={{ display: 'flex', alignItems: 'left', justifyContent: 'left', color: '#000', fontSize: ele.fontSize, }}></span>
                                                <h5 className="text-lg mt-5" style={{ color: 'rgba(36,40,45,1)', textAlign: 'left', fontFamily: 'Montserrat,Arial,Helvetica,sans-serif', fontWeight: '600', fontSize: '20px', lineHeight: '1.2' }}>{ele.title}</h5>
                                                <p className="mt-3" style={{
                                                    color: 'rgba(36,40,45,1)',
                                                    textAlign: 'left', fontFamily: 'Montserrat,Arial,Helvetica,sans-serif', fontWeight: '400',
                                                    lineHeight: '1.5', fontSize: '16px'
                                                }} dangerouslySetInnerHTML={{ __html: formatDescription(ele.description )}}>
                                                </p>
                                                <div className="color-price" style={{ marginTop: '30px', marginRight: '60px' }}>
                                                    <div className="flex items-center">
                                                        <Link
                                                            style={{
                                                                color: '#000000',
                                                                fontWeight: 'bold',
                                                                fontSize: '18px',
                                                                cursor: 'pointer',
                                                                marginLeft: '15px',
                                                                paddingBottom: '3px',
                                                                whiteSpace: 'nowrap',
                                                                display: 'flex', // Added display flex
                                                                alignItems: 'center', // Added align items center
                                                            }}
                                                            className='under'
                                                        >
                                                            {ele.readmore}
                                                            <MdOutlineArrowForward color='#000' style={{ marginLeft: '5px' }} />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    {!empty(initialFAQ)?(<div className="md:flex" style={{ marginTop: '60px' }}>
                        <div className="lg:w-5/5 md:w-full md:mb-8 md:order-2" >
                            <li className="block">
                                <span style={{ color: '#000000', fontWeight: 'bold', fontSize: '30px', cursor: 'pointer', textAlign: 'left' }}>FAQs</span>
                            </li>

                            <div id="accordion-collapseone" data-accordion="collapse" className="mt-12">
                                {initialFAQ.map((item, index) => (
                                    <div key={index} className="relative shadow1 dark:shadow1 rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                            <button onClick={() => toggleAccordion(index)} type="button" style={{ color: 'black' }}
                                                className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-8002 text-violet-6001' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                <span>{item.title}</span>
                                                <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>
                                        {activeIndex === index && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-5">
                                                    <p style={{ color: 'black' }}
                                                        dangerouslySetInnerHTML={{ __html: formatDescription(item?.description) }}></p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>):("")}
                </div>
            </section>

            <div style={{
                position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 50, overflow: 'hidden',
                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
                className={`m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${placeBid ? "" : "hidden"}`}>
                <div style={{
                    position: 'relative', width: '100%', height: 'auto',
                    maxWidth: '600px', padding: '4px'
                }} className="relative w-full h-auto max-w-2xl p-4">
                    <div style={{
                        position: 'relative', backgroundColor: 'white', borderRadius: '8px',
                        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
                    }}
                        className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">

                        <div style={{
                            display: 'flex', justifyContent: 'space-between',
                            alignItems: 'center', padding: '6px 12px', borderBottom: '1px solid #e6e6e6'
                        }}
                            className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-t-gray-800">
                            <h5 style={{ fontSize: '1.5rem', fontWeight: '800', color: 'black' }}
                                className="text-xl font-bold">Benefits of this coverage</h5>
                            <button style={{
                                color: '#718096', backgroundColor: 'transparent', ':hover': {
                                    backgroundColor: '#edf2f7',
                                    color: '#2d3748'
                                }, borderRadius: '8px', fontSize: '1rem', padding: '0.375rem 0.75rem', marginLeft: 'auto'
                            }} type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                <IoMdClose style={{ fontSize: '1.8rem', color: 'black' }}
                                    onClick={() => setPlaceBid(!placeBid)} />
                            </button>
                        </div>


                        <div id='center' style={{ padding: '1.5rem', overflowY: 'auto', maxHeight: '400px', color: 'black' }} className="p-6 overflow-y-auto max-h-96">
                            <div id="accordion-collapseone" data-accordion="collapse" style={{ marginTop: '-23px' }}>
                                {benefits.map((item, index) => (
                                    <div key={index} className="relative rounded-md overflow-hidden mt-4">
                                        <h2 className="text-base font-semibold flex items-center" id="accordion-collapse-heading-1">

                                            {benefitIds.includes(item.id) ? (<span className='charm--tick'
                                                style={{ marginTop: '-8px', color: 'green', fontSize: '26px' }}>
                                            </span>) : (
                                                <span className='akar-icons--cross' style={{ marginTop: '-8px', color: 'red', fontSize: '26px' }}>
                                                </span>
                                            )}
                                            <button onClick={() => toggleBenefitsIndexAccordion(index)}
                                                type="button" style={{ color: 'black' }}
                                                className={`flex justify-between items-center p-56 w-full font-semibold text-start ${benefitsIndex === index ? 'bg-gray-50 text-violet-6001' : ''}`}
                                                data-accordion-target="#accordion-collapse-body-1"
                                                aria-expanded="true"
                                                aria-controls="accordion-collapse-body-1">
                                                <span>{item.name}</span>
                                                <svg data-accordion-icon className={`w-4 h-4 shrink-0 ${benefitsIndex === index ? "rotate-180" : "rotate-360"}`}
                                                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                        clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </h2>

                                        {benefitsIndex === index && (
                                            <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                <div className="p-55 dark:bg-slate-8003">
                                                    <p style={{ color: 'black' }}
                                                        dangerouslySetInnerHTML={{ __html: formatDescription(item.description) }} ></p>
                                                    <h4 className='mt-6 font-semibold text-start'>Waiting Periods</h4>
                                                    <p style={{ color: 'black' }}>{item.periods}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      rtl={false}
      draggable
      theme="light"
       />

            <Footer />

        </>
    )
}

// https://www.npmjs.com/package/leaflet