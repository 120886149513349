// Import the functions you need from the SDKs you need
import  {initializeApp}  from "firebase/app";
import  {getStorage}  from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyChACrHEhSl2mq8M27IEj3P1sAcSHC97po",
    authDomain: "visa-search-a8660.firebaseapp.com",
    projectId: "visa-search-a8660",
    storageBucket: "visa-search-a8660.appspot.com",
    messagingSenderId: "616074046323",
    appId: "1:616074046323:web:449c2168e786bca4ad3306", 
    measurementId: "G-R4CTZV38BL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
// export default Storage = getStorage(app)
//  const analytics = getAnalytics(app);


